import React, { memo, forwardRef } from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import { Spinner } from 'components';
import Icon, { IconName, IconSize } from 'components/Icon';

import styles from './ButtonV3.module.scss';

export type ButtonV3Variant = 'contained' | 'text';

export interface ButtonV3Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** How large should the button be? */
  iconSize?: IconSize;
  /** size of the button */
  size?: 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
  /** What should be the button's variant */
  variant?: ButtonV3Variant;
  /** color of the button */
  color?:
    | 'primary'
    | 'secondary'
    | 'black'
    | 'blue'
    | 'primary-pressed'
    | 'silent'
    | 'danger';
  /** Is button full width? */
  isFullWidth?: boolean;
  /** start icon name */
  startIcon?: IconName;
  /** start icon name */
  startIconClassName?: string;
  /** end icon name */
  endIcon?: IconName;
  /** end icon name */
  endIconClassName?: string;
  /** borderless? */
  borderless?: boolean;
  /** isLoading */
  isLoading?: boolean;
  borderRadius?: number;
  htmlFor?: string;
}

export const ButtonV3 = memo(
  forwardRef<HTMLButtonElement, ButtonV3Props>(
    (
      {
        className = '', // custom class name
        iconSize,
        size = 'medium',
        color = 'primary',
        variant = 'contained',
        borderless = false,
        isFullWidth = false,
        startIcon,
        endIcon,
        isLoading = false,
        borderRadius,
        startIconClassName,
        endIconClassName,
        children,
        htmlFor,
        ...props
      },
      ref
    ) => {
      const colorName = color.split('-').map(capitalize).join('');
      const contentClassNames = cn(
        {
          [styles.ButtonV3]: true,
          [`${styles[`ButtonV3Color${colorName}`]}`]: true,
          [`${styles[`ButtonV3Variant${capitalize(variant)}`]}`]: true,
          [`${styles[`ButtonV3Size${capitalize(size)}`]}`]: true,
          [styles.ButtonV3FullWidth]: isFullWidth,
          [styles.ButtonV3Borderless]: borderless,
          [styles.ButtonV3Loading]: isLoading,
        },
        className
      );

      const loaderColor = color === 'secondary' ? 'primary' : 'secondary';

      return (
        <button
          className={contentClassNames}
          style={{
            borderRadius: borderRadius ? `${borderRadius}px` : undefined,
          }}
          data-testid="ButtonV3"
          ref={ref}
          {...props}
        >
          {startIcon && (
            <Icon
              iconName={startIcon}
              size={iconSize}
              className={cn(
                styles.ButtonV3IconLeft,
                styles.ButtonV3Icon,
                startIconClassName
              )}
            />
          )}
          <div className={styles.ButtonV3Content}>{children} </div>
          {endIcon && (
            <Icon
              iconName={endIcon}
              size={iconSize}
              className={cn(
                styles.ButtonV3IconRight,
                styles.ButtonV3Icon,
                endIconClassName
              )}
            />
          )}
          {isLoading && (
            <div className={styles.ButtonV3Spinner}>
              <Spinner className={styles.ButtonV3Loader} color={loaderColor} />
            </div>
          )}
          {htmlFor && (
            <label htmlFor={htmlFor} className={styles.label}></label>
          )}
        </button>
      );
    }
  )
);

ButtonV3.displayName = 'ButtonV3';
