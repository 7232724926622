import axios from 'axios';

import { UserSecret } from 'lib/atoms/userSecretAtom';

const { NEXT_PUBLIC_BUILD_PAGES_API_HOST_URL } = process.env;

export const pageBuilderInstance = axios.create({
  baseURL: NEXT_PUBLIC_BUILD_PAGES_API_HOST_URL,
});

export const apiInstance = axios.create({
  baseURL:
    typeof process !== undefined ? process.env.NEXT_PUBLIC_API_HOST_URL : '',
});

export function setAuthHeaders(userSecret: UserSecret | null) {
  const email = userSecret?.email || null;
  const token = userSecret?.token || null;
  apiInstance.defaults.headers.common['X-Auth-Email'] = email;
  apiInstance.defaults.headers.common['X-Auth-Token'] = token;
}

export function hasAuthHeaders() {
  return (
    !!apiInstance.defaults.headers.common['X-Auth-Email'] &&
    !!apiInstance.defaults.headers.common['X-Auth-Token']
  );
}
