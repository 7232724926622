import React, { memo, useEffect, useMemo, useRef, useCallback } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { Menu } from '@headlessui/react';
import { useSession } from 'next-auth/react';

import { Icon, IconButton, Link } from 'components';
import Logo from 'components/Logo';
import { ButtonV2 } from 'components/form';
import { signInModalInstance } from 'components/candidate/SignInModal';
import { CVUploadModalInstance } from 'components/candidate/CVUploadModal';
import { Row } from 'components/layout';
import { saveInAppModalInstance } from 'components/candidate/SaveInAppModal';
import ChatPopUp from 'components/ChatPopUp';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { ActionMenuButton } from 'components/ActionMenuButton/ActionMenuButton.component';
import { useResponsive } from 'hooks/useResponsive';
import { useNotification } from 'hooks/useNotification';
import { useCandidate } from 'hooks/useCandidate';
import ResponseModal from 'pages/candidate/applications/ResponseModal/ResponseModal.component';
import { PAGE_ROUTES } from 'lib/page-routes';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { parseResponse } from 'lib/utils/parser';
import { useApp } from 'lib/contexts/save-in-app-context';
import { useOpportunitiesSearch } from 'lib/contexts/opportunities-search-context';
import { useSocietiesSearch } from 'lib/contexts/societies-search-context';
import { useCompaniesSearch } from 'lib/contexts/companies-search-context';
import { useManagerFilters } from 'lib/contexts/manager-filters-context';
import { ApplicationResponseModalEnum } from 'lib/models/candidate-applications';
import { useMatchingExperience } from 'lib/contexts/matches-context';
import { useAuth } from 'lib/providers/AuthProvider';
import styles from './Header.module.scss';
import { isAndroid } from 'utils/dom';
import { APP_LINK, CHROME_EXTENSION_STORE_LINK } from 'utils/urls';

export interface HeaderProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** children node */
  children: React.ReactNode;
  showChatLogo?: boolean;
}

export const Header: React.FC<HeaderProps> = memo(
  ({ children, className, showChatLogo }: HeaderProps) => {
    const notificationInstance = useNotification();
    const screens = useResponsive();
    const router = useRouter();
    const { data: session, status } = useSession();

    const { headers } = useRecoilValue(httpHeadersState);
    const { user, isCandidate, isTemporaryCandidate, isAuthLoading } =
      useAuth();
    const heapIdentifiedRef = useRef(false);

    const {
      search: opportunitiesSearch,
      resetFilter: resetOpportunitiesFilter,
    } = useOpportunitiesSearch();
    const { search: societiesSearch, resetFilter: resetSocietiesFilter } =
      useSocietiesSearch();
    const { search: companiesSearch, resetFilter: resetCompaniesFilter } =
      useCompaniesSearch();
    const { resetFilter: resetManagerFilter } = useManagerFilters();

    const {
      shouldHideMobileFooter,
      writeApplicationModalType,
      setWriteApplicationModalType,
    } = useApp();
    const {
      isNonOnboardedUser,
      updateTouchpointApplicationRating,
      onGoogleLoginAuthentication,
      onAppleLoginAuthentication,
    } = useCandidate();
    const { candidateDashboard } = useMatchingExperience();

    const { upload_cv, rating, ta_id, matches } = router.query;

    const {
      unread_messages_count = 0,
      unread_notifications_count = 0,
      started_or_matches_applications = [],
    } = useMemo(() => {
      const {
        unread_messages_count,
        unread_notifications_count,
        started_or_matches_applications,
      } = candidateDashboard || {};

      return {
        unread_messages_count,
        unread_notifications_count,
        started_or_matches_applications,
      };
    }, [candidateDashboard]);

    const isLoggedInViaGoogle = session && session.oauth_provider === 'google';
    const isLoggedInViaApple = session && session.oauth_provider === 'apple';

    useEffect(() => {
      if (status === 'authenticated') {
        (async () => {
          if (isLoggedInViaGoogle) {
            await onGoogleLoginAuthentication();
          } else if (isLoggedInViaApple) {
            await onAppleLoginAuthentication();
          }
        })();
      }
    }, [isLoggedInViaGoogle, isLoggedInViaApple, status]);

    useEffect(() => {
      if (!isNonOnboardedUser) {
        updateRatingHandler();
      }
    }, [isNonOnboardedUser]);

    const handleLoginClick = async () => {
      try {
        await signInModalInstance();
      } catch (error) {
        console.error(error);
      }
    };

    const handleCVUpload = async () => {
      try {
        await CVUploadModalInstance();
      } catch (error) {
        console.error(error);
      }
    };

    const optionForChatPopUp = useMemo(() => {
      if (matches === 'true') {
        return 1;
      } else if (Number(rating) > 0 && Number(rating) < 4) {
        return 2;
      } else if (Number(rating) > 3) {
        return 3;
      } else return 1;
    }, [rating, matches]);

    useEffect(() => {
      if (upload_cv === 'true') {
        handleCVUpload();
      }
    }, [upload_cv]);

    const updateRatingHandler = useCallback(async () => {
      if (rating && ta_id && !isNonOnboardedUser && isCandidate) {
        try {
          const response = await updateTouchpointApplicationRating(
            headers,
            ta_id as string,
            Number(rating)
          );
          if (response) {
            const applicationResponse = parseResponse(response);
            const { student_society_name } = applicationResponse;
            notificationInstance.custom({
              message: '',
              timeout: 10000,
              renderContent: (fn?: (() => void) | undefined) =>
                renderFeedbackToaster(student_society_name, fn),
            });
          }
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
      }
    }, [rating, ta_id, isNonOnboardedUser, isCandidate]);

    const isHomePage = router.pathname.includes(PAGE_ROUTES.CANDIDATE_MATCHES);
    const isSearchPage = router.pathname.includes(PAGE_ROUTES.SEARCH);

    const isPublicPage =
      router.pathname === PAGE_ROUTES.COMPANIES ||
      router.pathname === PAGE_ROUTES.SOCIETIES ||
      router.pathname === PAGE_ROUTES.SOCIETY_PUBLIC ||
      router.pathname === PAGE_ROUTES.SHORTLINK_SOCIETY_PUBLIC ||
      router.pathname === PAGE_ROUTES.COMPANY_PUBLIC ||
      router.pathname === PAGE_ROUTES.JOB_PUBLIC ||
      router.pathname === PAGE_ROUTES.EVENT_PUBLIC ||
      router.pathname === PAGE_ROUTES.INTERNSHIP_PUBLIC ||
      router.pathname === PAGE_ROUTES.COMPETITION_PUBLIC ||
      router.pathname === PAGE_ROUTES.PUBLIC_SEARCH_PAGES ||
      router.pathname === PAGE_ROUTES.SOCIETY_SPONSOR_PUBLIC_PAGE;

    const isCandidateRoute =
      isPublicPage ||
      router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES ||
      router.pathname === PAGE_ROUTES.MANAGER ||
      router.pathname === PAGE_ROUTES.CANDIDATE_ONBOARDING ||
      router.pathname.includes(PAGE_ROUTES.SEARCH) ||
      router.pathname === PAGE_ROUTES.MATCHING_EXPERIENCE_PAGE ||
      router.pathname === PAGE_ROUTES.CANDIDATE_PROFILE ||
      router.pathname === PAGE_ROUTES.CANDIDATE_EDIT_PROFILE ||
      router.pathname === PAGE_ROUTES.CANDIDATE_NOTIFICATIONS ||
      router.pathname === PAGE_ROUTES.CANDIDATE_MESSAGES ||
      //add society page and universities society page in candidate route for new logo
      router.pathname === PAGE_ROUTES.SOCIETIES ||
      router.pathname === PAGE_ROUTES.UNIVERSITIES_SOCIETIES;

    const isTemporaryCandidateRoute =
      isPublicPage || router.pathname === PAGE_ROUTES.FUNNEL_MATCHES;

    const showFooter =
      router.pathname === PAGE_ROUTES.MANAGER ||
      router.pathname === PAGE_ROUTES.CANDIDATE_MATCHES ||
      router.pathname === PAGE_ROUTES.CANDIDATE_PROFILE ||
      router.pathname.includes(PAGE_ROUTES.SEARCH);

    useEffect(() => {
      if (isCandidate && !heapIdentifiedRef.current && user?.email) {
        heapIdentifiedRef.current = true;
        // @ts-ignore
        window.heap && window.heap.identify(user.email);
      }
    }, [isCandidate && user]);

    const onUseHuzzleClick = async () => {
      try {
        if (isTemporaryCandidate) {
          await saveInAppModalInstance();
        } else if (!isCandidate) {
          await signInModalInstance();
        }
        router.push(PAGE_ROUTES.CANDIDATE_MATCHES);
      } catch (error) {
        console.error(error);
      }
    };

    const onAppDownloadClick = async () => {
      try {
        if (isTemporaryCandidate) {
          await saveInAppModalInstance();
        } else if (!isCandidate) {
          await signInModalInstance();
        }
        router.push(APP_LINK);
      } catch (error) {
        console.error(error);
      }
    };

    const hideHeader = !!(
      (isPublicPage && (isCandidate || isAuthLoading)) ||
      router.pathname === PAGE_ROUTES.MATCHING_EXPERIENCE_PAGE ||
      router.pathname === PAGE_ROUTES.CANDIDATE_EDIT_PROFILE ||
      router.pathname === PAGE_ROUTES.CANDIDATE_NOTIFICATIONS ||
      shouldHideMobileFooter ||
      (opportunitiesSearch &&
        router.pathname === PAGE_ROUTES.OPPORTUNITIES_SEARCH) ||
      (societiesSearch && router.pathname === PAGE_ROUTES.SOCIETIES_SEARCH) ||
      (companiesSearch && router.pathname === PAGE_ROUTES.COMPANIES_SEARCH) ||
      router.pathname === PAGE_ROUTES.CANDIDATE_MESSAGES
    );

    /** Reset candidate filter if the  page route is not
     * specific search page or
     * public webpages
     */
    useEffect(() => {
      if (
        !isPublicPage &&
        router.pathname !== PAGE_ROUTES.MANAGER &&
        router.pathname !== PAGE_ROUTES.SOCIETIES_SEARCH &&
        router.pathname !== PAGE_ROUTES.COMPANIES_SEARCH &&
        router.pathname !== PAGE_ROUTES.OPPORTUNITIES_SEARCH
      ) {
        resetSocietiesFilter();
        resetCompaniesFilter();
        resetOpportunitiesFilter();
        resetManagerFilter();
      }
    }, [router]);

    const headerVisible = hideHeader === false && showFooter === false;
    const footerVisible = hideHeader === false && showFooter === true;

    const handleLogout = () =>
      router.push(`${PAGE_ROUTES.LOGOUT}?type=candidate`);

    const writeApplicationsModal = useMemo(() => {
      if (writeApplicationModalType) {
        return (
          <ResponseModal
            modalType={writeApplicationModalType?.type}
            isOpen={true}
            application={writeApplicationModalType?.application || undefined}
            onClose={() => setWriteApplicationModalType(null)}
          />
        );
      }
    }, [writeApplicationModalType]);

    const getCoverLetterApplicationsList = (close: () => void) => (
      <Menu as="div" className={styles.moremenu}>
        <Menu.Button
          className={cn(
            styles.trigger,
            styles.menuItem,
            styles.menubutton,
            styles.submenu
          )}
          disabled={started_or_matches_applications?.length === 0}
        >
          <Tooltip
            content="You’ll be able to generate cover letters once you’ve saved a job or internship."
            position="top"
            xAlign="center"
            color="dark"
            disabled={started_or_matches_applications?.length > 0}
            className={styles.tooltipContainer}
            containerClassName={styles.tooltipContainerContent}
          >
            <p>Write cover letter</p>
          </Tooltip>
        </Menu.Button>
        <Menu.Items
          as="ul"
          className={cn(styles.menuList, styles.moremenuList, styles.scroll)}
        >
          <Menu.Item as="li" className={styles.menuItem}>
            {started_or_matches_applications?.map(
              ({ touchpoint_id, touchpoint_title, touchpoint_slug }) => {
                return (
                  <button
                    key={touchpoint_id}
                    onClick={() => {
                      setWriteApplicationModalType({
                        type: ApplicationResponseModalEnum.coverLetter,
                        application: {
                          id: touchpoint_id,
                          title: touchpoint_title,
                          slug: touchpoint_slug,
                        },
                      });
                      close();
                    }}
                    className={styles.menubutton}
                  >
                    <span>{touchpoint_title} </span>
                  </button>
                );
              }
            )}
          </Menu.Item>
        </Menu.Items>
      </Menu>
    );

    const getWriteApplicationsMenu = (close: () => void) => (
      <Menu as="div" className={cn(styles.moremenu)}>
        <Menu.Button
          className={cn(
            styles.trigger,
            styles.menuItem,
            styles.menubutton,
            styles.submenu
          )}
        >
          <Icon iconName="icon_magic-wand" />
          <p>Write applications</p>
        </Menu.Button>
        <Menu.Items
          as="ul"
          className={cn(styles.menuList, styles.moremenuList)}
        >
          <Menu.Item as="li" className={styles.menuItem}>
            <button
              className={styles.menubutton}
              onClick={() => {
                setWriteApplicationModalType({
                  type: ApplicationResponseModalEnum.questionResponse,
                  application: null,
                });
                close();
              }}
            >
              <p>Get questions replies</p>
            </button>
          </Menu.Item>
          {getCoverLetterApplicationsList(close)}
        </Menu.Items>
      </Menu>
    );

    const desktopHamburgerMenu = (
      <Menu as="div" className={cn(styles.options, styles.hamburger)}>
        {({ close }) => (
          <>
            <Menu.Button className={cn(styles.trigger)}>
              <Icon
                className={styles.optionsIcon}
                iconName="hamburger"
                size="medium"
              />
              <p className={styles.optionsText}>Menu</p>
              {unread_notifications_count ? (
                <p className={styles.notificationbubble}>
                  {unread_notifications_count}
                </p>
              ) : null}
            </Menu.Button>
            <Menu.Items as="ul" className={styles.menuList}>
              <Menu.Item
                as="li"
                className={cn(styles.menuItem, styles.hidedesktop)}
              >
                <button
                  className={styles.menubutton}
                  onClick={() => router.push(PAGE_ROUTES.CANDIDATE_MESSAGES)}
                >
                  <Icon iconName="icon_chat" />
                  {unread_messages_count ? (
                    <p className={styles.new}>{unread_messages_count}</p>
                  ) : null}
                  <p>Messages</p>
                </button>
              </Menu.Item>
              <Menu.Item
                as="li"
                className={cn(styles.menuItem, styles.hidedesktop)}
              >
                <button
                  className={styles.menubutton}
                  onClick={() =>
                    router.push(PAGE_ROUTES.CANDIDATE_EDIT_PROFILE)
                  }
                >
                  <Icon iconName="icon_profile" />
                  <p>Edit profile</p>
                </button>
              </Menu.Item>
              <Menu.Item as="li" className={styles.menuItem}>
                <button
                  className={styles.menubutton}
                  onClick={() =>
                    router.push(PAGE_ROUTES.CANDIDATE_EDIT_PROFILE)
                  }
                >
                  <Icon iconName="icon_camera-flash" />
                  <p>Generate CV</p>
                </button>
              </Menu.Item>
              <Menu.Item as="li" className={styles.menuItem}>
                <button
                  className={styles.menubutton}
                  onClick={() => handleCVUpload()}
                >
                  <Icon iconName="icon_document-2" />
                  <p>Download CV</p>
                </button>
              </Menu.Item>
              {getWriteApplicationsMenu(close)}
              <Menu.Item as="li" className={styles.menuItem}>
                <button
                  className={styles.menubutton}
                  onClick={() => {
                    window.open(CHROME_EXTENSION_STORE_LINK, '_blank');
                  }}
                >
                  <Icon iconName="icon_check-all" />
                  <p>Autofill applications</p>
                </button>
              </Menu.Item>
              <div className={styles.menuDivider}></div>
              <Menu.Item as="li" className={styles.menuItem}>
                <button
                  className={styles.menubutton}
                  onClick={() =>
                    router.push(PAGE_ROUTES.CANDIDATE_NOTIFICATIONS)
                  }
                >
                  <Icon iconName="icon_notification-bell" />
                  <p>Notifications</p>
                  {unread_notifications_count ? (
                    <p className={styles.new}>{unread_notifications_count}</p>
                  ) : null}
                </button>
              </Menu.Item>
              <Menu.Item as="li" className={styles.menuItem}>
                <button
                  className={styles.menubutton}
                  onClick={() => {
                    router.push(
                      isNonOnboardedUser
                        ? PAGE_ROUTES.CANDIDATE_ONBOARDING
                        : `${PAGE_ROUTES.CANDIDATE_ONBOARDING}?matching_preference=true`
                    );
                  }}
                >
                  <Icon iconName="icon_filter-2" />
                  <p>Matching preferences</p>
                </button>
              </Menu.Item>
              <Menu.Item as="li" className={styles.menuItem}>
                <button
                  className={styles.menubutton}
                  onClick={() => router.push(PAGE_ROUTES.CANDIDATE_PROFILE)}
                >
                  <Icon iconName="icon_gear" />
                  <p>Settings</p>
                </button>
              </Menu.Item>
              <div className={styles.menuDivider}></div>
              <Menu.Item as="li" className={styles.menuItem}>
                <button
                  className={styles.menubutton}
                  onClick={() => handleLogout()}
                >
                  <Icon iconName="icon_logout" />
                  <p>Sign out</p>
                </button>
              </Menu.Item>
            </Menu.Items>
          </>
        )}
      </Menu>
    );

    /** common for both mobile and desktop */
    const commonIconButtons = (
      <>
        <div
          onClick={() => router.push(PAGE_ROUTES.CANDIDATE_MATCHES)}
          className={cn(styles.options, {
            [styles.optionsActive]: router.pathname.includes(
              PAGE_ROUTES.CANDIDATE_MATCHES
            ),
          })}
        >
          <Icon
            className={styles.optionsIcon}
            iconName={isHomePage ? 'icon_home-fill' : 'icon_home'}
          />
          <p className={styles.optionsText}>Home</p>
        </div>
        <div
          onClick={() => router.push(PAGE_ROUTES.SEARCH)}
          className={cn(styles.options, {
            [styles.optionsActive]: isSearchPage,
          })}
        >
          <Icon
            className={styles.optionsIcon}
            iconName={isSearchPage ? 'icon_search-fill' : 'icon_search'}
          />
          <p className={styles.optionsText}>Explore</p>
        </div>
        <div
          onClick={() => router.push(PAGE_ROUTES.MANAGER)}
          className={cn(styles.options, {
            [styles.optionsActive]: router.pathname === PAGE_ROUTES.MANAGER,
          })}
        >
          <Icon
            className={styles.optionsIcon}
            iconName={
              router.pathname === PAGE_ROUTES.MANAGER
                ? 'icon_bookmark-filled'
                : 'icon_bookmark'
            }
          />
          <p className={styles.optionsText}>Manage</p>
        </div>
      </>
    );

    const signInActionMenuButton = [
      {
        id: '1',
        label: 'Sign in as student',
        onClick: async (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          event.stopPropagation();
          await handleLoginClick();
        },
      },
      {
        id: '2',
        label: 'Sign in as recruiter',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          event.stopPropagation();
          router.push(PAGE_ROUTES.SIGN_IN_RECRUITER);
        },
      },
    ];

    return (
      <>
        <>
          <header
            className={cn(
              styles.container,
              {
                [styles.containerTemporary]: isTemporaryCandidate && screens.sm,
                [styles['shown-mobile']]: headerVisible,
              },
              className
            )}
          >
            {!(
              screens.sm && router.pathname === PAGE_ROUTES.CANDIDATE_ONBOARDING
            ) ? (
              isTemporaryCandidate && isTemporaryCandidateRoute ? (
                <Row
                  align="center"
                  justify="space-between"
                  className={styles.tempcandidateheader}
                >
                  <Logo name="huzzle-new" size="small" color="primary" />
                  {screens.sm ? (
                    <IconButton
                      iconName={
                        isAndroid()
                          ? 'play-store-download'
                          : 'app-store-download'
                      }
                      size="auto"
                      className={styles.download}
                      onClick={onAppDownloadClick}
                    />
                  ) : (
                    <ButtonV2
                      onClick={onUseHuzzleClick}
                      className={styles.download}
                    >
                      Use huzzle
                    </ButtonV2>
                  )}
                </Row>
              ) : isCandidateRoute && isCandidate ? (
                <Link
                  href={PAGE_ROUTES.CANDIDATE_MATCHES}
                  className={styles.logoLink}
                >
                  <Logo name="huzzle-new" size="small" color="black" />
                </Link>
              ) : isPublicPage && !isCandidate ? (
                <Link
                  href="https://www.huzzle.app/"
                  className={styles.logoLink}
                >
                  <Logo
                    name={isCandidateRoute ? 'huzzle-new' : 'huzzle'}
                    size={
                      isCandidateRoute
                        ? 'small'
                        : screens.sm
                        ? 'small'
                        : 'medium'
                    }
                    color="black"
                  />
                </Link>
              ) : (
                <Link
                  href="https://www.huzzle.app/"
                  className={styles.logoLink}
                >
                  <Logo
                    name={isCandidateRoute ? 'huzzle-new' : 'huzzle'}
                    size={
                      isCandidateRoute
                        ? 'small'
                        : screens.sm
                        ? 'small'
                        : 'medium'
                    }
                    color="black"
                  />
                </Link>
              )
            ) : null}
            {!isAuthLoading &&
              isCandidateRoute &&
              !(router.pathname === PAGE_ROUTES.CANDIDATE_ONBOARDING) &&
              (isCandidate ? (
                <Row align="center">
                  {commonIconButtons}
                  <div
                    onClick={() => router.push(PAGE_ROUTES.CANDIDATE_MESSAGES)}
                    className={cn(
                      styles.options,
                      {
                        [styles.optionsActive]:
                          router.pathname === PAGE_ROUTES.CANDIDATE_MESSAGES,
                      },
                      styles.position
                    )}
                  >
                    {unread_messages_count ? (
                      <p className={styles.messagebubble}>
                        {unread_messages_count}
                      </p>
                    ) : null}
                    <Icon
                      className={styles.optionsIcon}
                      iconName={
                        router.pathname === PAGE_ROUTES.CANDIDATE_MESSAGES
                          ? 'icon_chat-filled'
                          : 'icon_chat'
                      }
                    />
                    <p className={styles.optionsText}>Messages</p>
                  </div>
                  <div
                    onClick={() =>
                      router.push(PAGE_ROUTES.CANDIDATE_EDIT_PROFILE)
                    }
                    className={cn(styles.options, {
                      [styles.optionsActive]:
                        router.pathname === PAGE_ROUTES.CANDIDATE_EDIT_PROFILE,
                    })}
                  >
                    <Icon
                      className={styles.optionsIcon}
                      iconName={
                        router.pathname === PAGE_ROUTES.CANDIDATE_EDIT_PROFILE
                          ? 'icon_profile-fill'
                          : 'icon_profile'
                      }
                    />
                    <p className={styles.optionsText}>Profile</p>
                  </div>
                  {desktopHamburgerMenu}
                </Row>
              ) : !isTemporaryCandidate ? (
                router.pathname === PAGE_ROUTES.SOCIETY_SPONSOR_PUBLIC_PAGE ? (
                  <ActionMenuButton
                    options={signInActionMenuButton}
                    buttonText="Sign in"
                    buttonColor="secondary"
                    menuOpenClassName={styles.activeActionButton}
                  />
                ) : (
                  <ButtonV2
                    width={80}
                    onClick={handleLoginClick}
                    className={styles.signin}
                  >
                    Sign in
                  </ButtonV2>
                )
              ) : null)}
          </header>
          <div
            className={cn(
              styles.footer,
              {
                [styles.shown]: footerVisible,
              },
              { [styles.footerScrolled]: true } //todo
            )}
          >
            <Row align="center" justify="space-around">
              {commonIconButtons}
              {desktopHamburgerMenu}
            </Row>
          </div>
        </>
        {children}
        {/* {onboardingSteps} */}
        {writeApplicationsModal}
        {showChatLogo && isNonOnboardedUser ? (
          <ChatPopUp
            option={optionForChatPopUp}
            rating={Number(rating)}
            touchpointApplicationId={ta_id as string}
          />
        ) : null}
      </>
    );
  }
);

Header.displayName = 'Header';

export function getLayout(page: React.ReactNode): React.ReactNode {
  // Add className as custom style to shared layout
  // @ts-ignore
  return (
    <Header
      // @ts-ignore
      className={this?.className}
      // @ts-ignore
      showChatLogo={this?.showChatLogo}
    >
      {page}
    </Header>
  );
}

export const renderEventRegistrationCompleteExistingUserToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>✅</span>
      <div>
        <p className={styles.toasterContentTitle}>You’re registered!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your email for the ticket and instructions.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderSubmitApplicationExistingUserToaster = (
  onClose?: () => void,
  type?: string
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>✅</span>
      <div>
        <p className={styles.toasterContentTitle}>You’ve applied!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your email and application manager for updates.
          </p>
          <div>
            <button
              className={cn(styles.toasterContentDismiss, styles.leftPadding0)}
              onClick={onClose}
            >
              OK
            </button>
            <Link
              href={`${PAGE_ROUTES.MANAGER}?status=${
                type === 'Event'
                  ? 'Registered'
                  : type === 'Job'
                  ? 'Applied&entity=Job'
                  : 'Applied&entity=Internship'
              }`}
            >
              <button
                className={styles.toasterContentDismiss}
                onClick={onClose}
              >
                Open Manager
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderAlreadyAppliedToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>✅</span>
      <div>
        <p className={styles.toasterContentTitle}>You’ve already applied!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your email for confirmation and application manager for
            updates.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            Resend email
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderEventAlreadyRegisteredToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>✅</span>
      <div>
        <p className={styles.toasterContentTitle}>You’ve already registered!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your email for registration ticket and instructions.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            Resend email
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderToasterWithTickmark = (
  message?: string
): React.ReactElement => {
  return (
    <div
      className={cn(styles.toasterContent, {
        [styles.toasterContentCenter]: true,
      })}
    >
      <span className={styles.toasterContentIcon}>✅</span>
      <p className={styles.toasterContentTitle}>{message}</p>
    </div>
  );
};

export const renderEventAlreadyWaitlistedToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>✅</span>
      <div>
        <p className={styles.toasterContentTitle}>
          You’re already on waitlist!
        </p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your email for registration ticket and instructions.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderFeedbackToaster = (
  societyName: string,
  onClose?: () => void | undefined
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>🙌</span>
      <div>
        <p className={styles.toasterContentTitle}>Thank you!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            {societyName} and Huzzle appreciate your feedback and will aim to
            improve future events.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderJoinedWaitlistToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>✅</span>
      <div>
        <p className={styles.toasterContentTitle}>You’re on the Waitlist</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            If a ticket becomes available, you will be notified with further
            instructions.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderPreferencesToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>🥁</span>
      <div>
        <p className={styles.toasterContentTitle}>
          You’ve found your preferences!
        </p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Personalise them now or change them anytime from the home feed.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderMandatoryToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>🥶</span>
      <div>
        <p className={styles.toasterContentTitle}>
          You need to select something here!
        </p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            This step is mandatory. Select at least one option before you can
            proceed.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderExpiredToaster = (
  onClose?: () => void,
  type?: string
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>🔎</span>
      <div>
        <p className={styles.toasterContentTitle}>
          {`The ${type} has expired`}
        </p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            {`Search for other live ${type}s below!`}
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderFeatureNotification = (
  link: string,
  title: string,
  onClose?: () => void | undefined
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>
        <Icon iconName="icon_star" />
      </span>
      <div>
        <p className={styles.toasterContentTitle}>{title}</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            Check your{' '}
            <u>
              <Link href={link} target="_blank">
                company profile page
              </Link>
            </u>{' '}
            to see how it looks for candidates.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export const renderInvalidURLReportToaster = (
  onClose?: () => void
): React.ReactElement => {
  return (
    <div className={styles.toasterContent}>
      <span className={styles.toasterContentIcon}>🙌</span>
      <div>
        <p className={styles.toasterContentTitle}>Thank you!</p>
        <div className={styles.toasterContentFooter}>
          <p className={styles.toasterContentDescription}>
            We will investigate it ASAP and let you know once we have it fixed.
          </p>
          <button className={styles.toasterContentDismiss} onClick={onClose}>
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
