import React, { forwardRef, memo } from 'react';
import cn from 'classnames';

import { IconName } from 'components/Icon/Icon.component';
import { IconButton } from 'components/IconButton/IconButton.component';
import styles from './InputV2.module.scss';

export interface InputV2Props
  extends React.InputHTMLAttributes<HTMLInputElement> {
  inputStyle?: Record<string, unknown>;
  inputClassName?: string;
  labelClassName?: string;
  label?: string;
  isInvalid?: boolean;
  inputType?: 'primary' | 'secondary';
  context?: string;
  contextIcon?: IconName;
  onContextButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  showOptionalLabel?: boolean;
  infoToolTip?: React.ReactNode;
  infoToolTipClassName?: string;
  inputSize?: 'small' | 'large';
  showTabText?: string;
  showEnterText?: string;
  disabled?: boolean;
  rightIcon?: React.ReactNode;
  helperText?: string;
}

export const InputV2 = memo(
  forwardRef<HTMLInputElement | null, InputV2Props>(
    (
      {
        className = '', // custom class name
        style, // custom style
        inputStyle,
        inputClassName,
        labelClassName,
        label = '',
        id,
        isInvalid = false,
        inputType = 'primary',
        context = '',
        contextIcon,
        showOptionalLabel,
        infoToolTip,
        infoToolTipClassName,
        inputSize = 'small',
        onContextButtonClick,
        disabled = false,
        showTabText = '',
        showEnterText = '',
        rightIcon,
        helperText = '',
        ...props
      },
      ref
    ) => {
      const contentClassNames = cn(
        {
          [styles.InputV2]: true,
          [styles.InputV2IsInvalid]: isInvalid,
          [styles.InputV2IsDisabled]: disabled,
          [styles.InputV2Secondary]: inputType === 'secondary',
          [styles.InputV2Large]: inputSize === 'large',
        },
        className
      );

      return (
        <section
          className={contentClassNames}
          style={style}
          data-testid="InputV2"
        >
          {label ? (
            <label
              className={cn(styles.InputV2Label, labelClassName)}
              htmlFor={id}
            >
              <div>
                {label}
                {showOptionalLabel ? (
                  <span className={styles.optional}> (optional)</span>
                ) : null}
                {infoToolTip ? (
                  <span
                    className={cn(styles.infoToolTip, infoToolTipClassName)}
                  >
                    {infoToolTip}
                  </span>
                ) : null}
              </div>
              <div>
                {helperText ? (
                  <label htmlFor={id} className={styles.InputV2LabelHelperText}>
                    {helperText}
                  </label>
                ) : null}
              </div>
            </label>
          ) : null}
          <input
            id={id}
            ref={ref}
            data-testid="Input"
            aria-invalid={isInvalid}
            style={inputStyle}
            disabled={disabled}
            className={cn(styles.InputV2Element, inputClassName, {
              [styles.InputV2ElementTabText]: !!showTabText,

              [styles.InputV2ElementEnterText]: !!showEnterText,
              [styles.InputV2ElementRightIcon]: !!rightIcon,
            })}
            {...props}
          />
          {showTabText && (
            <div className={styles.tabText}>
              <span>Tab</span>
              {showTabText}
            </div>
          )}
          {showEnterText && (
            <div className={styles.tabText}>
              <span>Enter</span>
              {showEnterText}
            </div>
          )}
          {rightIcon && <div className={styles.rightIcon}>{rightIcon}</div>}
          {context ? (
            <div className={styles.contextContainer}>
              {contextIcon ? (
                <IconButton
                  iconName={contextIcon}
                  size="auto"
                  onClick={onContextButtonClick}
                  iconClassName={styles.contextIcon}
                />
              ) : null}
              <p className={styles.context}>{context}</p>
            </div>
          ) : null}
        </section>
      );
    }
  )
);

InputV2.displayName = 'InputV2';
