import { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import useSWR, { KeyedMutator } from 'swr';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { get, patch, post, put, remove } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import { CandidateAttributes, CandidateResponse } from 'lib/models/candidate';
import { httpHeadersState, userSecretState } from 'lib/atoms/userSecretAtom';
import {
  parseResponse,
  parseArrayResponse,
  parseErrorResponse,
  parseUserResponse,
} from 'lib/utils/parser';
import { InterestsResponse } from 'lib/models/interest';
import {
  StudentSocietyRegistration,
  StudentSocietyRegistrationAttributes,
} from 'lib/models/student-society-registration';
import { UserResponse, UserRole } from 'lib/models/user';
import { ApplicationResponse } from 'lib/models/application';
import { Notice } from 'lib/models/notice';
import { OPPORTUNITY_TYPE } from 'lib/models/opportunity';
import { TouchpointType } from 'lib/models/touchpoint';
import { useAuth } from 'lib/providers/AuthProvider';
import { useNotification } from './useNotification';

export type RegisteredCandidateResponse = {
  already_registered: boolean;
};

interface ReturnType {
  loginCandidate: ({
    email,
    source,
    touchpointable_type,
    check_email,
  }: {
    email: string;
    source?: string;
    touchpointable_type?: TouchpointType;
    check_email?: boolean;
  }) => Promise<CandidateResponse | RegisteredCandidateResponse>;
  updateCandidatePolicy: ({
    headers,
    accepted,
  }: {
    headers: Headers;
    accepted: boolean;
  }) => void;
  updateCandidateProfile: ({
    headers,
    email,
    university_id,
  }: {
    headers: Headers;
    email: string;
    university_id: string;
  }) => Promise<CandidateAttributes | undefined>;
  updateCandidateEducation: ({
    headers,
    study_year,
    subject_id,
    degree_id,
    university_id,
  }: {
    headers: Headers;
    study_year: number;
    subject_id: string;
    degree_id: string;
    university_id: string;
  }) => Promise<CandidateAttributes | undefined>;
  updateCandidateGoals: ({
    headers,
    goalIds,
  }: {
    headers: Headers;
    goalIds: Array<string>;
  }) => Promise<CandidateResponse | undefined>;
  updateCandidateInterests: ({
    headers,
    selectedInterests,
    interestable_type,
  }: {
    headers: Headers;
    selectedInterests: Array<{
      interestable_id: string;
      interestable_type: string;
      weight: number;
    }>;
    interestable_type: string;
  }) => Promise<InterestsResponse | undefined>;
  getHeaders: (candidateResponse: CandidateAttributes) => Headers;
  updateCandidateResume: ({
    headers,
    name,
    file,
  }: {
    headers: Headers;
    name: string;
    file: string;
  }) => Promise<CandidateAttributes | undefined>;
  removeCandidateResume: (
    headers: Headers
  ) => Promise<CandidateAttributes | undefined>;
  loginTemporaryCandidate: () => Promise<CandidateResponse | undefined>;
  updateCandidateEmail: ({
    headers,
    email,
  }: {
    headers: Headers;
    email: string;
  }) => Promise<CandidateAttributes | undefined>;
  authenticateUser: (candidate_id: string, token: string) => Promise<void>;
  updateCandidatePhoto: ({
    headers,
    file,
  }: {
    headers: Headers;
    file: string;
  }) => Promise<CandidateAttributes | undefined>;
  readAllCandidateNotifications: (headers: Headers) => Promise<void | unknown>;
  toggleCandidateNotification: (
    headers: Headers,
    enabled: boolean
  ) => Promise<CandidateAttributes | undefined>;
  deleteCandidate: (headers: Headers) => Promise<void | undefined>;
  updateCandidateName: ({
    headers,
    first_name,
    last_name,
  }: {
    headers: Headers;
    first_name: string;
    last_name: string;
  }) => Promise<CandidateAttributes | undefined>;
  updateCandidateUniversity: ({
    headers,
    university_id,
  }: {
    headers: Headers;
    university_id: string;
  }) => Promise<CandidateAttributes | undefined>;
  updateCandidateBio: ({
    headers,
    bio,
  }: {
    headers: Headers;
    bio: string;
  }) => Promise<CandidateAttributes | undefined>;
  updateCandidateSocialAccount: ({
    headers,
    url,
  }: {
    headers: Headers;
    url: string;
  }) => Promise<CandidateAttributes | undefined>;
  onSubscribe: ({
    email,
    student_society_id,
  }: {
    email: string;
    student_society_id: string;
  }) => Promise<StudentSocietyRegistrationAttributes | undefined>;
  verifyCandidateOTP: ({
    email,
    otp,
  }: {
    email: string;
    otp: string;
  }) => Promise<CandidateResponse | UserResponse | undefined>;
  isCandidate: (response: CandidateResponse | UserResponse) => boolean;
  updateTouchpointApplicationRating: (
    headers: Headers,
    touchpoint_application_id: string,
    rating: number
  ) => Promise<ApplicationResponse | undefined>;
  isNonOnboardedUser: boolean;
  isOldOnboardedUser: boolean;
  candidateInterestsResponse: InterestsResponse | undefined;
  updateCandidateOpportunityTypes: ({
    headers,
    opportunity_types,
  }: {
    headers: Headers;
    opportunity_types: Array<string>;
  }) => Promise<CandidateAttributes | undefined>;
  updateCandidateLocations: ({
    headers,
    city_ids,
    remote_opportunity,
  }: {
    headers: Headers;
    city_ids: Array<string>;
    remote_opportunity?: boolean;
  }) => Promise<CandidateAttributes | undefined>;
  userSelectedResponsibilities: Array<{
    interestable_id: string;
    interestable_type: string;
    weight: number;
  }>;
  candidateIndustries: Array<{
    interestable_id: string;
    interestable_type: string;
    weight: number;
  }>;
  updateCandidateReviewedPreferences: () => Promise<
    CandidateAttributes | undefined
  >;
  updateCandidateInternshipKind: ({
    headers,
    touchpointable_kinds,
  }: {
    headers: Headers;
    touchpointable_kinds: Array<string>;
  }) => Promise<CandidateAttributes | undefined>;
  updateCandidateEventId: ({
    headers,
    event_category_ids,
  }: {
    headers: Headers;
    event_category_ids: Array<string>;
  }) => Promise<CandidateAttributes | undefined>;
  updateCandidateOnboarded: () => Promise<CandidateAttributes | undefined>;
  isNextMatchesAvailable: boolean;
  mutateUser: KeyedMutator<CandidateResponse>;
  onUnsubscribe: (headers: Headers, id: string) => Promise<Notice | undefined>;
  candidateInterestsMutate: KeyedMutator<InterestsResponse>;
  updateCandidateRemotePreference: ({
    headers,
    country_ids,
  }: {
    headers: Headers;
    country_ids: Array<string>;
  }) => Promise<CandidateAttributes | undefined>;
  onGoogleLoginAuthentication: () => Promise<CandidateResponse | undefined>;
  onAppleLoginAuthentication: () => Promise<CandidateResponse | undefined>;
  isNewCandidate: (
    response: CandidateResponse | RegisteredCandidateResponse
  ) => boolean;
  updateCandidateVisaPreference: ({
    headers,
    visa_country_ids,
  }: {
    headers: Headers;
    visa_country_ids: Array<string>;
  }) => Promise<CandidateAttributes | undefined>;
  updateCandidateLanguage: ({
    headers,
    language_ids,
  }: {
    headers: Headers;
    language_ids: Array<string>;
  }) => Promise<CandidateAttributes | undefined>;
  updateLanguageBanner: ({
    headers,
    show_add_language_banner,
  }: {
    headers: Headers;
    show_add_language_banner: boolean;
  }) => Promise<CandidateAttributes | undefined>;
  updateCVBuilderBanner: ({
    headers,
    show_cv_builder_banner,
  }: {
    headers: Headers;
    show_cv_builder_banner: boolean;
  }) => Promise<CandidateAttributes | undefined>;
  hideAffiliatedSocietiesSection: ({
    headers,
    show_affiliated_societies,
  }: {
    headers: Headers;
    show_affiliated_societies: boolean;
  }) => Promise<CandidateAttributes | undefined>;
}

export const useCandidate = (): ReturnType => {
  const router = useRouter();
  const notificationInstance = useNotification();
  const [userSecret, setUserSecret] = useRecoilState(userSecretState);
  const { headers } = useRecoilValue(httpHeadersState);
  const {
    isCandidate: isUser,
    isTemporaryCandidate,
    setUser,
    setIsNewCandidate,
  } = useAuth();
  const { utm_source } = router.query;
  const { data: session, status } = useSession();

  const { data: candidateInterestsResponse, mutate: candidateInterestsMutate } =
    useSWR<InterestsResponse>(
      userSecret && isUser ? [API_ROUTES.CANDIDATE_INTERESTS, headers] : null,
      get,
      {
        revalidateOnFocus: false,
      }
    );

  const candidateResponsibilities = useMemo(() => {
    const list = parseArrayResponse(candidateInterestsResponse);
    return list
      .filter((item) => item.interestable_type === 'AreaOfResponsibility')
      .map((item) => ({
        interestable_id: item.interestable_id,
        interestable_type: 'AreaOfResponsibility',
        weight: item.weight,
      }));
  }, [candidateInterestsResponse]);

  const candidateIndustries = useMemo(() => {
    const list = parseArrayResponse(candidateInterestsResponse);
    return list
      .filter((item) => item.interestable_type === 'Industry')
      .map((item) => ({
        interestable_id: item.interestable_id,
        interestable_type: 'Industry',
        weight: item.weight,
      }));
  }, [candidateInterestsResponse]);

  const loginCandidate = async ({
    email,
    source,
    touchpointable_type,
    check_email,
  }: {
    email: string;
    source?: string;
    touchpointable_type?: TouchpointType;
    check_email?: boolean;
  }) => {
    const body = {
      user: {
        email,
        utm_source,
        userable_type: 'Candidate',
        userable_source: 'website',
      },
      touchpointable_type,
      source,
      check_email,
    };
    return await post<CandidateResponse>(API_ROUTES.CANDIDATE_CREATE, body);
  };

  const updateCandidatePolicy = async ({
    headers,
    accepted,
  }: {
    headers: Headers;
    accepted: boolean;
  }) => {
    const body = {
      candidate: { is_privacy_terms_accepted: accepted },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      const candidateResponse = parseResponse(response);
      if (candidateResponse) {
        const { user } = candidateResponse;
        const userResponse = parseResponse(user);
        const { authentication_token, email } = userResponse;
        setUserSecret({ token: authentication_token, email });
      } else {
        throw Error('Something went wrong');
      }
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateProfile = async ({
    headers,
    email,
    university_id,
  }: {
    headers: Headers;
    email: string;
    university_id: string;
  }) => {
    const body = {
      candidate: { email, university_id },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      const candidateResponse = parseResponse(response);
      if (candidateResponse) {
        const user = parseUserResponse(response);
        if (user) setUser(user);
        return candidateResponse;
      } else {
        throw Error('Something went wrong');
      }
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateEducation = async ({
    headers,
    university_id,
    study_year,
    subject_id,
    degree_id,
  }: {
    headers: Headers;
    university_id: string;
    study_year: number;
    subject_id: string;
    degree_id: string;
  }) => {
    const body = {
      candidate: { university_id, study_year, subject_id, degree_id },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      const candidateResponse = parseResponse(response);
      if (candidateResponse) {
        const user = parseUserResponse(response);
        if (user) setUser(user);
        return candidateResponse;
      } else {
        throw Error('Something went wrong');
      }
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateGoals = async ({
    headers,
    goalIds,
  }: {
    headers: Headers;
    goalIds: Array<string>;
  }) => {
    const body = {
      candidate: {
        goal_ids: goalIds,
      },
    };
    try {
      return await put<CandidateResponse>(API_ROUTES.CANDIDATE, body, headers);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateInterests = async ({
    headers,
    selectedInterests,
    interestable_type,
  }: {
    headers: Headers;
    selectedInterests: Array<{
      interestable_id: string;
      interestable_type: string;
      weight: number;
    }>;
    interestable_type: string;
  }) => {
    const body = {
      interests: selectedInterests,
      interestable_type_to_destroy: interestable_type,
    };
    try {
      return await post<InterestsResponse>(
        `${API_ROUTES.CANDIDATE_INTERESTS_UPDATE}?interestable_type_to_destroy=${interestable_type}`,
        body,
        headers
      );
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const getHeaders = (candidateResponse: CandidateAttributes) => {
    const { user } = candidateResponse;
    const userResponse = parseResponse(user);
    const { authentication_token, email } = userResponse;
    const headers: HeadersInit = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    });
    headers.set('X-Auth-Email', email);
    headers.set('X-Auth-Token', authentication_token);
    return headers;
  };

  const updateCandidateResume = async ({
    headers,
    name,
    file,
  }: {
    headers: Headers;
    name: string;
    file: string;
  }) => {
    const body = {
      candidate: {
        resume: {
          name,
          file,
        },
      },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const removeCandidateResume = async (headers: Headers) => {
    const body = {
      candidate: {
        resume: { _destroy: true },
      },
    };
    try {
      const response = await patch<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const loginTemporaryCandidate = async () => {
    const body = {
      user: {
        email: '',
        is_temporary: true,
        userable_type: 'Candidate',
        userable_source: 'website',
      },
    };
    try {
      return await post<CandidateResponse>(API_ROUTES.CANDIDATE_CREATE, body);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const authenticateUser = async (candidateId: string, token: string) => {
    const route = `${API_ROUTES.CANDIDATE}?candidate_id=${candidateId}&magic_token=${token}`;

    const response = await get<CandidateResponse>(route);
    const user = parseUserResponse(response);
    if (user) {
      const { authentication_token, email } = user;
      setUserSecret({ token: authentication_token, email });
      setUser(user);
    }
  };

  const updateCandidateEmail = async ({
    headers,
    email,
  }: {
    headers: Headers;
    email: string;
  }) => {
    const body = {
      user: { email, is_temporary: false },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE_EMAIL_UPDATE,
        body,
        headers
      );
      const candidateResponse = parseResponse(response);
      if (candidateResponse) {
        const user = parseUserResponse(response);
        if (user) {
          const { authentication_token, email } = user;
          setUserSecret({ token: authentication_token, email });
          setUser(user);
        }
        return candidateResponse;
      } else {
        throw Error('Something went wrong');
      }
    } catch (error) {
      if (isTemporaryCandidate) {
        throw error;
      } else {
        console.error(error);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const updateCandidatePhoto = async ({
    headers,
    file,
  }: {
    headers: Headers;
    file: string;
  }) => {
    const body = {
      candidate: {
        avatar: { file },
      },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const readAllCandidateNotifications = async (headers: Headers) => {
    const body = {};
    try {
      const response = await patch(
        `${API_ROUTES.CANDIDATE_NOTIFICATIONS}/read_all`,
        body,
        headers
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const toggleCandidateNotification = async (
    headers: Headers,
    enabled: boolean
  ) => {
    const body = {
      candidate: {
        notifications_enabled: enabled,
      },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const deleteCandidate = async (headers: Headers) => {
    try {
      return await remove<void>(API_ROUTES.CANDIDATE, headers);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateName = async ({
    headers,
    first_name,
    last_name,
  }: {
    headers: Headers;
    first_name: string;
    last_name: string;
  }) => {
    const body = {
      candidate: { first_name, last_name },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateUniversity = async ({
    headers,
    university_id,
  }: {
    headers: Headers;
    university_id: string;
  }) => {
    const body = {
      candidate: { university_id },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateBio = async ({
    headers,
    bio,
  }: {
    headers: Headers;
    bio: string;
  }) => {
    const body = {
      candidate: { bio },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateSocialAccount = async ({
    headers,
    url,
  }: {
    headers: Headers;
    url: string;
  }) => {
    const body = {
      candidate: { linkedin_url: url },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onSubscribe = async ({
    email,
    student_society_id,
  }: {
    email: string;
    student_society_id: string;
  }) => {
    const body = {
      student_society_registration: {
        email,
        student_society_id,
      },
    };
    try {
      const response = await post<{ data: StudentSocietyRegistration }>(
        API_ROUTES.CANDIDATE_SIGNUP,
        body,
        headers
      );
      if (response) {
        return parseResponse(response);
      }
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const isNewCandidate = (
    response: CandidateResponse | RegisteredCandidateResponse
  ): response is CandidateResponse => {
    const isNew = !(
      'already_registered' in response && response.already_registered
    );
    setIsNewCandidate(isNew);
    return isNew;
  };

  const verifyCandidateOTP = async ({
    email,
    otp,
  }: {
    email: string;
    otp: string;
  }) => {
    const body = {
      user: {
        email,
        otp,
      },
    };
    try {
      return await post<CandidateResponse | UserResponse>(
        API_ROUTES.SIGNIN,
        body
      );
    } catch (error) {
      const message = parseErrorResponse(error);
      throw Error(message);
    }
  };

  const isCandidate = (
    response: CandidateResponse | UserResponse
  ): response is CandidateResponse => {
    return response.data.type.toLowerCase() === UserRole.CANDIDATE;
  };

  const updateTouchpointApplicationRating = async (
    headers: Headers,
    touchpoint_application_id: string,
    rating: number
  ) => {
    const body = {
      touchpoint_application: {
        rating,
      },
    };
    const path = API_ROUTES.APPLICATION_RATING.replace(
      '[id]',
      touchpoint_application_id
    );
    try {
      return await put<ApplicationResponse>(path, body, headers);
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const { data: candidateResponse, mutate: mutateUser } =
    useSWR<CandidateResponse>(
      userSecret && isUser ? [API_ROUTES.CANDIDATE, headers] : null,
      get,
      { revalidateOnFocus: false }
    );

  const candidate = useMemo(
    () => parseResponse(candidateResponse),
    [candidateResponse]
  );
  const {
    onboarding_completed,
    next_job_state_empty,
    next_internship_state_empty,
    next_event_state_empty,
    opportunity_types,
  } = candidate || {};

  const isNonOnboardedUser = useMemo(() => {
    if (isUser && onboarding_completed === false) {
      return true;
    } else {
      return false;
    }
  }, [isUser, onboarding_completed]);

  const isOldOnboardedUser = useMemo(() => {
    if (
      isUser &&
      candidateInterestsResponse &&
      onboarding_completed === false
    ) {
      const filterInterest = candidateResponsibilities.filter(
        (data) => data.interestable_type === 'AreaOfResponsibility'
      );
      if (filterInterest && filterInterest.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }, [isCandidate, candidateResponsibilities]);

  const updateCandidateOpportunityTypes = async ({
    headers,
    opportunity_types,
  }: {
    headers: Headers;
    opportunity_types: Array<string>;
  }) => {
    const body = {
      candidate: { opportunity_types },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateLocations = async ({
    headers,
    city_ids,
    remote_opportunity,
  }: {
    headers: Headers;
    city_ids: Array<string>;
    remote_opportunity?: boolean;
  }) => {
    const body = {
      candidate: { city_ids, remote_opportunity },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateReviewedPreferences = async () => {
    const body = {
      candidate: { reviewed_preferences: true },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateInternshipKind = async ({
    headers,
    touchpointable_kinds,
  }: {
    headers: Headers;
    touchpointable_kinds: Array<string>;
  }) => {
    const body = {
      candidate: { touchpointable_kinds },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateEventId = async ({
    headers,
    event_category_ids,
  }: {
    headers: Headers;
    event_category_ids: Array<string>;
  }) => {
    const body = {
      candidate: { event_category_ids },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateOnboarded = async () => {
    const body = {
      candidate: { onboarding_completed: true },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const isNextMatchesAvailable = useMemo(() => {
    if (!opportunity_types || opportunity_types.length === 0) return false;

    const nextJobMatchesAvailable =
      opportunity_types.includes(OPPORTUNITY_TYPE.JOB) && !next_job_state_empty;
    const nextInternshipMatchesAvailable =
      opportunity_types.includes(OPPORTUNITY_TYPE.INTERNSHIP) &&
      !next_internship_state_empty;
    const nextEventMatchesAvailable =
      opportunity_types.includes(OPPORTUNITY_TYPE.EVENT) &&
      !next_event_state_empty;

    return (
      nextJobMatchesAvailable ||
      nextInternshipMatchesAvailable ||
      nextEventMatchesAvailable
    );
  }, [
    next_job_state_empty,
    next_internship_state_empty,
    next_event_state_empty,
    opportunity_types,
  ]);

  const onUnsubscribe = async (headers: Headers, id: string) => {
    const body = {
      student_society_id: id,
    };
    try {
      return await post<Notice>(
        `${API_ROUTES.STUDENT_SOCIETY_REGISTRATION}/unsubscribe`,
        body,
        headers
      );
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateRemotePreference = async ({
    headers,
    country_ids,
  }: {
    headers: Headers;
    country_ids: Array<string>;
  }) => {
    const body = {
      candidate: { country_ids },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onGoogleLoginAuthentication = async () => {
    if (session && status === 'authenticated') {
      const { user, oauth_uid, id_token, access_token, image } = session || {};
      const { name, email } = user || {};
      const first_name = name ? name.split(' ')[0] : '';
      const last_name = name ? name.split(' ')[1] : '';

      const body = {
        email,
        oauth_provider: 'google',
        oauth_uid,
        id_token,
        access_token,
        first_name: first_name || '',
        last_name: last_name || '',
        utm_source,
        avatar: image,
      };

      try {
        const response = await post<CandidateResponse>(
          '/api/v1/oauth_google',
          body
        );
        const candidateResponse = parseResponse(response as CandidateResponse);
        const { user } = candidateResponse;
        const userResponse = parseResponse(user);
        const { authentication_token, email } = userResponse;
        setUserSecret({ token: authentication_token, email });
        return response;
      } catch (error) {
        console.error(error);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const onAppleLoginAuthentication = async () => {
    if (session && status === 'authenticated') {
      const { user, oauth_uid, id_token, access_token, image } = session || {};

      const { name, email } = user || {};
      const first_name = name ? name.split(' ')[0] : email;
      const last_name = name ? name.split(' ')[1] : email;

      const body = {
        email,
        oauth_provider: 'apple',
        oauth_uid,
        id_token,
        access_token,
        first_name: first_name || '',
        last_name: last_name || '',
        utm_source,
        avatar: image,
      };

      try {
        const response = await post<CandidateResponse>(
          '/api/v1/oauth_apple',
          body
        );
        const candidateResponse = parseResponse(response as CandidateResponse);
        const { user } = candidateResponse;
        const userResponse = parseResponse(user);
        const { authentication_token, email } = userResponse;
        setUserSecret({ token: authentication_token, email });
        return response;
      } catch (error) {
        console.error(error);
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const updateCandidateVisaPreference = async ({
    headers,
    visa_country_ids,
  }: {
    headers: Headers;
    visa_country_ids: Array<string>;
  }) => {
    const body = {
      candidate: { visa_country_ids },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateLanguage = async ({
    headers,
    language_ids,
  }: {
    headers: Headers;
    language_ids: Array<string>;
  }) => {
    const body = {
      candidate: { language_ids },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateLanguageBanner = async ({
    headers,
    show_add_language_banner,
  }: {
    headers: Headers;
    show_add_language_banner: boolean;
  }) => {
    const body = {
      candidate: { show_add_language_banner },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCVBuilderBanner = async ({
    headers,
    show_cv_builder_banner,
  }: {
    headers: Headers;
    show_cv_builder_banner: boolean;
  }) => {
    const body = {
      candidate: { show_cv_builder_banner },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const hideAffiliatedSocietiesSection = async ({
    headers,
    show_affiliated_societies,
  }: {
    headers: Headers;
    show_affiliated_societies: boolean;
  }) => {
    const body = {
      candidate: { show_affiliated_societies },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  return {
    loginCandidate,
    updateCandidatePolicy,
    getHeaders,
    updateCandidateProfile,
    updateCandidateEducation,
    updateCandidateGoals,
    updateCandidateInterests,
    updateCandidateResume,
    removeCandidateResume,
    loginTemporaryCandidate,
    updateCandidateEmail,
    authenticateUser,
    updateCandidatePhoto,
    readAllCandidateNotifications,
    toggleCandidateNotification,
    deleteCandidate,
    updateCandidateName,
    updateCandidateUniversity,
    updateCandidateBio,
    updateCandidateSocialAccount,
    onSubscribe,
    verifyCandidateOTP,
    isCandidate,
    updateTouchpointApplicationRating,
    isNonOnboardedUser,
    isOldOnboardedUser,
    candidateInterestsResponse,
    userSelectedResponsibilities: candidateResponsibilities,
    candidateIndustries,
    updateCandidateOpportunityTypes,
    updateCandidateLocations,
    updateCandidateReviewedPreferences,
    updateCandidateInternshipKind,
    updateCandidateEventId,
    updateCandidateOnboarded,
    isNextMatchesAvailable,
    mutateUser,
    onUnsubscribe,
    candidateInterestsMutate,
    updateCandidateRemotePreference,
    onGoogleLoginAuthentication,
    onAppleLoginAuthentication,
    isNewCandidate,
    updateCandidateVisaPreference,
    updateCandidateLanguage,
    updateLanguageBanner,
    updateCVBuilderBanner,
    hideAffiliatedSocietiesSection,
  };
};
