import { Icon, Spinner } from 'components';
import { NotificationTypeEnum } from 'lib/models/notification';

import styles from './ToasterV2.module.scss';

type ToasterV2Props = {
  message: string;
  type: NotificationTypeEnum;
  loading?: boolean;
};

const ToasterV2: React.FC<ToasterV2Props> = ({ message, loading }) => {
  return (
    <div className={styles.container}>
      {loading ? (
        <Spinner size="xxsmall" color="secondary" />
      ) : (
        <Icon iconName={'icon_check-double'} size="small" />
      )}
      <div>
        <p
          dangerouslySetInnerHTML={{
            __html: message,
          }}
          className={styles.message}
        />
      </div>
    </div>
  );
};

ToasterV2.displayName = 'ToasterV2';

export default ToasterV2;
