import {
  Dispatch,
  SetStateAction,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useRouter } from 'next/router';

import { useAuth } from 'lib/providers/AuthProvider';
import { parseResponse } from 'lib/utils/parser';
import { Option } from 'lib/models/option';
import {
  ApplicationResponseModalEnum,
  CandidateApplicationType,
} from 'lib/models/candidate-applications';
import { ToolUserAttributes } from 'lib/models/user';
import { CandidateUserAttributes } from 'lib/models/candidate';
import { useCommonList } from 'lib/contexts/common-list-context';
import { RoomAttributes } from 'lib/models/candidate-messages';

export type CallbackFn = (headers: Headers) => Promise<void>;

export type CohostIds = { creatableForId: string; touchpointId: string };

export type UseAppReturnType = {
  setCallback: (callbackFn: CallbackFn | null) => void;
  getCallback: () => CallbackFn | null;
  routesVisited: Array<string>;
  email: string | null;
  setEmail: Dispatch<SetStateAction<string | null>>;
  societySearch: string | null;
  setSocietySearch: Dispatch<SetStateAction<string | null>>;
  shouldHideMobileFooter: boolean;
  setHideMobileFooter: Dispatch<SetStateAction<boolean>>;
  locationList: Array<Option>;
  followable_id: string | null;
  setFollowableId: Dispatch<SetStateAction<string | null>>;
  handleRemoveRoute: () => boolean;
  writeApplicationModalType: {
    type: ApplicationResponseModalEnum;
    application: CandidateApplicationType | null;
  } | null;
  setWriteApplicationModalType: Dispatch<
    SetStateAction<{
      type: ApplicationResponseModalEnum;
      application: CandidateApplicationType | null;
    } | null>
  >;
  previousRoute: string | null;
  setPreviousRoute: Dispatch<SetStateAction<string | null>>;
  userAttributes: ToolUserAttributes | CandidateUserAttributes | null;
  recruiterMessage: {
    sponsorship_student_society_id: string;
    sponsorship_message: string;
    sponsorship_plan_id: string | null;
  } | null;
  setRecruiterMessage: Dispatch<
    SetStateAction<{
      sponsorship_student_society_id: string;
      sponsorship_message: string;
      sponsorship_plan_id: string | null;
    } | null>
  >;
  roomData: RoomAttributes | null;
  setRoomData: Dispatch<SetStateAction<RoomAttributes | null>>;
};

export enum FLOW {
  ONBOARDING = 'onboarding',
  MATCHING_PREFERENCES = 'matching_preferences',
  MATCHING_PREFERENCES_SAVE = 'matching_preferences_save',
  SEEN_ALL_MACTHES = 'seen_all_matches',
  PREVIOUS_MATCH_GENERATED = 'previous_match_generated',
  PREVIOUS_MATCH_SET_COMPLETED = 'previous_match_set_completed',
  MP_EMPTY_MATCH = 'mp_empty_match',
}

export const useApp = (): UseAppReturnType => {
  const callbackRef = useRef<CallbackFn | null>(null);
  const router = useRouter();
  const { user } = useAuth();
  const { cities } = useCommonList();

  const [routesVisited, setRoutesVisted] = useState<Array<string>>([]);
  const [email, setEmail] = useState<string | null>(null);
  const [societySearch, setSocietySearch] = useState<string | null>(null);
  const [previousRoute, setPreviousRoute] = useState<string | null>(null);
  const [shouldHideMobileFooter, setHideMobileFooter] = useState(false);
  const [followable_id, setFollowableId] = useState<string | null>(null);
  const [roomData, setRoomData] = useState<RoomAttributes | null>(null);

  /** sponsorship request from recruiter to be saved
   * when recruiter is not logged in
   * and tried to message society
   */
  const [recruiterMessage, setRecruiterMessage] = useState<{
    sponsorship_student_society_id: string;
    sponsorship_message: string;
    sponsorship_plan_id: string | null;
  } | null>(null);

  const [writeApplicationModalType, setWriteApplicationModalType] = useState<{
    type: ApplicationResponseModalEnum;
    application: CandidateApplicationType | null;
  } | null>(null);

  // On clicking back from the browser, removing the route from the routesVisted
  // and pushing -1 instead.

  function handleRouteChange(route: string) {
    setRoutesVisted((routes) => {
      if (routes[routes.length - 1] === '-1')
        return routes.slice(0, routes.length - 1);
      else return [...routes, route];
    });
  }

  function handleRemoveRoute() {
    setRoutesVisted((routes) => {
      const newRoutes = routes.slice(0, routes.length - 1);
      return [...newRoutes, '-1'];
    });
    return true;
  }

  function getPreviousRoute() {
    const previousRoute = router.asPath;
    setPreviousRoute(previousRoute);
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    router.beforePopState(handleRemoveRoute);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', getPreviousRoute);
    return () => {
      router.events.off('routeChangeStart', getPreviousRoute);
    };
  }, [router]);

  function setCallback(callbackFn: CallbackFn | null) {
    callbackRef.current = callbackFn;
  }

  function getCallback() {
    return callbackRef.current;
  }

  const locationList = useMemo(() => {
    return cities.map(({ id, name, country, hidden }) => {
      const countryAttr = parseResponse(country);

      return {
        id: id,
        value: `${name}`, //Changing this from id to name, need to test if this breaks anywhere.
        label: `${name}`,
        country: countryAttr,
        timezoneMapping: countryAttr?.formatted_timezone_mapping,
        hidden,
      };
    });
  }, [cities]);

  return {
    roomData,
    setRoomData,
    setCallback,
    getCallback,
    routesVisited,

    email,
    setEmail,
    societySearch,
    setSocietySearch,
    shouldHideMobileFooter,
    setHideMobileFooter,
    locationList,
    followable_id,
    setFollowableId,
    handleRemoveRoute,
    writeApplicationModalType,
    setWriteApplicationModalType,
    previousRoute,
    setPreviousRoute,
    userAttributes: user,
    recruiterMessage,
    setRecruiterMessage,
  };
};
