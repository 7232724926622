import React, { memo } from 'react';
import cn from 'classnames';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import Button, { ButtonProps, ButtonVariant } from 'components/form/Button';

import styles from './Link.module.scss';

export interface LinkProps extends NextLinkProps, ButtonProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** children */
  children: React.ReactNode;
  /** is link underlined? */
  isUnderlined?: boolean;
  /** is priamry link? */
  isPrimary?: boolean;
  /** variant of the link */
  variant?: ButtonVariant;
  /** target */
  target?: '_blank' | '_self' | '_parent' | '_top';
  /** underline on hover */
  underlineOnHover?: boolean;
  /** onclick event handler on anchor */
  onAnchorClick?: () => void;
}

export const Link: React.FC<LinkProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    variant,
    isPrimary,
    children,
    isUnderlined,
    isFullWidth,
    color,
    borderless,
    target,
    underlineOnHover = true,
    onAnchorClick,
    ...props
  }: LinkProps) => {
    const contentClassNames = cn(
      {
        [styles.Link]: true,
        [styles.LinkUnderlined]: isUnderlined,
        [styles.LinkColorPrimary]: !variant && isPrimary,
        [styles.LinkVariantInline]: !variant,
        [styles.LinkUnderlinedOnHover]: underlineOnHover,
      },
      className
    );

    return (
      <NextLink {...props}>
        {variant ? (
          <Button
            variant={variant}
            color={color}
            isFullWidth={isFullWidth}
            borderless={borderless}
            style={style}
          >
            <a
              className={contentClassNames}
              target={target}
              onClick={onAnchorClick}
            >
              {children}
            </a>
          </Button>
        ) : (
          <a
            className={contentClassNames}
            style={style}
            target={target}
            onClick={onAnchorClick}
          >
            {children}
          </a>
        )}
      </NextLink>
    );
  }
);

Link.displayName = 'Link';
