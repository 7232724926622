import React, { memo, useState } from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import styles from './Tooltip.module.scss';

export interface TooltipProps {
  childrenClassName?: string;
  containerClassName?: string;
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** position of tooltip */
  position?: 'top' | 'bottom' | 'left' | 'right';
  /** horizontal alignment */
  xAlign?: 'left' | 'center' | 'right';
  /** vertical alignment */
  yAlign?: 'top' | 'middle' | 'bottom';
  /** tooltip content */
  content: React.ReactNode;
  /** color */
  color?: 'primary' | 'dark';
  /** children */
  children: React.ReactNode;
  disabled?: boolean;
  isV2Design?: boolean;
  hasWhitespaceNoWrap?: boolean;
  isRounded?: boolean;
}

export const Tooltip: React.FC<TooltipProps> = memo(
  ({
    childrenClassName,
    containerClassName,
    className = '', // custom class name
    style, // custom style
    position = 'bottom',
    xAlign = position === ('top' || 'bottom') ? 'center' : undefined,
    yAlign = position === ('left' || 'right') ? 'middle' : undefined,
    children,
    color = 'primary',
    content,
    disabled,
    isV2Design,
    hasWhitespaceNoWrap = true,
    isRounded = false,
  }: TooltipProps) => {
    const [isOpen, setOpen] = useState(false);
    const contentClassNames = cn(
      {
        [styles.TooltipContent]: true,
        [styles.TooltipContentRounded]: isRounded,
        [`${styles[`TooltipContentPosition${capitalize(position)}`]}`]:
          position,
        [`${styles[`TooltipContentColor${capitalize(color)}`]}`]: color,
        [`${styles[`TooltipContentHorizontal${capitalize(xAlign)}`]}`]: xAlign,
        [`${styles[`TooltipContentVertical${capitalize(yAlign)}`]}`]: yAlign,
        [styles.v2Design]: isV2Design,
        [styles['whitespace-nowrap']]: hasWhitespaceNoWrap,
      },
      className
    );

    return (
      <section className={cn(styles.Tooltip, containerClassName)}>
        <div
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          onClick={() => setOpen(true)}
          className={childrenClassName}
        >
          {children}
        </div>
        {isOpen && !disabled && (
          <div
            className={contentClassNames}
            style={style}
            data-testid="Tooltip"
          >
            {content}
          </div>
        )}
      </section>
    );
  }
);

Tooltip.displayName = 'Tooltip';
