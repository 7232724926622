import React, { forwardRef } from 'react';
import cn from 'classnames';

import styles from './Selection.module.scss';

export interface SelectionProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /** What is the type of selection */
  type: 'radio' | 'checkbox';
  /** id for the input element */
  id?: string;
  /**  should label hidden and only present for screen readers? */
  hideLabel?: boolean;
  /** data-testid of the element for testing */
  dataTestid?: string;
  /** default checked */
  defaultChecked?: boolean;
  /** tooltip title for label */
  labelTitle?: string;
}

export const Selection = forwardRef<HTMLInputElement, SelectionProps>(
  (
    {
      className = '', // custom class name
      style, // custom style
      type,
      id = '',
      hideLabel = false,
      dataTestid,
      defaultChecked,
      children,
      labelTitle,
      ...props
    },
    ref
  ) => {
    const contentClassNames = cn(
      {
        [styles.Selection]: true,
      },
      className
    );

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid={dataTestid || 'Selection'}
      >
        <input
          style={style}
          id={id}
          type={type}
          role={type}
          ref={ref}
          defaultChecked={defaultChecked}
          data-testid={`${dataTestid ? dataTestid : 'Selection'}-Input`}
          {...props}
        />
        {children && (
          <label
            htmlFor={id}
            className={hideLabel ? 'sr-only' : ''}
            title={labelTitle}
          >
            {children}
          </label>
        )}
      </div>
    );
  }
);
