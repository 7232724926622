import cn from 'classnames';
import format from 'date-fns/format';
import React, { memo, useMemo } from 'react';

import { Image, Link } from 'components';

import ProfilePics from 'components/candidate/ProfilePics';
import { Col, Row } from 'components/layout';

import Deadline from 'components/Deadline';
import Chip from 'components/Chip';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { CitiesResponse } from 'lib/models/city';
import { CohostAttributes } from 'lib/models/cohost';
import { CompanyAttributes, CompanyResponse } from 'lib/models/company';
import {
  StudentSocietyAttributes,
  StudentSocietyResponse,
} from 'lib/models/student-society';
import styles from './EventCard.module.scss';
import { formatToK, getHostNames } from 'utils/format';

export interface EventCardProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  title: string;
  url: string;
  start_date: string;
  avatar_urls: Array<string>;
  applications: number;
  icon_url: string;
  onNewTab?: boolean;
  registrationsDesc?: string;
  titleClassname?: string;
  dateClassname?: string;
  showTypeTitle?: boolean;
  application_deadline: string;
  cities: CitiesResponse | null;
  cohost_applications: Array<CohostAttributes> | null;
  creatable_for?: CompanyResponse | StudentSocietyResponse;
  showDetails: boolean;
  isSocietyEventCard?: boolean;
  registrationsDescClassName?: string;
  show_guest_list?: boolean;
}

export const EventCard: React.FC<EventCardProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    title,
    url,
    start_date,
    avatar_urls,
    applications,
    icon_url,
    onNewTab = false,
    registrationsDesc,
    titleClassname,
    dateClassname,
    showTypeTitle = false,
    application_deadline,
    cities,
    cohost_applications,
    creatable_for,
    showDetails,
    isSocietyEventCard = false,
    registrationsDescClassName,
    show_guest_list = true,
  }: EventCardProps) => {
    const contentClassNames = cn(
      {
        [styles.EventCard]: true,
      },
      className
    );

    const startDateTimeValue = start_date
      ? format(new Date(start_date), 'MMM d, h:mma')
      : 'Start Date: TBD';

    const leftApplicantsNumber = applications - 10;

    const createdByData = parseResponse<
      CompanyAttributes | StudentSocietyAttributes
    >(creatable_for);
    const { name: createdBy } = createdByData || {};
    const location = cities
      ? parseArrayResponse(cities)
      : [{ id: '1', name: 'London' }];
    const locationDetail = location.map(({ id, name }) => {
      return (
        <Chip key={id} className={cn(styles.chip)}>
          <span className={styles.name}>📍 {name}</span>
        </Chip>
      );
    });

    const hostsInfo = useMemo(
      () => getHostNames({ createdBy, cohost_applications }),
      [cohost_applications, createdBy]
    );

    return (
      <Link
        href={url}
        target={onNewTab ? '_blank' : '_self'}
        underlineOnHover={false}
      >
        <div
          className={contentClassNames}
          style={style}
          data-testid="EventCard"
        >
          <Row justify="space-between" wrap={false} gutter={10}>
            <Col className={styles.details}>
              {showTypeTitle && <p className={cn(styles.typeTitle)}>Event</p>}
              {isSocietyEventCard ? (
                <>
                  <p className={cn(styles.title, titleClassname)}>{title}</p>

                  <p className={cn(styles.date, dateClassname)}>
                    {startDateTimeValue}
                  </p>
                </>
              ) : (
                <>
                  <p className={cn(styles.date, dateClassname)}>
                    {startDateTimeValue}
                  </p>
                  <p className={cn(styles.title, titleClassname)}>{title}</p>
                </>
              )}
              <p className={styles.cohost}>{hostsInfo}</p>
            </Col>

            {icon_url && (
              <Col>
                <Image
                  src={icon_url}
                  source={undefined}
                  className={styles.img}
                  alt={`Cover Photo of ${title}`}
                />
              </Col>
            )}
          </Row>
          {showDetails ? (
            <>
              {application_deadline || location?.length > 0 ? (
                <Row align="center" className={styles.chipRow}>
                  {locationDetail}
                  {application_deadline && (
                    <Deadline application_deadline={application_deadline} />
                  )}
                </Row>
              ) : null}
            </>
          ) : null}
          {applications >= 10 && show_guest_list ? (
            <Row align="center" className={cn(styles.registrationsRow)}>
              <Col className={cn(styles.registrationsCol)}>
                <ProfilePics
                  maxPicsToShow={10}
                  urls={avatar_urls}
                  className={styles.profile}
                />
                {leftApplicantsNumber > 0 ? (
                  <div className={styles.numberBorder}>
                    <p className={styles.number}>+{leftApplicantsNumber}</p>
                  </div>
                ) : null}
              </Col>
              <Col>
                <p
                  className={cn(
                    styles.registrationsDesc,
                    registrationsDescClassName
                  )}
                >
                  {formatToK(applications)}{' '}
                  {registrationsDesc
                    ? registrationsDesc
                    : 'students like you already registered to this event.'}
                </p>
              </Col>
            </Row>
          ) : null}
        </div>
      </Link>
    );
  }
);

EventCard.displayName = 'EventCard';
