import React, { memo, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import useSWR from 'swr';
import { useRecoilState, useRecoilValue } from 'recoil';

import router from 'next/router';
import { IconButton, Image } from 'components';
import { Row } from 'components/layout';
import { Button } from 'components/form';
import { useCandidate } from 'hooks/useCandidate';
import { useNotification } from 'hooks/useNotification';
import { httpHeadersState, userSecretState } from 'lib/atoms/userSecretAtom';
import { get } from 'lib/utils/http';
import { parseResponse } from 'lib/utils/parser';
import { CandidateResponse } from 'lib/models/candidate';
import { API_ROUTES } from 'lib/api-routes';

import { PAGE_ROUTES } from 'lib/page-routes';
import styles from './ChatPopUp.module.scss';

export interface ChatPopUpProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  option: number | null;
  rating: number | undefined;
  touchpointApplicationId: string;
}

export const ChatPopUp: React.FC<ChatPopUpProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    option,
    rating,
    touchpointApplicationId,
  }: ChatPopUpProps) => {
    const contentClassNames = cn(
      {
        [styles.ChatPopUp]: true,
      },
      className
    );
    const notificationInstance = useNotification();
    const { headers } = useRecoilValue(httpHeadersState);
    const [userSecret] = useRecoilState(userSecretState);
    const { updateTouchpointApplicationRating } = useCandidate();

    const { data: userResponse } = useSWR<CandidateResponse>(
      userSecret ? [API_ROUTES.CANDIDATE, headers] : null,
      get
    );

    const userInfo = parseResponse(userResponse);

    const { first_name } = userInfo || {};

    const [isChatBoxOpen, setChatBoxOpen] = useState(true);

    const chats = useMemo(() => {
      switch (option) {
        case 1:
          return (
            <>
              <p className={styles.text}>Hey {first_name} 👋</p>
              <p className={styles.text}>Welcome to Huzzle!</p>
              <p className={styles.text}>
                Tell us your career goals, interests and we will bring you
                super-personalized career opportunities
              </p>
              <p className={styles.text}>
                Hit <span>Start now</span> to setup your preferences or browse a
                bit first
              </p>
            </>
          );
        case 2:
          return (
            <>
              <p className={styles.text}>Thanks for the feedback! 🙌</p>
              <p className={styles.text}>
                We’re sorry to hear the event didn’t meet your expectations 😞
              </p>
              <p className={styles.text}>
                We have other amazing events, jobs and internships that may suit
                you better
              </p>
              <p className={styles.text}>
                Hit <span>Start now</span> to setup your preferences and get
                personalised opportunities
              </p>
            </>
          );
        case 3:
          return (
            <>
              <p className={styles.text}>Thanks for the feedback! 🙌</p>
              <p className={styles.text}>
                We’re glad to hear you enjoyed the event 😁
              </p>
              <p className={styles.text}>
                We have more amazing events, jobs and internships
              </p>
              <p className={styles.text}>
                Hit <span>Start now</span> to setup your preferences and get
                personalised opportunities
              </p>
            </>
          );
      }
    }, [option, first_name]);

    useEffect(() => {
      (async () => {
        if (rating && touchpointApplicationId) {
          try {
            await updateTouchpointApplicationRating(
              headers,
              touchpointApplicationId,
              rating
            );
          } catch (error) {
            notificationInstance.handleExceptionError(error);
          }
        }
      })();
    }, [rating, touchpointApplicationId]);

    if (!option) return <></>;

    return (
      <div className={contentClassNames} style={style} data-testid="ChatPopUp">
        {isChatBoxOpen ? (
          <div className={styles.chatBox}>
            <Row justify="space-between" className={styles.header}>
              <Row align="center" className={styles.row}>
                <Image
                  className={styles.logo}
                  style={style}
                  src="/images/huzzle-logo-small.png"
                />
                <p className={styles.title}>Huzzle</p>
              </Row>
              <IconButton
                size="small"
                iconName="close"
                onClick={() => setChatBoxOpen(false)}
                className={styles.close}
              />
            </Row>
            <div className={styles.chatBoxContent}>
              {chats}
              <Button
                isFullWidth={true}
                onClick={() => {
                  setChatBoxOpen(false);
                  router.push(PAGE_ROUTES.CANDIDATE_ONBOARDING);
                }}
                className={styles.button}
              >
                Start now
              </Button>
            </div>
          </div>
        ) : (
          <div
            className={styles.logoContainer}
            onClick={() => setChatBoxOpen(true)}
          >
            <Image
              className={styles.logo}
              style={style}
              src="/images/huzzle-logo-small.png"
            />
          </div>
        )}
      </div>
    );
  }
);

ChatPopUp.displayName = 'ChatPopUp';
