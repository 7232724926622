import { useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import useSWR, { KeyedMutator } from 'swr';
import { saveAs } from 'file-saver';

import { get, patch } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import {
  CandidateAttributes,
  CandidateResponse,
  EducationHistoryAttributes,
  ExperienceAttributes,
  ExtraCurricularAttributes,
  AccomplishmentResponseAttributes,
} from 'lib/models/candidate';
import { httpHeadersState, userSecretState } from 'lib/atoms/userSecretAtom';
import { useAuth } from 'lib/providers/AuthProvider';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { UniversityAttributes } from 'lib/models/university';
import { useNotification } from './useNotification';

export enum USERINFO_LABELS {
  BASIC_INFO = 'Basic info',
  CONTACTS = 'Contacts',
  EDUCATION = 'Education',
  EXPERIENCE = 'Experience',
  EXTRA_CURRICULAR_ACTIVITIES = 'Extra curricular activities',
  SKILLS = 'Skills and achievements',
  DEMOGRAPHIC_INFO = 'Demographic information',
}

export interface UserInfoStatusType {
  label: USERINFO_LABELS;
  text: string;
  desc: string;
  recommended: boolean;
  completed: boolean;
}

export interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface BasicInfoType {
  first_name: string;
  last_name: string;
  bio: string;
  avatar_url: string;
  avatar: string;
  display_huzzle_verified?: boolean;
  huzzle_verified: boolean;
  languages: Array<string> | undefined;
  country_id: string;
}

interface DemographicInfoType {
  gender: string;
  ethnicities: Array<string> | undefined;
  physical_disability: string;
  congnitive_disability: string;
  socio_economic_status: string;
}

interface ContactsInfoType {
  email: string;
  linkedin_url: string;
  phone_number: string;
  location: string;
}

export interface SchoolDetailsType {
  id: string;
  type: string;
  school_name: string;
  school_type: string;
  start_date: string;
  end_date: string;
  description: string;
  location: string;
  isNew: boolean;
}

/* Making few fields as optional in UniversityDetailsType,
 *  they are reqd in build profile
 *  but are optional in Onboarding flow
 */
export interface UniversityDetailsType {
  id: string;
  type: string;
  university: {
    id?: string;
    name: string;
  };
  degree: {
    id: string;
    name: string;
  };
  subject: {
    id: string;
    name: string;
  };
  study_year: string | null;
  start_date?: string;
  end_date?: string;
  description?: string;
  isEndDateFuture?: boolean;
  currently_studying?: boolean;
  isNew: boolean;
  degree_name?: string;
  location?: string;
}

interface CandidateDetailsType {
  first_name?: string;
  last_name?: string;
  avatar?: string;
  bio?: string;
  linkedin_url?: string;
  phone_number?: string;
  location?: string;
  remove_avatar?: boolean;
  display_huzzle_verified?: boolean;
  gender?: string;
  ethnicities?: Array<string>;
  languages?: Array<string>;
  country_id?: string;
  physical_disability?: string;
  congnitive_disability?: string;
  socio_economic_status?: string;
}

export interface ReturnType {
  candidate: CandidateAttributes | undefined;
  mutateCandidate: KeyedMutator<CandidateResponse>;
  candidateBasicInfo: BasicInfoType;
  setCandidateBasicInfo: Dispatch<SetStateAction<BasicInfoType>>;
  candidateDemographicInfo: DemographicInfoType;
  setCandidateDemographicInfo: Dispatch<SetStateAction<DemographicInfoType>>;
  contactsInfo: ContactsInfoType;
  setContactsInfo: Dispatch<SetStateAction<ContactsInfoType>>;
  resume_url: string;
  email: string;
  universityId: string;
  universityInfo: Array<UniversityDetailsType>;
  setUniversityInfo: Dispatch<SetStateAction<Array<UniversityDetailsType>>>;
  schoolInfo: Array<SchoolDetailsType>;
  setSchoolInfo: Dispatch<SetStateAction<Array<SchoolDetailsType>>>;
  experienceInfo: Array<
    ExperienceAttributes & {
      isNew: boolean;
    }
  >;
  setExperienceInfo: Dispatch<
    SetStateAction<Array<ExperienceAttributes & { isNew: boolean }>>
  >;
  extraCurricularInfo: Array<
    ExtraCurricularAttributes & {
      isNew: boolean;
    }
  >;
  setExtraCurricularInfo: Dispatch<
    SetStateAction<Array<ExtraCurricularAttributes & { isNew: boolean }>>
  >;
  accomplishmentsInfo: Array<
    AccomplishmentResponseAttributes & { isNew: boolean }
  >;
  setAccomplishmentsInfo: Dispatch<
    SetStateAction<Array<AccomplishmentResponseAttributes & { isNew: boolean }>>
  >;

  removeAttribute: (id: string, type: string) => Promise<void>;
  generateCV: () => Promise<void>;
  updateWorkHistory: (
    info: ExperienceAttributes & { isNew: boolean }
  ) => Promise<void>;
  updateExtraCurricular: (
    info: ExtraCurricularAttributes & { isNew: boolean }
  ) => Promise<void>;
  updateAccomplishment: (
    info: AccomplishmentResponseAttributes & { isNew: boolean }
  ) => Promise<void>;
  updateUniversity: (
    info: UniversityDetailsType
  ) => Promise<CandidateResponse | undefined>;
  updateSchool: (info: SchoolDetailsType) => Promise<void>;
  updateCandidateDetails: (info: CandidateDetailsType) => Promise<void>;
}

export const useCandidateProfile = (): ReturnType => {
  const notificationInstance = useNotification();
  const [userSecret] = useRecoilState(userSecretState);
  const { headers, plainHeaders } = useRecoilValue(httpHeadersState);
  const { isCandidate } = useAuth();

  const [candidateBasicInfo, setCandidateBasicInfo] = useState<BasicInfoType>({
    first_name: '',
    last_name: '',
    bio: '',
    avatar_url: '',
    avatar: '',
    display_huzzle_verified: false,
    huzzle_verified: false,
    languages: [],
    country_id: '',
  });

  const [candidateDemographicInfo, setCandidateDemographicInfo] =
    useState<DemographicInfoType>({
      gender: '',
      ethnicities: [],
      physical_disability: '',
      congnitive_disability: '',
      socio_economic_status: '',
    });

  const [contactsInfo, setContactsInfo] = useState<ContactsInfoType>({
    email: '',
    linkedin_url: '',
    phone_number: '',
    location: '',
  });

  const [universityInfo, setUniversityInfo] = useState<
    Array<UniversityDetailsType>
  >([]);

  const [schoolInfo, setSchoolInfo] = useState<Array<SchoolDetailsType>>([]);

  const [experienceInfo, setExperienceInfo] = useState<
    Array<ExperienceAttributes & { isNew: boolean }>
  >([]);

  const [extraCurricularInfo, setExtraCurricularInfo] = useState<
    Array<ExtraCurricularAttributes & { isNew: boolean }>
  >([]);

  const [accomplishmentsInfo, setAccomplishmentsInfo] = useState<
    Array<AccomplishmentResponseAttributes & { isNew: boolean }>
  >([]);

  const { data: candidateResponse, mutate: mutateCandidate } =
    useSWR<CandidateResponse>(
      userSecret && isCandidate ? [API_ROUTES.CANDIDATE, headers] : null,
      get,
      { revalidateOnFocus: false }
    );

  const candidate = useMemo(
    () => parseResponse(candidateResponse),
    [candidateResponse]
  );
  const {
    first_name,
    last_name,
    bio,
    avatar_url,
    resume_url,
    email,
    education_histories: educationHistoriesResponse,
    work_histories: experiencesResponse,
    extra_curricular_activities: extraCurricularActivitiesResponse,
    linkedin_url,
    phone_number,
    location,
    university: universityResponse,
    accomplishments: accomplishmentsResponse,
    gender,
    ethnicities,
    languages,
    country_id,
    physical_disability,
    congnitive_disability,
    socio_economic_status,
    display_huzzle_verified,
    huzzle_verified,
  } = candidate || {};
  const universityId =
    parseResponse<UniversityAttributes>(universityResponse)?.id;

  useEffect(() => {
    if (candidate) {
      setCandidateBasicInfo({
        first_name,
        last_name,
        bio,
        avatar_url,
        avatar: '',
        display_huzzle_verified,
        huzzle_verified,
        country_id,
        languages: languages?.data?.map((item) => item.id),
      });
      setCandidateDemographicInfo({
        gender,
        ethnicities: ethnicities?.data?.map((item) => item.id),
        congnitive_disability,
        physical_disability,
        socio_economic_status,
      });
      setContactsInfo({
        email,
        linkedin_url: linkedin_url || '',
        phone_number: phone_number || '',
        location: location || '',
      });

      const educationHistories = parseArrayResponse<EducationHistoryAttributes>(
        educationHistoriesResponse
      );
      const uniDetails = educationHistories
        .filter((item) => item.education_type === 'university')
        .map((info) => {
          const {
            id,
            start_date,
            end_date,
            subject_id,
            subject_name,
            description,
            current_study_year,
            university: universityResponse,
            degree: degreeResponse,
            degree_name,
            university_id,
            university_name,
            location,
            currently_studying,
          } = info;
          const university =
            parseResponse<UniversityAttributes>(universityResponse);
          const degree = parseResponse(degreeResponse);
          return {
            id,
            type: 'University',
            university: {
              id: university?.id || university_id,
              name: university?.name || university_name || '',
            },
            degree: {
              id: degree?.id,
              name: degree?.name,
            },
            subject: {
              id: subject_id,
              name: subject_name,
            },
            study_year: current_study_year ? String(current_study_year) : null,
            start_date,
            end_date,
            currently_studying: currently_studying,
            description: (description as string[])
              ?.map((pointer) => `• ${pointer}`)
              .join('\n'),

            isNew: false,
            degree_name,
            location,
          };
        });
      setUniversityInfo(uniDetails);

      const schoolDetails = educationHistories
        .filter((item) => item.education_type === 'school')
        .map((info) => {
          const {
            id,
            school_name,
            school_type,
            start_date,
            end_date,
            description,
            location,
          } = info;

          return {
            id,
            type: 'School',
            school_name,
            school_type,
            start_date,
            end_date,
            description: (description as string[])
              ?.map((pointer) => `• ${pointer}`)
              .join('\n'),
            location,
            isNew: false,
          };
        });
      setSchoolInfo(schoolDetails);

      const experiencesList =
        parseArrayResponse<ExperienceAttributes>(experiencesResponse);
      const experiences = experiencesList.map((item) => {
        return {
          ...item,
          description: (item.description as string[])
            ?.map((pointer) => `• ${pointer}`)
            .join('\n'),
          isNew: false,
        };
      });
      setExperienceInfo(experiences);

      const extraCurricularList = parseArrayResponse<ExtraCurricularAttributes>(
        extraCurricularActivitiesResponse
      );
      const extraCurricular = extraCurricularList.map((item) => {
        return {
          ...item,
          description: (item.description as string[])
            ?.map((pointer) => `• ${pointer}`)
            .join('\n'),
          isNew: false,
        };
      });
      setExtraCurricularInfo(extraCurricular);

      const accomplishmentsList =
        parseArrayResponse<AccomplishmentResponseAttributes>(
          accomplishmentsResponse
        );
      const accomplishments = accomplishmentsList.map((item) => ({
        ...item,
        isNew: false,
      }));
      setAccomplishmentsInfo(accomplishments);
    }
  }, [candidate]);

  const removeAttribute = async (id: string, type: string) => {
    let body;
    switch (type) {
      case 'education':
        body = {
          candidate: {
            education_histories_attributes: { id, _destroy: true },
          },
        };
        break;
      case 'work_history':
        body = {
          candidate: {
            work_histories_attributes: { id, _destroy: true },
          },
        };
        break;
      case 'extra_curricular':
        body = {
          candidate: {
            extra_curricular_activities_attributes: { id, _destroy: true },
          },
        };
        break;
      case 'accomplishment':
        body = {
          candidate: {
            accomplishments_attributes: { id, _destroy: true },
          },
        };
        break;
      default:
        body = {
          candidate: {
            id,
            _destroy: true,
          },
        };
    }

    try {
      await patch<CandidateResponse>(API_ROUTES.CANDIDATE, body, headers);
      mutateCandidate();
      notificationInstance.success({
        title: 'Entry removed!',
        message: `Row has been removed successfully.`,
      });
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const generateCV = async () => {
    try {
      const { first_name, last_name } = candidateBasicInfo;
      const { NEXT_PUBLIC_API_HOST_URL } = process.env;
      plainHeaders.set('Content-Type', 'application/pdf');
      plainHeaders.set('Accept', 'application/pdf');
      plainHeaders.set('Response-Type', 'application/pdf');
      const response = await fetch(
        `${NEXT_PUBLIC_API_HOST_URL}${API_ROUTES.CANDIDATE_DOWNLOAD_CV}`,
        {
          method: 'POST',
          headers: plainHeaders,
        }
      );
      const data = await response.blob();
      const blobURL = window.URL.createObjectURL(data);
      const fileName = `${first_name}-${last_name}-CV.pdf`;
      await saveAs(blobURL, fileName);
      notificationInstance.success({
        title: 'CV downloaded!',
        message: 'Your CV has been downloaded successfully.',
      });
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateDetails = async ({
    first_name,
    last_name,
    avatar,
    bio,
    linkedin_url,
    phone_number,
    location,
    remove_avatar,
    display_huzzle_verified,
    gender,
    ethnicities,
    languages,
    country_id,
    congnitive_disability,
    physical_disability,
    socio_economic_status,
  }: CandidateDetailsType) => {
    const body = {
      candidate: {
        ...(bio !== undefined && { bio }),
        ...(last_name && { last_name }),
        ...(first_name && { first_name }),
        ...(linkedin_url !== undefined && { linkedin_url }),
        ...(phone_number !== undefined && { phone_number }),
        ...(location !== undefined && { location }),
        ...(avatar && { avatar: { file: avatar } }),
        ...(remove_avatar && { remove_avatar }),
        ...{ display_huzzle_verified },
        ...(country_id && { country_id }),
        ...(gender && { gender }),
        ...(congnitive_disability && { congnitive_disability }),
        ...(physical_disability && { physical_disability }),
        ...(socio_economic_status && { socio_economic_status }),
        ...(ethnicities && { ethnicity_ids: ethnicities }),
        ...(languages && { language_ids: languages }),
      },
    };

    try {
      await patch<CandidateResponse>(API_ROUTES.CANDIDATE, body, headers);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateWorkHistory = async (
    info: ExperienceAttributes & {
      isNew: boolean;
    }
  ) => {
    const {
      id,
      isNew,
      organisation_name,
      role,
      start_date,
      end_date,
      description,
      website,
      generated_description_disable,
      generated_description,
      presently_working,
      location,
    } = info;

    const body = {
      candidate: {
        work_histories_attributes: [
          {
            id: isNew ? '' : id,
            start_date,
            end_date,
            description: !Array.isArray(description)
              ? description
                  ?.split('\n')
                  .map((item) => item.replace(/•/g, '').trim())
              : description,
            organisation_name,
            role,
            website,
            generated_description_disable,
            generated_description,
            presently_working,
            location,
          },
        ],
      },
    };

    try {
      await patch<CandidateResponse>(API_ROUTES.CANDIDATE, body, headers);
      mutateCandidate();
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };
  const updateExtraCurricular = async (
    info: ExtraCurricularAttributes & {
      isNew: boolean;
    }
  ) => {
    const {
      id,
      isNew,
      organisation_name,
      role,
      start_date,
      end_date,
      description,
      website,
      generated_description_disable,
      generated_description,
      presently_engaged,
      location,
    } = info;

    const body = {
      candidate: {
        extra_curricular_activities_attributes: [
          {
            id: isNew ? '' : id,
            start_date,
            end_date,
            description: !Array.isArray(description)
              ? description
                  ?.split('\n')
                  .map((item) => item.replace(/•/g, '').trim())
              : description,
            organisation_name,
            role,
            website,
            generated_description_disable,
            generated_description,
            presently_engaged,
            location,
          },
        ],
      },
    };

    try {
      await patch<CandidateResponse>(API_ROUTES.CANDIDATE, body, headers);
      mutateCandidate();
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateAccomplishment = async (
    info: AccomplishmentResponseAttributes & {
      isNew: boolean;
    }
  ) => {
    const { id, isNew, title, description } = info;

    const body = {
      candidate: {
        accomplishments_attributes: [
          {
            id: isNew ? '' : id,
            description,
            title,
          },
        ],
      },
    };

    try {
      await patch<CandidateResponse>(API_ROUTES.CANDIDATE, body, headers);
      mutateCandidate();
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateUniversity = async (info: UniversityDetailsType) => {
    const {
      id,
      isNew,
      university,
      degree,
      subject,
      start_date,
      end_date,
      description,
      study_year,
      degree_name,
      location,
      currently_studying,
    } = info;
    const body = {
      candidate: {
        education_histories_attributes: [
          {
            id: isNew ? '' : id,
            start_date,
            end_date,
            description: !Array.isArray(description)
              ? description
                  ?.split('\n')
                  .map((item) => item.replace(/•/g, '').trim())
              : description,
            ...(university.id && { university_id: university.id }),
            ...(!university.id && { university_name: university.name }),
            degree_id: degree.id,
            subject_id: subject.id,
            subject_name: subject.name,
            education_type: 'university',
            currently_studying: currently_studying,
            current_study_year: study_year ? Number(study_year) : null,
            ...(degree_name && { degree_name }),
            location,
          },
        ],
      },
    };
    try {
      const response = await patch<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      mutateCandidate();
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateSchool = async (info: SchoolDetailsType) => {
    const {
      id,
      isNew,
      school_name,
      school_type,
      start_date,
      end_date,
      description,
      location,
    } = info;
    const body = {
      candidate: {
        education_histories_attributes: [
          {
            id: isNew ? '' : id,
            start_date,
            end_date,
            description: !Array.isArray(description)
              ? description
                  ?.split('\n')
                  .map((item) => item.replace(/•/g, '').trim())
              : description,
            school_name,
            school_type,
            education_type: 'school',
            location,
          },
        ],
      },
    };
    try {
      await patch<CandidateResponse>(API_ROUTES.CANDIDATE, body, headers);
      mutateCandidate();
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  return {
    candidate,
    mutateCandidate,
    candidateBasicInfo,
    setCandidateBasicInfo,
    candidateDemographicInfo,
    setCandidateDemographicInfo,
    contactsInfo,
    setContactsInfo,
    resume_url,
    email,
    universityId,
    universityInfo,
    setUniversityInfo,
    schoolInfo,
    setSchoolInfo,
    experienceInfo,
    setExperienceInfo,
    extraCurricularInfo,
    setExtraCurricularInfo,
    accomplishmentsInfo: accomplishmentsInfo,
    setAccomplishmentsInfo: setAccomplishmentsInfo,
    removeAttribute,
    generateCV,
    updateWorkHistory,
    updateExtraCurricular,
    updateAccomplishment,
    updateUniversity,
    updateSchool,
    updateCandidateDetails,
  };
};
