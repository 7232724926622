import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import cn from 'classnames';

import { Icon, IconButton, Spinner } from 'components';
import { Modal } from 'components/Modal/Modal.component';
import { ButtonV3 } from 'components/ComponentV2';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { TextArea } from 'components/form';
import { useResponsive } from 'hooks/useResponsive';
import { useNotification } from 'hooks/useNotification';
import { useMatchingExperience } from 'hooks/useMatchingExperience';
import {
  ApplicationResponseModalEnum,
  CandidateApplicationType,
} from 'lib/models/candidate-applications';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAIGeneratedResponse } from '../useAIGeneratedResponse';

import styles from './ResponseModal.module.scss';

interface ResponseModalProps {
  isOpen: boolean;
  modalType?: ApplicationResponseModalEnum;
  onClose: () => void;
  application?: CandidateApplicationType;
}

const ResponseModal: React.FC<ResponseModalProps> = (
  props: ResponseModalProps
) => {
  const { onClose, isOpen, modalType, application } = props;
  const router = useRouter();

  const [coverLetterValue, setCoverLetterValue] = useState<string>('');
  const [questionResponse, setQuestionResponse] = useState<string>('');
  const [coverLetterStore, setCoverLetterStore] = useState<{
    [key: string]: string;
  }>({});
  const [questionData, setQuestionData] = useState<string>('');
  const [generateAnswerSuccess, setGenerateAnswerSuccess] = useState<
    boolean | null
  >(null);
  const [generateAnswerError, setGenerateAnswerError] = useState<
    boolean | null
  >(null);
  const [generateAnswerDismissed, setGenerateAnswerDismissed] = useState<
    boolean | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showProfileBanner, setShowProfileBanner] = useState<boolean>(false);
  const [dots, setDots] = useState('');
  const screens = useResponsive();
  const {
    generateCoverLetter,
    generateQuestionReply,
    abortGenerateQuestionReply,
  } = useAIGeneratedResponse();
  const notificationInstance = useNotification();
  const { candidateDashboard } = useMatchingExperience();
  const { profile_complete } = candidateDashboard || {};

  const animatedPlaceholder = `Generating${dots}\nPlease wait, or start typing to cancel.`;

  useEffect(() => {
    if (!isOpen) {
      setCoverLetterValue('');
      setQuestionResponse('');
      setQuestionData('');
      setGenerateAnswerSuccess(null);
      setGenerateAnswerError(null);
      setGenerateAnswerDismissed(null);
      setIsLoading(false);
      setDots('');
    } else {
      setShowProfileBanner(!profile_complete);
    }
  }, [isOpen]);

  useEffect(() => {
    if (modalType && modalType === ApplicationResponseModalEnum.coverLetter) {
      onGetCoverLetter();
    }
  }, [modalType]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isLoading) {
      interval = setInterval(() => {
        setDots((prevDots) => {
          if (prevDots.length >= 3) {
            return '';
          } else {
            return prevDots + '.';
          }
        });
      }, 500);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isLoading]);

  const onGetCoverLetter = () => {
    if (application && coverLetterStore[application.id]) {
      setCoverLetterValue(coverLetterStore[application.id]);
    } else {
      generateCoverLetterResponse();
    }
  };

  const generateCoverLetterResponse = async () => {
    setCoverLetterValue('');
    setIsLoading(true);
    const response = await generateCoverLetter(
      (application && application.id) || ''
    );
    setIsLoading(false);
    if (response) {
      setCoverLetterValue(response.suggestions);
      const tempData = JSON.parse(JSON.stringify(coverLetterStore));
      tempData[application!.id] = response.suggestions;
      setCoverLetterStore(tempData);
    }
  };

  const generateReply = async () => {
    setGenerateAnswerError(false);
    setGenerateAnswerSuccess(false);
    setGenerateAnswerDismissed(null);
    setQuestionResponse('');
    setIsLoading(true);
    try {
      const response = await generateQuestionReply(
        (application && application.id) || '',
        questionData
      );
      setIsLoading(false);
      if (response) {
        setGenerateAnswerSuccess(true);
        setGenerateAnswerError(false);
        setQuestionResponse(response.suggestions);
      }
    } catch (error) {
      setIsLoading(false);
      setGenerateAnswerError(true);
      setGenerateAnswerSuccess(false);
    }
  };

  const getReply = () => {
    if (!isLoading && questionData && !questionResponse) {
      generateReply();
    }
  };

  const onGenerateReplyCta = () => {
    if (!isLoading && questionData) {
      generateReply();
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(
      modalType === ApplicationResponseModalEnum.questionResponse
        ? questionResponse
        : coverLetterValue
    );
    notificationInstance.success({
      title: 'Success',
      message: 'Text copied to clipboard.',
    });
  };

  const getInfoLabel = () => {
    if (generateAnswerSuccess) {
      return 'Auto-generated reply';
    } else if (generateAnswerError) {
      return "We couldn't generate right now.";
    } else {
      return 'Add a question from your application form and we will write reply for you';
    }
  };

  const dismissGptResponse = () => {
    setQuestionResponse('');
    setGenerateAnswerSuccess(null);
    setGenerateAnswerError(null);
    setGenerateAnswerDismissed(true);
  };

  const redirectToEditProfile = () => {
    router.push(PAGE_ROUTES.CANDIDATE_EDIT_PROFILE);
  };

  const questionResponseContent = () => {
    return (
      <>
        <div className={styles.questionInputContainer}>
          <TextArea
            className={styles.questionInput}
            placeholder="Paste or write your question here"
            isValid
            isDynamic={true}
            value={questionData}
            onChange={(event) => setQuestionData(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Tab') {
                onGenerateReplyCta();
              }
            }}
            autoFocus
            initialHeight="52px"
          />
          {!screens.sm && questionData && questionData.length < 30 && (
            <p className={styles.tabInfo}>
              <span className={styles.tabKey}>Tab</span>
              <span className={styles.infoText}>Generate reply</span>
            </p>
          )}
        </div>
        <div className={styles.gptSuggestionBox}>
          <div className={styles.labelContainer}>
            <p>Reply</p>
            <Tooltip
              className={styles.magicWandTooltip}
              content="Add question to generate"
              color="dark"
              position="top"
              disabled={!!questionData}
            >
              <p className={styles.generateReply} onClick={onGenerateReplyCta}>
                <Icon
                  iconName="icon_magic-wand"
                  size="small"
                  className={styles.magicWand}
                />
                Generate reply
              </p>
            </Tooltip>
          </div>
          <div className={styles.inputWrapper}>
            <TextArea
              placeholder={isLoading ? animatedPlaceholder : ''}
              isValid
              isDynamic={true}
              value={questionResponse}
              onChange={(event) => {
                if (isLoading) {
                  abortGenerateQuestionReply();
                }
                setQuestionResponse(event.target.value);
              }}
              onClick={() => !generateAnswerDismissed && getReply()}
            />
            <div className={styles.suggestionActions}>
              <div className={styles.infoWrapper}>
                {isLoading ? (
                  <Spinner className={styles.infoWand} />
                ) : (
                  <Icon
                    iconName="icon_magic-wand"
                    size="small"
                    className={cn(
                      styles.infoWand,
                      { [styles.successWand]: generateAnswerSuccess },
                      { [styles.errorWand]: generateAnswerError }
                    )}
                  />
                )}
                <p
                  className={cn(styles.info, {
                    [styles.errorLabel]: generateAnswerError,
                  })}
                >
                  {getInfoLabel()}
                </p>
              </div>
              {(generateAnswerSuccess || generateAnswerError) && (
                <div className={styles.actionCta}>
                  <ButtonV3
                    size="small"
                    color="secondary"
                    startIcon={
                      generateAnswerError ? 'icon_magic-wand' : undefined
                    }
                    onClick={generateReply}
                  >
                    {generateAnswerSuccess ? 'Redo' : 'Try again'}
                  </ButtonV3>
                  <ButtonV3
                    size="small"
                    color="secondary"
                    onClick={dismissGptResponse}
                  >
                    Dismiss
                  </ButtonV3>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const coverLetterContent = () => {
    return (
      <div className={styles.gptSuggestionBox}>
        <div className={styles.inputWrapper}>
          <TextArea
            placeholder={isLoading ? `Generating${dots}` : ''}
            isValid
            isDynamic={true}
            value={coverLetterValue}
            onChange={(event) => setCoverLetterValue(event.target.value)}
          />
          <div className={styles.suggestionActions}>
            <div className={styles.infoWrapper}>
              <Icon
                iconName="icon_magic-wand"
                size="small"
                className={cn(styles.infoWand, styles.successWand)}
              />
              <p className={styles.info}>
                We&apos;ve generated a cover letter based on your CV, job
                details and requirements! Check it out and assess the accuracy.
              </p>
            </div>
            <ButtonV3
              color="secondary"
              size="small"
              onClick={generateCoverLetterResponse}
            >
              Redo
            </ButtonV3>
          </div>
        </div>
      </div>
    );
  };

  const renderCompleteProfileBanner = useMemo(
    () => (
      <div className={styles.completeProfileBanner}>
        <div>
          <p className={styles.rocketIcon}>🚀</p>
          <p className={styles.infoTitle}>
            Complete your profile to get better and more accurate replies.
          </p>
        </div>
        <div>
          <ButtonV3 size="small" onClick={redirectToEditProfile}>
            Complete profile
          </ButtonV3>
          <IconButton
            iconName="close"
            size="small"
            onClick={() => setShowProfileBanner(false)}
          />
        </div>
      </div>
    ),
    []
  );

  return (
    <Modal
      className={styles.modalContainer}
      contentClassName={styles.ResponseModal}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onRequestClose={onClose}
    >
      <div className={styles.ResponseModalHeader}>
        {modalType === ApplicationResponseModalEnum.questionResponse ? (
          <p>Get questions replies</p>
        ) : (
          <p>Get cover letter</p>
        )}
        <IconButton
          className={styles.closeIcon}
          iconName="close"
          size="small"
          onClick={onClose}
        />
      </div>
      <div className={styles.ResponseModalContent}>
        {showProfileBanner && renderCompleteProfileBanner}
        {modalType === ApplicationResponseModalEnum.questionResponse
          ? questionResponseContent()
          : coverLetterContent()}
      </div>
      <div className={styles.ResponseModalAction}>
        <ButtonV3
          size={screens.sm ? 'large' : 'medium'}
          color="primary"
          onClick={copyToClipboard}
          disabled={
            modalType === ApplicationResponseModalEnum.questionResponse
              ? !questionResponse
              : !coverLetterValue
          }
        >
          Copy
        </ButtonV3>
        <ButtonV3
          size={screens.sm ? 'large' : 'medium'}
          color="secondary"
          onClick={onClose}
        >
          Done
        </ButtonV3>
      </div>
    </Modal>
  );
};

export default ResponseModal;
