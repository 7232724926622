import React from 'react';
import cn from 'classnames';
import { create, InstanceProps } from 'react-modal-promise';

import { IconButton } from 'components';
import Modal from 'components/Modal';
import { useAuth } from 'lib/providers/AuthProvider';
import SignIn from '../SignIn';

import styles from './SignInModal.module.scss';

const ModalInstance: React.FC<InstanceProps<Headers>> = ({
  isOpen,
  onResolve,
  onReject,
}) => {
  const { isTemporaryCandidate } = useAuth();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onReject}
      contentClassName={styles.container}
    >
      <IconButton
        size="small"
        iconName="close"
        className={styles.cancelIcon}
        onClick={() => onReject()}
      />
      <SignIn
        onComplete={onResolve}
        isLoginPopup={true}
        className={cn(
          { [styles.content]: !isTemporaryCandidate },
          { [styles.contentSaveForLater]: isTemporaryCandidate }
        )}
      />
    </Modal>
  );
};

export const signInModalInstance = create(ModalInstance);
