import React, { memo } from 'react';
import cn from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useRouter } from 'next/router';

import { ButtonV2, Input, Form as FormComponent } from 'components/form';
import InputV2 from 'components/ComponentV2/InputV2';
import ButtonV3 from 'components/ComponentV2/ButtonV3';
import Logo from 'components/Logo';
import { REGEX } from 'lib/utils/regex';
import { useAuth } from 'lib/providers/AuthProvider';

import { PAGE_ROUTES } from 'lib/page-routes';
import styles from './Form.module.scss';

export interface FormProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  onContinue: (data: Inputs) => Promise<void>;
  isAICandidate?: boolean;
}

interface Inputs {
  email: string;
}

export const Form: React.FC<FormProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    onContinue,
    isAICandidate = false,
  }: FormProps) => {
    const contentClassNames = cn([styles.container], className);
    const { isTemporaryCandidate } = useAuth();
    const router = useRouter();
    const {
      register,
      watch,
      handleSubmit,
      formState: { errors, isSubmitting },
    } = useForm();

    const emailValue = watch('email');

    const onSubmitForm: SubmitHandler<Inputs> = async (data) => {
      await onContinue(data);
    };

    const isPublicPage =
      router.pathname === PAGE_ROUTES.SOCIETY_PUBLIC ||
      router.pathname === PAGE_ROUTES.SHORTLINK_SOCIETY_PUBLIC ||
      router.pathname === PAGE_ROUTES.COMPANY_PUBLIC ||
      router.pathname === PAGE_ROUTES.JOB_PUBLIC ||
      router.pathname === PAGE_ROUTES.EVENT_PUBLIC ||
      router.pathname === PAGE_ROUTES.INTERNSHIP_PUBLIC ||
      router.pathname === PAGE_ROUTES.COMPETITION_PUBLIC;

    const isSignInPage = router.pathname === PAGE_ROUTES.CANDIDATE_SIGN_IN;

    const aiFormSection = (
      <section className={styles.aiSection} data-testid="Signup">
        <Logo
          name="huzzle-new"
          color="primary"
          size="xsmall"
          className={styles.logo}
        />
        <p className={styles.step}>1 / 2</p>
        <div className={styles.section}>
          <p className={styles.emoji}>😇</p>
          <p className={styles.title}>Welcome</p>
          <FormComponent
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitForm)}
            className={styles.sectionForm}
          >
            <InputV2
              type="email"
              id="candidate-signin-email"
              placeholder="Enter your email to continue"
              inputSize="large"
              {...register('email', {
                required: 'Required',
                pattern: {
                  value: REGEX.EMAIL,
                  message: 'Enter a valid e-mail address',
                },
              })}
              isInvalid={errors.email}
              className={styles.input}
            />
            <ButtonV3
              type="submit"
              size="xlarge"
              disabled={!!errors.email || !emailValue}
              isLoading={isSubmitting}
              className={styles.button}
            >
              Continue
            </ButtonV3>
          </FormComponent>
        </div>
      </section>
    );

    return (
      <section className={contentClassNames} style={style} data-testid="Form">
        {isTemporaryCandidate && !isSignInPage ? (
          <div className={styles.saveforlater}>
            {isPublicPage ? (
              <>
                <p className={styles.emoji}>🔖</p>
                <p className={styles.title}>Save for later</p>
                <p className={styles.desc}>
                  Enter your Email and we’ll save this for you so you can apply
                  to it when you’re ready.
                </p>
              </>
            ) : (
              <>
                <p className={styles.emoji}>📥</p>
                <p className={styles.title}>Save your matches</p>
                <p className={styles.desc}>
                  Use your Email to save these matches on Huzzle, and you can
                  apply to them when you’re ready.
                </p>
              </>
            )}
            <FormComponent
              autoComplete="off"
              className={styles.formSaveForLater}
              onSubmit={handleSubmit(onSubmitForm)}
            >
              <Input
                type="email"
                id="candidate-signin-email"
                placeholder="Email"
                {...register('email', {
                  required: 'Required',
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Enter a valid e-mail address',
                  },
                })}
                isValid={!errors.email}
                className={styles.emailSaveForLater}
              />
              <ButtonV2
                size="large"
                type="submit"
                disabled={!!errors.email || !emailValue}
                isLoading={isSubmitting}
                className={styles.buttonSaveForLater}
              >
                {isPublicPage ? 'Save' : 'Save and Continue'}
              </ButtonV2>
            </FormComponent>
            <p className={styles.error}>{errors.email?.message}</p>
          </div>
        ) : isAICandidate ? (
          aiFormSection
        ) : (
          <>
            <p className={styles.welcome}>Welcome.</p>
            <h1 className={styles.question}>What&apos;s your email?</h1>
            <FormComponent
              autoComplete="off"
              className={styles.form}
              onSubmit={handleSubmit(onSubmitForm)}
            >
              <Input
                type="email"
                id="candidate-signin-email"
                placeholder="email"
                {...register('email', {
                  required: 'Required',
                  pattern: {
                    value: REGEX.EMAIL,
                    message: 'Enter a valid e-mail address',
                  },
                })}
                isValid={!errors.email}
                className={styles.email}
              />
              <ButtonV2
                width={220}
                size="large"
                type="submit"
                disabled={!!errors.email || !emailValue}
                isLoading={isSubmitting}
              >
                Continue
              </ButtonV2>
            </FormComponent>
            <p className={styles.error}>{errors.email?.message}</p>
          </>
        )}
      </section>
    );
  }
);

Form.displayName = 'Form';
