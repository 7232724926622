import React, { ReactElement } from 'react';
import type { AppLayoutProps } from 'next/app';
import Head from 'next/head';
import { RecoilRoot } from 'recoil';
import ModalContainer from 'react-modal-promise';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';

import NotificationToaster from 'components/NotificationToaster';
import ErrorBoundary from 'components/ErrorBoundary';
import FacebookPixel from 'components/FacebookPixel';
import GTM from 'components/GTM';
import HeapScript from 'components/HeapScript';
import GlobalScriptAction from 'components/GlobalScriptAction';
import { SaveInAppProvider } from 'lib/contexts/save-in-app-context';
import { MatchingExperienceProvider } from 'lib/contexts/matches-context';
import { CompaniesSearchProvider } from 'lib/contexts/companies-search-context';
import { OpportunitiesSearchProvider } from 'lib/contexts/opportunities-search-context';
import { SocietiesSearchProvider } from 'lib/contexts/societies-search-context';
import { CampaignsProvider } from 'lib/contexts/campaigns-context';
import { ManagerFiltersContextProvider } from 'lib/contexts/manager-filters-context';
import { CommonListProvider } from 'lib/contexts/common-list-context';
import { AuthProvider } from 'lib/providers/AuthProvider';
import nextI18NextConfig from '../../next-i18next.config.js';

import 'styles/globals.css';
import 'styles/theme.scss';

const isProduction =
  process.env.NEXT_PUBLIC_TARGET_ENVIRONMENT === 'production';

function MyApp({ Component, pageProps }: AppLayoutProps): ReactElement {
  const getLayout = Component.getLayout || ((page: React.ReactNode) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {isProduction && (
          <meta
            name="google-site-verification"
            content="sZf2-yH8YTxOJcOfqGl6X-ENdcU_bjTztM9wcHV1UWk"
          />
        )}
      </Head>
      <HeapScript />
      <FacebookPixel />
      <GTM />
      <ErrorBoundary>
        <RecoilRoot>
          <AuthProvider>
            <SessionProvider session={pageProps.session}>
              <CommonListProvider>
                <CampaignsProvider>
                  <CompaniesSearchProvider>
                    <SocietiesSearchProvider>
                      <OpportunitiesSearchProvider>
                        <ManagerFiltersContextProvider>
                          <SaveInAppProvider>
                            <MatchingExperienceProvider>
                              {getLayout(<Component {...pageProps} />)}
                              <NotificationToaster />
                              <GlobalScriptAction />
                              <ModalContainer />
                            </MatchingExperienceProvider>
                          </SaveInAppProvider>
                        </ManagerFiltersContextProvider>
                      </OpportunitiesSearchProvider>
                    </SocietiesSearchProvider>
                  </CompaniesSearchProvider>
                </CampaignsProvider>
              </CommonListProvider>
            </SessionProvider>
          </AuthProvider>
        </RecoilRoot>
      </ErrorBoundary>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
