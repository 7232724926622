import {
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  useEffect,
} from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { useRecoilValue } from 'recoil';
import useSWRInfinite from 'swr/infinite';
import queryString from 'query-string';

import { useNotification } from 'hooks/useNotification';
import {
  TouchpointsResponse,
  TouchpointAttributes,
  TouchpointResponse,
} from 'lib/models/touchpoint';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { API_ROUTES } from 'lib/api-routes';
import { get, patch, remove } from 'lib/utils/http';
import { parseArrayResponse, parsePaginatedResponse } from 'lib/utils/parser';
import { useAuth } from 'lib/providers/AuthProvider';
import { TouchpointState } from 'lib/models/opportunity';

export const eventColumns = [
  {
    id: 1,
    field: 'date',
    label: 'Date',
    width: '5%',
  },
  {
    id: 2,
    field: 'title',
    label: 'Title',
    width: '48%',
  },
  {
    id: 3,
    field: '',
    label: 'Status',
    width: '14%',
  },
  {
    id: 4,
    field: '',
    label: 'Views',
    width: '10%',
  },
  {
    id: 5,
    field: '',
    label: 'Registrations',
    width: '13%',
  },
  {
    id: 6,
    field: '',
    label: '',
    width: '5%',
  },
];

export enum ConfirmationScreen {
  NO_SCREEN = 0,
  DELETE = 1,
  REMOVE_COHOST = 2,
  STOP_COHOST = 3,
  UNPUBLISH = 4,
  ENABLE_EASY_APPLY = 5,
}

export enum TabsType {
  ALL = 0,
  LIVE = 1,
  IN_REVIEW = 2,
  DRAFTS = 3,
  ENDED = 4,
}

export enum SocietyTabsType {
  ALL = 0,
  LIVE = 1,
  DRAFTS = 2,
  INVITED = 3,
  ENDED = 4,
}

export interface UseCampaignsReturnType {
  onDelete: (id: string) => Promise<TouchpointResponse | undefined>;
  mutateCompanyCampaigns: KeyedMutator<TouchpointsResponse>;
  touchpointViewCounterHandler: (
    id: string,
    headers?: Headers
  ) => Promise<void | undefined>;
  eventsList: TouchpointAttributes[];
  liveOpportunityLists: TouchpointAttributes[];

  size: number;
  setSize: (size: number) => void;
  hasMoreRecords: boolean;
  isLoadingMore: boolean | undefined;
  totalCount: number | undefined;
  isAPILoading: boolean;
  mutateEvents: KeyedMutator<TouchpointsResponse[]>;
  selectedEventState: TabsType | SocietyTabsType;
  setSelectedEventState: Dispatch<SetStateAction<TabsType | SocietyTabsType>>;
  onEasyApplyConfirm: (touchpoint_id: string) => Promise<void>;
  onFeaturedToggle: ({
    id,
    featured,
    companySlug,
  }: {
    id: string;
    featured: boolean;
    companySlug: string;
  }) => Promise<TouchpointResponse | undefined>;
  getFeaturedIds: (id: string) => Promise<string[]>;
}

const PER_PAGE_COUNT = 10;

export const useCampaigns = (): UseCampaignsReturnType => {
  const notificationInstance = useNotification();
  const { headers } = useRecoilValue(httpHeadersState);
  const { isManager, isRecruiter } = useAuth();

  const [selectedEventState, setSelectedEventState] = useState<
    TabsType | SocietyTabsType
  >(0);

  const companyJobsPathname = `${API_ROUTES.COMPANY_TOUCHPOINTS}?sort_by=start_date&sort_order=desc&per_page=100&touchpointable_types[]=Job&touchpointable_types[]=Internship&with_details=true`;

  const { data: companyCampaignsResponse, mutate: mutateCompanyCampaigns } =
    useSWR<TouchpointsResponse>(
      isRecruiter ? [companyJobsPathname, headers] : null,
      get,
      {
        revalidateOnFocus: false,
      }
    );
  const companyCampaigns = useMemo(() => {
    return parseArrayResponse<TouchpointAttributes>(companyCampaignsResponse);
  }, [companyCampaignsResponse]);

  const liveOpportunityLists = useMemo(() => {
    return companyCampaigns?.filter(
      (opportunity) => opportunity.state === TouchpointState.PUBLISHED
    );
  }, [companyCampaigns]);

  const getSelectedEventState = useCallback(() => {
    switch (selectedEventState) {
      case TabsType.ALL:
        return '';
      case TabsType.LIVE:
        return ['published'];
      case TabsType.IN_REVIEW:
        return ['ready_for_approval'];
      case TabsType.DRAFTS:
        return ['draft'];
      case TabsType.ENDED:
        return ['expired'];
      default:
    }
  }, [selectedEventState]);

  const getSelectedEventStateSociety = useCallback(() => {
    switch (selectedEventState) {
      case SocietyTabsType.ALL:
        return '';
      case SocietyTabsType.LIVE:
        return ['published'];
      case SocietyTabsType.DRAFTS:
        return ['draft'];
      case SocietyTabsType.ENDED:
        return ['expired'];
      case SocietyTabsType.INVITED:
        return 'invited';
      default:
    }
  }, [selectedEventState]);

  const getJobsKey = (pageIndex: number) => {
    const params = {
      page: pageIndex + 1,
      per_page: PER_PAGE_COUNT,
      state: isRecruiter
        ? getSelectedEventState()
        : getSelectedEventStateSociety(),
    };
    const qs = queryString.stringify(params, {
      arrayFormat: 'bracket',
      skipEmptyString: true,
    });

    let pathname = '';
    if (isRecruiter)
      pathname = `${API_ROUTES.COMPANY_TOUCHPOINTS}?${qs}&with_details=true&sort_by=start_date&sort_order=desc&touchpointable_types[]=Event`;
    else if (isManager)
      pathname = `${API_ROUTES.SOCIETY_TOUCHPOINTS}?${qs}&with_details=true&sort_by=start_date&sort_order=desc`;
    else return;

    return [pathname, headers];
  };

  const {
    data: eventsResponse,
    error: eventsError,
    size,
    setSize,
    mutate: mutateEvents,
  } = useSWRInfinite<TouchpointsResponse>(getJobsKey, get, {
    revalidateOnFocus: false,
    revalidateFirstPage: false,
  });

  useEffect(() => {
    mutateEvents();
  }, []);

  const isAPILoading = !eventsResponse && !eventsError;
  const isLoadingMore =
    isAPILoading ||
    (size > 0 &&
      eventsResponse &&
      typeof eventsResponse[size - 1] === 'undefined');

  const eventsList = useMemo(() => {
    return parsePaginatedResponse<TouchpointAttributes>(eventsResponse);
  }, [isManager, selectedEventState, eventsResponse]);

  const totalCount = eventsResponse
    ? eventsResponse[0]?.meta?.total
    : undefined;

  const hasMoreRecords = useMemo(() => {
    const firstApplicationList = Array.isArray(eventsResponse)
      ? eventsResponse[0]
      : null;
    if (firstApplicationList) {
      return (firstApplicationList?.meta?.total || 0) > size * PER_PAGE_COUNT;
    } else {
      return false;
    }
  }, [eventsResponse, size]);

  const onDelete = async (
    id: string
  ): Promise<TouchpointResponse | undefined> => {
    const path = isManager
      ? `${API_ROUTES.SOCIETY_TOUCHPOINTS}/${id}`
      : `${API_ROUTES.COMPANY_TOUCHPOINTS}/${id}`;
    try {
      const response = await remove<TouchpointResponse>(path, headers);
      mutateEvents();
      notificationInstance.success({
        title: 'Success!',
        message: 'Event removed successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const onFeaturedToggle = async ({
    id,
    featured,
    companySlug,
  }: {
    id: string;
    featured: boolean;
    companySlug: string;
  }): Promise<TouchpointResponse | undefined> => {
    const body = {
      id,
      featured,
    };
    const path = `${API_ROUTES.COMPANY_TOUCHPOINTS}/${id}`;
    try {
      const response = await patch<TouchpointResponse>(path, body, headers);

      // fetch webpage on save changes
      // so that updated page is present
      // when view profile is clicked
      const domain =
        typeof window !== 'undefined'
          ? window.location.origin
          : process.env.NEXT_PUBLIC_SITE_URL;

      const publicPagePathname = `${domain}/companies/${companySlug}`;
      await fetch(publicPagePathname);

      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getFeaturedIds = async (id: string) => {
    const path = `${API_ROUTES.COMPANY_LIST}/${id}/touchpoints?featured=true`;
    try {
      const response = await get<TouchpointsResponse>(path, headers);
      return response.data.map((item) => item.id);
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
    return [];
  };

  const touchpointViewCounterHandler = async (
    id: string,
    headers?: Headers
  ) => {
    try {
      const path = `${API_ROUTES.CAMPAIGNS}/${id}/increment_view_count`;
      const body = {};
      await patch(path, body, headers);
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const onEasyApplyConfirm = async (touchpoint_id: string) => {
    const body = {
      touchpoint: {
        apply_url: null,
      },
    };
    try {
      await patch(
        `${API_ROUTES.COMPANY_TOUCHPOINTS}/${touchpoint_id}`,
        body,
        headers
      );
      notificationInstance.success({
        title: 'Success',
        message: 'Enabled easy apply on huzzle successfully.',
      });
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  return {
    onDelete,
    mutateCompanyCampaigns,
    touchpointViewCounterHandler,
    eventsList,
    liveOpportunityLists,
    size,
    setSize,
    hasMoreRecords,
    isLoadingMore,
    totalCount,
    isAPILoading,
    mutateEvents,
    selectedEventState,
    setSelectedEventState,
    onEasyApplyConfirm,
    onFeaturedToggle,
    getFeaturedIds,
  };
};
