import { AxiosResponse } from 'axios';
import { TCityCountyList } from 'hooks/useCommonList';
import { SelectedFilterOptionLabels } from 'hooks/useFilter';
import { CandidateResponse } from 'lib/models/candidate';
import {
  TalentPoolAttributes,
  TalentPoolsResponse,
} from 'lib/models/talent-pool';
import { UserResponse, UserRole } from 'lib/models/user';

interface ArrayResponse<T> {
  data: Array<{ id: string; attributes: T }>;
  meta?: Record<string, unknown>;
}

interface Response<T> {
  data: { id: string; attributes: T };
}

export function parseResponse<T>(response?: Response<T>): T {
  return response?.data?.attributes as T;
}

export function parseArrayResponse<T>(response?: ArrayResponse<T>): Array<T> {
  const list =
    response?.data && Array.isArray(response?.data) ? response.data : [];
  return list.map((item) => item.attributes) as Array<T>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseErrorResponse(error: any): string {
  return error?.message;
}

export function parsePaginatedResponse<T>(
  response?: Array<ArrayResponse<T>>
): Array<T> {
  if (!response || !Array.isArray(response)) return [];
  const initialValue: Array<T> = [];
  return response.reduce((fullList, list) => {
    return [...fullList, ...parseArrayResponse(list)];
  }, initialValue);
}

export function parseAxiosPaginatedResponse<T>(
  paginatedAxiosResponse?: Array<AxiosResponse<ArrayResponse<T>>>
): Array<T> {
  if (
    !paginatedAxiosResponse ||
    !Array.isArray(paginatedAxiosResponse) ||
    !paginatedAxiosResponse[0]?.data?.data
  )
    return [];

  const initialValue: Array<T> = [];
  return paginatedAxiosResponse.reduce((fullList, axiosResponse) => {
    const { data } = axiosResponse;
    data.data.forEach((item) => {
      fullList = [...fullList, item.attributes];
    });
    return fullList;
  }, initialValue);
}

export function getAxiosPaginatedResponseTotal<T>(
  paginatedAxiosResponse?: Array<AxiosResponse<ArrayResponse<T>>>
): number {
  if (
    !paginatedAxiosResponse ||
    !Array.isArray(paginatedAxiosResponse) ||
    !paginatedAxiosResponse[0]?.data?.meta?.total
  )
    return 0;

  const [axiosResponse] = paginatedAxiosResponse;
  const { data } = axiosResponse;
  return (data.meta?.total as number) || 0;
}

export function parseTalentPoolPaginatedResponse(
  response?: Array<TalentPoolsResponse>
): Array<TalentPoolAttributes> {
  if (!response || !Array.isArray(response)) return [];

  return response.reduce(
    (accumulator: Array<TalentPoolAttributes>, currentResponse) => {
      const data = currentResponse.data;
      const attributesList = data.map((d) => d.attributes);
      return [...accumulator, ...attributesList];
    },
    []
  );
}

type FilterField = {
  id: string;
  name: string;
};

type FilterFieldArrayResponse = ArrayResponse<FilterField>;

export function parseArrayResponseLabels(
  response?: FilterFieldArrayResponse
): string {
  const list = parseArrayResponse(response);
  return list.map((item) => item.name).join(', ');
}

type SearchPageFilterOptions = {
  area_of_responsibility_ids?: ArrayResponse<FilterField>;
  city_ids?: ArrayResponse<FilterField>;
  event_category_ids?: ArrayResponse<FilterField>;
  industry_ids?: ArrayResponse<FilterField>;
  topic_ids?: ArrayResponse<FilterField>;
  working_model?: string;
  work_type?: string;
  kinds?: Array<string>;
  country_ids?: ArrayResponse<FilterField>;
  query_params: {
    touchpointable_types: Array<string>;
    country_ids: Array<string>;
  };
};

export function parseSearchPageFilter(
  data: SearchPageFilterOptions,
  filterOptions?: SelectedFilterOptionLabels,
  cityCountryList?: TCityCountyList
): SelectedFilterOptionLabels {
  const {
    area_of_responsibility_ids,
    topic_ids,
    event_category_ids,
    industry_ids,
    city_ids,
    kinds = (filterOptions?.kinds || []) as Array<string>,
    query_params,
  } = data;
  const countryIds = query_params?.country_ids;
  let cityLabels = '';

  if (countryIds) {
    const [countryId] = countryIds;
    const city_labels = cityCountryList?.reduce((cityIds, city) => {
      if (city.country_id === countryId) {
        cityIds.push(city.label);
      }
      return cityIds;
    }, [] as Array<string>);
    cityLabels = city_labels?.join(', ') || '';
  } else {
    cityLabels = parseArrayResponseLabels(city_ids);
  }

  const filters = {
    area_of_responsibility_ids: parseArrayResponseLabels(
      area_of_responsibility_ids
    ),
    topic_ids: parseArrayResponseLabels(topic_ids),
    event_category_ids: parseArrayResponseLabels(event_category_ids),
    industry_ids: parseArrayResponseLabels(industry_ids),
    city_ids: cityLabels,
    kinds: kinds.join(', ') || '',
  };
  return filters;
}

export function parseUserResponse(
  userResponse: UserResponse | CandidateResponse
) {
  if (!userResponse?.data) return null;

  const userType = userResponse.data.type.toLowerCase();

  switch (userType) {
    case UserRole.CANDIDATE: {
      const candidateResponse = parseResponse(
        userResponse as CandidateResponse
      );
      const { user: userAttributesResponse } = candidateResponse;
      const user = parseResponse(userAttributesResponse);
      return user;
    }
    case UserRole.MANAGER:
    case UserRole.RECRUITER: {
      const toolResponse = parseResponse(userResponse as UserResponse);
      const {
        user: userAttributesResponse,
        company: companyAttributesResponse,
        student_society: studentSocietyResponse,
        ...rest
      } = toolResponse;
      const companyAttributes = parseResponse(companyAttributesResponse);
      const company = companyAttributes;
      const studentStudentAttributes = parseResponse(studentSocietyResponse);
      const student_society = studentStudentAttributes;
      const user = parseResponse(userAttributesResponse);
      return user ? { ...rest, ...user, company, student_society } : null;
    }
  }
}
