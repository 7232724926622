import React, { ComponentType, useEffect } from 'react';
import { useRouter } from 'next/router';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';

export function withAuthCandidate<T>(
  Component: ComponentType<T>
): ComponentType<T> {
  return (hocProps: T) => {
    const router = useRouter();
    const { isCandidate, isAuthLoading, isLoggedIn, isTemporaryCandidate } =
      useAuth();

    useEffect(() => {
      if (isAuthLoading) return;

      if (!isLoggedIn || (isLoggedIn && !isCandidate) || isTemporaryCandidate) {
        if (
          router.pathname === PAGE_ROUTES.AI_ONBOARDING ||
          router.pathname === PAGE_ROUTES.BUILD_PROFILE ||
          router.pathname === PAGE_ROUTES.CANDIDATE_REVIEW_APPLICATIONS
        ) {
          router.replace(PAGE_ROUTES.AI_SIGNUP);
        } else {
          router.replace(PAGE_ROUTES.CANDIDATE_SIGN_IN);
        }
      }
    }, [isAuthLoading, isLoggedIn, isCandidate, router]);

    if (isCandidate) {
      //@ts-ignore
      return <Component {...hocProps} />;
    } else {
      return null;
    }
  };
}
