import { CompanyAttributes, CompanyResponse } from './company';
import {
  StudentSocietyAttributes,
  StudentSocietyResponse,
} from './student-society';

export interface ToolUserAttributes {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  authentication_token: string;
  created_at: string;
  userable_type: UserRole.MANAGER | UserRole.RECRUITER;
  flow_state?: string | null;
  company?: CompanyAttributes;
  avatar_url: string;
  student_society?: StudentSocietyAttributes;
  referral_code: string;
  sponsorship_student_society_id: string;
  sponsorship_message: string;
  sponsorship_plan_id: string;
}

export interface ToolUser {
  id: string;
  type: 'user';
  attributes: ToolUserAttributes;
}

export interface UserRoleResponse {
  data: ToolUser;
}

export interface UserAttributes {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  authentication_token: string;
  student_society?: StudentSocietyResponse;
  company?: CompanyResponse;
  created_at: string;
  user: UserRoleResponse;
  flow_state?: string | null;
  avatar_url: string;
  parsedUser?: ToolUserAttributes;
}

export enum UserRole {
  RECRUITER = 'recruiter',
  MANAGER = 'manager',
  CANDIDATE = 'candidate',
}

export interface User {
  id: string;
  type: UserRole;
  attributes: UserAttributes;
}

export interface UserResponse {
  data: User;
}
