import React, { memo } from 'react';
import cn from 'classnames';

import { Image } from 'components';
import { REGEX } from 'lib/utils/regex';
import styles from './ProfilePics.module.scss';
import { getRandomInt, getRandomProfiles, randomBGColor } from 'utils/format';
import { isSafari } from 'utils/dom';

export interface ProfilePicsProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  maxPicsToShow: number;
  urls: Array<string>;
  displayCount?: boolean;
}

export const ProfilePics: React.FC<ProfilePicsProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    maxPicsToShow,
    urls,
    displayCount,
  }: ProfilePicsProps) => {
    const contentClassNames = cn(
      {
        [styles.ProfilePics]: true,
      },
      className
    );

    const getEmoji = (emoji: string, index: number) => {
      const randomNumber = getRandomInt(0, 10, true);
      const color = randomBGColor[randomNumber as number];
      return (
        <div key={index} className={styles.randomprofileBorder}>
          <p
            style={{ backgroundColor: color }}
            className={cn(styles.randomprofile, { [styles.safari]: isSafari })}
          >
            {emoji}
          </p>
        </div>
      );
    };

    const getProfilePic = (url: string, index: number) => {
      return (
        <div key={index} className={styles.randomprofileBorder}>
          <Image
            width={28}
            height={28}
            src={url}
            source={undefined}
            className={styles.img}
          />
        </div>
      );
    };
    const profiles = getRandomProfiles(maxPicsToShow, urls || []);

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="ProfilePics"
      >
        {profiles.map((item, index) => {
          if (REGEX.URL_CHECK.test(item)) {
            return getProfilePic(item, index);
          } else {
            return getEmoji(item, index);
          }
        })}
        {displayCount && urls.length > maxPicsToShow && (
          <div className={styles.randomprofileBorder}>
            <p
              className={cn(styles.randomprofile, styles.count, {
                [styles.safari]: isSafari,
              })}
            >
              +6
            </p>
          </div>
        )}
      </div>
    );
  }
);

ProfilePics.displayName = 'ProfilePics';
