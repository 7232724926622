import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { saveAs } from 'file-saver';

import { useNotification } from 'hooks/useNotification';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { API_ROUTES } from 'lib/api-routes';
import { post } from 'lib/utils/http';
import { CandidateApplicationAIResponse } from 'lib/models/candidate-applications';
import { parseErrorResponse } from 'lib/utils/parser';

export interface ReturnType {
  generateCoverLetter: (
    touchPointId: string
  ) => Promise<CandidateApplicationAIResponse | undefined>;
  generateQuestionReply: (
    touchPointId: string,
    question: string
  ) => Promise<CandidateApplicationAIResponse | undefined>;
  downloadCv: (info: {
    first_name: string;
    last_name: string;
  }) => Promise<void>;
  abortGenerateQuestionReply: () => void;
}

export const useAIGeneratedResponse = (): ReturnType => {
  const { headers, plainHeaders } = useRecoilValue(httpHeadersState);
  const notificationInstance = useNotification();
  const { NEXT_PUBLIC_API_HOST_URL } = process.env;

  const [abortController, setAbortController] = useState<
    AbortController | undefined
  >();

  const generateCoverLetter = async (touchPointId: string) => {
    const path = API_ROUTES.CANDIDATE_COVER_LETTER.replace(
      '[id]',
      touchPointId
    );
    try {
      const response = await post<CandidateApplicationAIResponse>(
        path,
        {},
        headers
      );
      return response;
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      notificationInstance.handleExceptionError(error as any);
    }
  };

  const generateQuestionReply = async (
    touchPointId: string,
    question: string
  ) => {
    const controller = new AbortController();
    setAbortController(controller);
    const url = API_ROUTES.CANDIDATE_QUESTION_REPLY;
    const path = touchPointId
      ? url.replace('[id]', touchPointId)
      : url.replace('/touchpoints/[id]', '');
    const body = {
      question,
    };
    const args = {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
      signal: controller.signal,
    };
    const request = new Request(`${NEXT_PUBLIC_API_HOST_URL}${path}`, args);
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await fetch(request);
      return response.json();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const err = parseErrorResponse(error);
      if (err !== 'The user aborted a request.') {
        throw new Error(error.alert || error.error);
      }
    }
  };

  const downloadCv = async (info: {
    first_name: string;
    last_name: string;
  }) => {
    const path = API_ROUTES.CANDIDATE_DOWNLOAD_CV;
    plainHeaders.set('Content-Type', 'application/pdf');
    plainHeaders.set('Accept', 'application/pdf');
    plainHeaders.set('Response-Type', 'application/pdf');
    const args = {
      method: 'POST',
      headers: plainHeaders,
    };

    const request = new Request(`${NEXT_PUBLIC_API_HOST_URL}${path}`, args);
    try {
      const response = await fetch(request);
      const blob = await response.blob();
      const fileURL = window.URL.createObjectURL(blob);
      const fileName = `${info.first_name}-${info.last_name}-CV.pdf`;
      await saveAs(fileURL, fileName);
    } catch (error) {
      notificationInstance.handleExceptionError(error as Error);
    }
  };

  const abortGenerateQuestionReply = () => {
    if (abortController) {
      abortController.abort();
      setAbortController(undefined);
    }
  };

  return {
    generateCoverLetter,
    generateQuestionReply,
    downloadCv,
    abortGenerateQuestionReply,
  };
};
