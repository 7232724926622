export const REGEX = {
  URL: /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  NUMBER: /[1-9]/,
  POSITIVE_NUMBER: /^\+?([1-9]\d*)$/,
  URL_CHECK:
    /(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  SALARY: /^[0-9,]*([0-9]+[0-9,]*)*$/,
  PHONE_NUMBER: /^[+0-9]+$/,
};
