import useSWR, { KeyedMutator } from 'swr';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { get, patch, post, put } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import {
  MatchAttributes,
  MatchesResponse,
  MatchUpdateResponse,
} from 'lib/models/matches';
import { useAuth } from 'lib/providers/AuthProvider';
import {
  CandidateAttributes,
  CandidateDashboardAttributes,
  CandidateResponse,
} from 'lib/models/candidate';
import { PAGE_ROUTES } from 'lib/page-routes';
import { StudentSocietyResponse } from 'lib/models/student-society';
import { CompanyResponse } from 'lib/models/company';
import { useNotification } from './useNotification';
import { FLOW } from './useApp';

export interface UseMatchingExperienceReturnType {
  isMatchesLoading: boolean;
  matchList: Array<MatchAttributes>;
  setMatchList: Dispatch<SetStateAction<Array<MatchAttributes>>>;
  mutateMatchesList: () => void;
  match: MatchAttributes | null;
  positionOfMatch: number;
  total: number | undefined;
  restMatches: Array<MatchAttributes>;
  generateNewMatches: () => Promise<unknown>;
  unvisited_count?: number;
  unvisited_events_count: number;
  unvisited_internships_count: number;
  unvisited_jobs_count: number;
  internshipMatches: Array<MatchAttributes>;
  jobMatches: Array<MatchAttributes>;
  eventMatches: Array<MatchAttributes>;
  update_matches: ({ id, viewed_at }: { id: string; viewed_at: Date }) => void;
  isMatchesMutating: boolean;
  setMatchesMutating: Dispatch<SetStateAction<boolean>>;
  generateMatchesFromScratch: () => Promise<unknown>;
  getPreviousMatches: () => Promise<MatchesResponse | unknown>;
  candidateLastUpdatedStep: FLOW | null;
  updateCandidateLastStep: (
    flow_state: FLOW | null
  ) => Promise<CandidateAttributes | undefined>;
  isCandidateUpdating: boolean;
  setCandidateUpdating: Dispatch<SetStateAction<boolean>>;
  isNewMatchesLoading: boolean;
  setIsNewMatchesLoading: Dispatch<SetStateAction<boolean>>;
  candidateSelectedOpportunities: Array<string>;
  onboarding_completed: boolean;
  event_state_empty: boolean;
  internship_state_empty: boolean;
  job_state_empty: boolean;
  updateCandidatePreferredMatches: ({
    headers,
    match_ids,
  }: {
    headers: Headers;
    match_ids: Array<string>;
  }) => Promise<boolean | undefined>;
  mutateMatches: KeyedMutator<MatchesResponse>;
  storePostedByData?: (data: {
    [key: string]: StudentSocietyResponse | CompanyResponse;
  }) => void;
  getPostedByData?: () => {
    [key: string]: StudentSocietyResponse | CompanyResponse;
  };
  candidateDashboard: CandidateDashboardAttributes | undefined;
  mutateCandidateDashboard: KeyedMutator<CandidateDashboardAttributes>;
  candidate: CandidateAttributes | undefined;
  mutateCandidate: KeyedMutator<CandidateResponse>;
  show_cv_builder_banner: boolean;
}

export const useMatchingExperience = (): UseMatchingExperienceReturnType => {
  const notificationInstance = useNotification();
  const { headers } = useRecoilValue(httpHeadersState);
  const { isCandidate } = useAuth();
  const router = useRouter();
  const { slug } = router.query;

  const [matchList, setMatchList] = useState<Array<MatchAttributes>>([]);
  const [isCandidateUpdating, setCandidateUpdating] = useState(false);
  const [isMatchesMutating, setMatchesMutating] = useState(false);
  const [isNewMatchesLoading, setIsNewMatchesLoading] = useState(false);
  const [postedByData, setPostedByData] = useState<{
    [key: string]: StudentSocietyResponse | CompanyResponse;
  }>({});

  const { data: candidateResponse, mutate: mutateCandidate } =
    useSWR<CandidateResponse>(
      isCandidate ? [API_ROUTES.CANDIDATE, headers] : null,
      get,
      {
        revalidateOnFocus: false,
      }
    );

  const candidate = useMemo(
    () => parseResponse(candidateResponse),
    [candidateResponse]
  );
  const {
    flow_state,
    opportunity_types: candidateSelectedOpportunities,
    onboarding_completed,
    show_cv_builder_banner,
  } = candidate || {};
  const candidateLastUpdatedStep = useMemo(() => flow_state, [flow_state]);

  const pathname = API_ROUTES.MATCHES;

  const {
    data: matchesResponse,
    error: matchesError,
    mutate: mutateMatches,
  } = useSWR<MatchesResponse>(
    isCandidate && !isNewMatchesLoading ? [pathname, headers] : null,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const generateMatchesFromScratch = async () => {
    try {
      const response = await get<MatchesResponse>(
        `${API_ROUTES.MATCHES}&generate_from_scratch=true`,
        headers
      );
      if (response) {
        mutateMatches(response);
        setMatchesMutating(false);
      }
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const generateNewMatches = async () => {
    try {
      const response = await get<MatchesResponse>(
        `${API_ROUTES.MATCHES}&generate_now=true`,
        headers
      );
      if (response) {
        mutateMatches(response);
        mutateCandidate();
        setIsNewMatchesLoading(false);
      }
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  useEffect(() => {
    if (matchesResponse) {
      setMatchesMutating(false);
    }
  }, [matchesResponse]);

  const isMatchesLoading = !matchesResponse && !matchesError;

  const touchpoints = useMemo(
    () => matchesResponse?.touchpoints,
    [matchesResponse]
  );
  const internshipMatches = useMemo(() => {
    const internships = touchpoints?.internships;
    return parseArrayResponse<MatchAttributes>(internships);
  }, [touchpoints]);
  const jobMatches = useMemo(() => {
    const jobs = touchpoints?.jobs;
    return parseArrayResponse<MatchAttributes>(jobs);
  }, [touchpoints]);
  const eventMatches = useMemo(() => {
    const events = touchpoints?.events;
    return parseArrayResponse<MatchAttributes>(events);
  }, [touchpoints]);

  useEffect(() => {
    const matches = [...jobMatches, ...internshipMatches, ...eventMatches];
    setMatchList(matches);
  }, [jobMatches, internshipMatches, eventMatches]);

  const total = matchesResponse === undefined ? undefined : matchList?.length;

  const meta = useMemo(() => matchesResponse?.meta, [matchesResponse]);
  const unvisited_count = meta?.unvisited_count;
  const unvisited_events_count = meta ? meta.unvisited_events_count : 0;
  const unvisited_internships_count = meta
    ? meta.unvisited_internships_count
    : 0;
  const unvisited_jobs_count = meta ? meta.unvisited_jobs_count : 0;

  const event_state_empty = meta
    ? meta.candidate_matches_state.event_state_empty
    : false;
  const internship_state_empty = meta
    ? meta.candidate_matches_state.internship_state_empty
    : false;
  const job_state_empty = meta
    ? meta.candidate_matches_state.job_state_empty
    : false;

  const match =
    matchList.find((match) => match.matchable_slug === slug) || null;

  const positionOfMatch = useMemo(
    () => matchList.findIndex((match) => match.matchable_slug === slug),
    [matchList, slug]
  );

  const restMatches = useMemo(
    () => matchList.filter((match) => match.matchable_slug !== slug),
    [matchList, slug]
  );

  const mutateMatchesList = () => {
    setMatchesMutating(true);
    if (router.pathname === PAGE_ROUTES.MATCHING_EXPERIENCE_PAGE)
      mutateMatches();
    else mutateMatches(undefined);
  };

  const update_matches = async ({
    id,
    viewed_at,
  }: {
    id: string;
    viewed_at: Date;
  }) => {
    const pathname = `${API_ROUTES.UPDATE_MATCHES}/${id}`;
    const body = {
      match: {
        viewed_at,
      },
    };
    try {
      await patch<MatchUpdateResponse>(pathname, body, headers);
      setMatchesMutating(true);
      await mutateMatches();
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getPreviousMatches = async () => {
    setMatchesMutating(true);
    try {
      const response = await get<MatchesResponse>(
        API_ROUTES.PREVIOUS_MATCHES,
        headers
      );
      if (response) {
        mutateMatches(response);
        setMatchesMutating(false);
        return response;
      }
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidateLastStep = async (flow_state: FLOW | null) => {
    setCandidateUpdating(true);
    const body = {
      candidate: { flow_state },
    };
    try {
      const response = await put<CandidateResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      if (response) {
        mutateCandidate(response);
        setCandidateUpdating(false);
        return parseResponse(response);
      }
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidatePreferredMatches = async ({
    headers,
    match_ids,
  }: {
    headers: Headers;
    match_ids: Array<string>;
  }) => {
    try {
      const body = {
        match_ids,
      };
      const response = await post<CandidateResponse>(
        API_ROUTES.MATCH_PREFERENCES,
        body,
        headers
      );
      if (response) {
        return true;
      }
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const storePostedByData = (data: {
    [key: string]: StudentSocietyResponse | CompanyResponse;
  }) => {
    setPostedByData(data);
  };

  const getPostedByData = () => {
    return postedByData;
  };

  const { data: candidateDashboard, mutate: mutateCandidateDashboard } =
    useSWR<CandidateDashboardAttributes>(
      isCandidate ? [API_ROUTES.CANDIDATE_DASHBOARD, headers] : null,
      get,
      {
        revalidateOnFocus: false,
      }
    );

  return {
    isMatchesLoading,
    matchList,
    setMatchList,
    mutateMatchesList,
    match,
    positionOfMatch,
    total,
    restMatches,
    unvisited_count,
    unvisited_events_count,
    unvisited_internships_count,
    unvisited_jobs_count,
    internshipMatches,
    jobMatches,
    eventMatches,
    update_matches,
    isMatchesMutating,
    setMatchesMutating,
    generateNewMatches,
    generateMatchesFromScratch,
    getPreviousMatches,
    candidateLastUpdatedStep,
    updateCandidateLastStep,
    isCandidateUpdating,
    setCandidateUpdating,
    isNewMatchesLoading,
    setIsNewMatchesLoading,
    candidateSelectedOpportunities,
    onboarding_completed,
    event_state_empty,
    internship_state_empty,
    job_state_empty,
    updateCandidatePreferredMatches,
    mutateMatches,
    storePostedByData,
    getPostedByData,
    candidateDashboard,
    mutateCandidateDashboard,
    candidate,
    mutateCandidate,
    show_cv_builder_banner,
  };
};
