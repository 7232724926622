import React, { memo } from 'react';
import cn from 'classnames';

import { Icon, IconName } from 'components';
import { Button } from 'components/form';
import { Row } from 'components/layout';
import { NotificationTypeEnum } from 'lib/models/notification';

import styles from './Toaster.module.scss';

export interface ToasterProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** type of the toaster */
  type: NotificationTypeEnum;
  /** title */
  title?: string;
  /** message */
  message: string;
  /** on close handler */
  onClose: () => void;
}

export const Toaster: React.FC<ToasterProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    type,
    title,
    message,
    onClose,
  }: ToasterProps) => {
    const contentClassNames = cn(
      styles.container,
      {
        [styles.success]: type === NotificationTypeEnum.Success,
        [styles.info]: type === NotificationTypeEnum.Info,
        [styles.warning]: type === NotificationTypeEnum.Warning,
        [styles.error]: type === NotificationTypeEnum.Error,
      },
      className
    );

    return (
      <Row
        align={message ? 'start' : 'center'}
        className={contentClassNames}
        style={style}
        data-testid="Toaster"
        wrap={false}
      >
        <Icon
          iconName={type as IconName}
          size="large"
          className={styles.icon}
        />
        <div>
          {title && <p className={styles.title}>{title}</p>}
          {message ? (
            <p
              dangerouslySetInnerHTML={{
                __html: message,
              }}
              className={styles.message}
            />
          ) : null}
        </div>
        <Button
          color="secondary"
          borderless
          size="small"
          className={styles.cancelButton}
          onClick={onClose}
        >
          Close
        </Button>
      </Row>
    );
  }
);

Toaster.displayName = 'Toaster';
