import React, { useEffect, createContext, useCallback, useState } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';

const DynamicPortal = dynamic(() => import('components/Portal'), {
  ssr: false,
});

import styles from './Modal.module.scss';

interface ModalContextType {
  onRequestClose: null | (() => void);
}

const ModalContext = createContext({
  onRequestClose: null,
} as ModalContextType);

export interface ModalProps {
  /** Custom class name */
  className?: string;
  /** Custom content class name */
  contentClassName?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** Custom content style */
  contentStyle?: Record<string, unknown>;
  /** children to be render inside modal */
  children: React.ReactNode;
  /** is modal open */
  isOpen: boolean;
  /** modal close handler */
  onRequestClose: () => void;
  /** Is modal full screen? */
  isFullScreen?: boolean;
  /** Close on overlay click? */
  closeOnOverlayClick?: boolean;
  closeOnESCPress?: boolean;
  modalId?: string;
}

export const Modal: React.FC<ModalProps> = ({
  className = '', // custom class name
  contentClassName = '',
  style, // custom style
  contentStyle,
  isFullScreen,
  isOpen,
  onRequestClose,
  closeOnOverlayClick = true,
  closeOnESCPress = false,
  children,
  modalId,
}: ModalProps) => {
  const contentClassNames = cn(
    {
      [styles.Modal]: true,
      [styles.ModalFullScreen]: isFullScreen,
    },
    className
  );
  const modalContentClassNames = cn(
    {
      [styles.ModalContent]: true,
    },
    contentClassName
  );
  const [isMouseDownOutside, setIsMouseDownOutside] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    }

    return () => document.body.classList.remove('modal-open');
  }, [isOpen]);

  const closeModal = useCallback(
    (event) => {
      event.stopPropagation();
      const mouseUpInsideModal = event.currentTarget.firstElementChild.contains(
        event.target
      );
      if (isMouseDownOutside && !mouseUpInsideModal) {
        onRequestClose();
      }
      setIsMouseDownOutside(false);
    },
    [isMouseDownOutside]
  );

  const onOverlayClick = useCallback(
    (event) => {
      event.stopPropagation();
      const clickedInsideModal = event.currentTarget.firstElementChild.contains(
        event.target
      );
      if (closeOnOverlayClick && !clickedInsideModal)
        setIsMouseDownOutside(true);
    },
    [closeOnOverlayClick]
  );

  const onESCPress = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.key !== 'Escape') return;

      if (closeOnESCPress) onRequestClose();
    },
    [closeOnESCPress]
  );

  useEffect(() => {
    if (!isOpen) return;

    document.addEventListener('keydown', onESCPress, false);

    return () => {
      document.removeEventListener('keydown', onESCPress, false);
    };
  }, [isOpen, onESCPress]);

  return (
    <DynamicPortal>
      {isOpen ? (
        <div
          className={contentClassNames}
          style={style}
          data-testid="Modal"
          onMouseDown={onOverlayClick}
          onMouseUp={closeModal}
        >
          <div
            role="dialog"
            aria-modal="true"
            data-testid="ModalContent"
            className={modalContentClassNames}
            style={contentStyle}
            onClick={(event) => event.stopPropagation()}
            tabIndex={-1}
            id={modalId}
          >
            <ModalContext.Provider value={{ onRequestClose }}>
              {children}
            </ModalContext.Provider>
          </div>
        </div>
      ) : null}
    </DynamicPortal>
  );
};
