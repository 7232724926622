import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import { UseAuthReturnType, useAuthInternalHook } from 'hooks/useAuthInternal';

const initialContext: UseAuthReturnType = {
  isInitialAuthLoading: false,
  isAuthLoading: false,
  setIsAuthLoading: noop,
  user: null,
  setUser: noop,
  isLoggedIn: false,
  isManager: false,
  isRecruiter: false,
  isCandidate: false,
  isTemporaryCandidate: false,
  userName: '',
  isNewCandidate: false,
  setIsNewCandidate: noop,
  userSociety: null,
  userCompany: null,
  candidate: null,
  candidateUser: null,
  toolUser: null,
  basicParticipant: null,
};

const AuthContext = createContext<UseAuthReturnType>(initialContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const value = useAuthInternalHook();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
