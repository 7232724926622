import React, { memo, useEffect, useState } from 'react';
import cn from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';

import { Form } from 'components/form';
import { Col, Row } from 'components/layout';
import { InputV2 } from 'components/ComponentV2';
import { Icon } from 'components/Icon/Icon.component';
import { useCandidate } from 'hooks/useCandidate';
import {
  parseErrorResponse,
  parseResponse,
  parseUserResponse,
} from 'lib/utils/parser';
import { userSecretState } from 'lib/atoms/userSecretAtom';
import { useAuth } from 'lib/providers/AuthProvider';
import { CandidateResponse } from 'lib/models/candidate';
import { API_ROUTES } from 'lib/api-routes';
import { post } from 'lib/utils/http';
import styles from './VerifyOTP.module.scss';
import { OTP_RESEND_TIMER } from 'utils/format';

interface Inputs {
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
}

export interface VerifyOTPProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  email: string;
  /** callback fn to be called when sign in is successful */
  onComplete: (headers: Headers) => void;
  isLoginPopup: boolean;
  isJobPage?: boolean;
}

export const VerifyOTP: React.FC<VerifyOTPProps> = memo(
  ({
    className = '', // custom class name
    email,
    onComplete,
    isLoginPopup,
    isJobPage,
  }: VerifyOTPProps) => {
    const contentClassNames = cn([styles.container], className);
    const [, setUserSecret] = useRecoilState(userSecretState);
    const { setUser, setIsAuthLoading } = useAuth();
    const [isInvalidOTP, setIsInvalidOTP] = useState(false);
    const [showError, setShowError] = useState<string>('');
    const { getHeaders, verifyCandidateOTP, isCandidate } = useCandidate();
    const {
      register,
      watch,
      handleSubmit,
      reset: resetForm,
      formState: { isSubmitting },
      setFocus,
      setValue,
    } = useForm();

    const otp1 = watch('otp1');
    const otp2 = watch('otp2');
    const otp3 = watch('otp3');
    const otp4 = watch('otp4');

    useEffect(() => {
      setFocus('otp1');
    }, [setFocus]);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
      const otp = data.otp1 + data.otp2 + data.otp3 + data.otp4;
      try {
        setIsAuthLoading(true);
        const response = await verifyCandidateOTP({ email, otp });
        if (response && isCandidate(response)) {
          const candidateResponse = parseResponse(
            response as CandidateResponse
          );
          if (candidateResponse) {
            const candidateResponse = parseResponse(
              response as CandidateResponse
            );
            const user = parseUserResponse(response as CandidateResponse);
            if (user) {
              const { authentication_token, email } = user;
              const headers = getHeaders(candidateResponse);
              if (onComplete) {
                setUserSecret({ token: authentication_token, email });
                setUser(user);
                setIsAuthLoading(false);
                onComplete(headers);
              }
            }
          }
        }
      } catch (error) {
        setIsInvalidOTP(true);
        const message = parseErrorResponse(error);
        setShowError(message);
        setIsAuthLoading(false);
      }
    };

    useEffect(() => {
      const subscription = watch((value) => {
        const { otp1, otp2, otp3, otp4 } = value;
        const otp: string = otp1 + otp2 + otp3 + otp4 || '';
        const optNumber = otp?.replace(/[^0-9]/g, '');
        if (optNumber.length === 4 && !isSubmitting) {
          handleSubmit(onSubmit)();
        }
      });

      return () => subscription.unsubscribe();
    }, [watch]);

    const [seconds, setSeconds] = React.useState(OTP_RESEND_TIMER);
    const [reset, setReset] = React.useState(false);

    useEffect(() => {
      if (reset) {
        setSeconds(OTP_RESEND_TIMER);
        setReset(false);
      } else {
        const interval = setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds === 0) {
              clearInterval(interval);
            }
            return prevSeconds > 0 ? prevSeconds - 1 : 0;
          });
        }, 1000);

        return () => clearInterval(interval);
      }
    }, [reset]);

    const handleReset = async () => {
      setReset(true);
      const data = {
        user: { email },
      };
      await post(API_ROUTES.CANDIDATE_CREATE, data);
      resetForm();
      setIsInvalidOTP(false);
      setShowError('');
      setFocus('otp1');
    };

    const fillAllOtp = (value: string) => {
      const optNumber = value?.replace(/[^0-9]/g, '');
      setValue('otp1', optNumber.charAt(0) || otp1);
      setValue('otp2', optNumber.charAt(1) || otp2);
      setValue('otp3', optNumber.charAt(2) || otp3);
      setValue('otp4', optNumber.charAt(3) || otp4);
    };

    return (
      <div className={contentClassNames}>
        <p
          className={cn(styles.title, {
            [styles.hideMobile]: !isLoginPopup,
          })}
        >
          {isJobPage ? 'Verify your email to apply' : 'Verify your email'}
        </p>
        <p
          className={cn(styles.desc, {
            [styles.hideMobile]: !isLoginPopup,
          })}
        >
          We’ve sent a confirmation code to your email address. Type it below to
          continue.
        </p>
        <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <Row className={styles.mb24}>
            <Col span={24} className={styles.confirmationCodeWrapper}>
              <InputV2
                inputClassName={styles.otpinput}
                type="number"
                {...register('otp1')}
                disabled={isSubmitting}
                autoComplete="off"
                onChange={(event) => {
                  const numberValue = event.target.value.replace(
                    /[^0-9]/g,
                    ''
                  )[0];
                  setValue('otp1', numberValue);
                  numberValue && setFocus('otp2');
                }}
                onPaste={(event) => {
                  event.preventDefault();
                  const value = event.clipboardData.getData('Text');
                  fillAllOtp(value);
                  return false;
                }}
                isInvalid={isInvalidOTP}
              />
              <InputV2
                inputClassName={styles.otpinput}
                type="number"
                {...register('otp2')}
                disabled={isSubmitting}
                autoComplete="off"
                onChange={(event) => {
                  const numberValue = event.target.value.replace(
                    /[^0-9]/g,
                    ''
                  )[0];
                  setValue('otp2', numberValue);
                  numberValue && setFocus('otp3');
                }}
                onPaste={(event) => {
                  event.preventDefault();
                  const value = event.clipboardData.getData('Text');
                  fillAllOtp(value);
                  return false;
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && !otp2) {
                    setFocus('otp1');
                  }
                }}
                isInvalid={isInvalidOTP}
              />
              <InputV2
                inputClassName={styles.otpinput}
                type="number"
                {...register('otp3')}
                disabled={isSubmitting}
                autoComplete="off"
                onChange={(event) => {
                  const numberValue = event.target.value.replace(
                    /[^0-9]/g,
                    ''
                  )[0];
                  setValue('otp3', numberValue);
                  numberValue && setFocus('otp4');
                }}
                onPaste={(event) => {
                  event.preventDefault();
                  const value = event.clipboardData.getData('Text');
                  fillAllOtp(value);
                  return false;
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && !otp3) {
                    setFocus('otp2');
                  }
                }}
                isInvalid={isInvalidOTP}
              />
              <InputV2
                inputClassName={styles.otpinput}
                type="number"
                {...register('otp4')}
                disabled={isSubmitting}
                autoComplete="off"
                onChange={(event) => {
                  const numberValue = event.target.value.replace(
                    /[^0-9]/g,
                    ''
                  )[0];
                  setValue('otp4', numberValue);
                }}
                onPaste={(event) => {
                  event.preventDefault();
                  const value = event.clipboardData.getData('Text');
                  fillAllOtp(value);
                  return false;
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && !otp4) {
                    setFocus('otp3');
                  }
                }}
                isInvalid={isInvalidOTP}
              />
            </Col>
            {showError && (
              <div className={styles.errorMessage}>
                <Icon
                  size="small"
                  iconName="icon_info"
                  className={styles.icon}
                />
                {showError}
              </div>
            )}
          </Row>
          <p className={styles.message}>
            {isJobPage
              ? `In order to apply we need you to confirm your email so we know that you're reachable at this email address 🙈`
              : `
            To complete your Huzzle profile we need you to confirm your email so
            we know that you’re reachable at this email address 🙈`}
          </p>

          <div className={styles.timerContainer}>
            <p className={styles.timer}>
              00:{seconds < 10 ? `0${seconds}` : seconds}
            </p>
            <p className={styles.dot}>•</p>
            <p
              className={cn(styles.resendButton, {
                [styles.disabled]: !!seconds,
              })}
              onClick={handleReset}
            >
              Resend code
            </p>
          </div>
        </Form>
      </div>
    );
  }
);

VerifyOTP.displayName = 'VerifyOTP';
