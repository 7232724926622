import React, { memo } from 'react';
import cn from 'classnames';
import differenceInDays from 'date-fns/differenceInDays';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

import Chip from 'components/Chip';
import styles from './Deadline.module.scss';
import { getFormattedDuration } from 'utils/format';

export interface DeadlineProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  application_deadline: string;
}

export const Deadline: React.FC<DeadlineProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    application_deadline,
  }: DeadlineProps) => {
    const diff = differenceInDays(new Date(application_deadline), new Date());

    const applicationDeadline =
      diff < 30
        ? formatDistanceToNowStrict(new Date(application_deadline))
        : null;
    const contentClassNames = cn(
      {
        [styles.Deadline]: true,
        [styles.silver]: diff > 14 && diff <= 30,
        [styles.yellow]: diff > 7 && diff <= 14,
        [styles.orange]: diff >= 1 && diff <= 7,
        [styles.red]: diff < 1,
      },
      className
    );

    const deadline = applicationDeadline
      ? getFormattedDuration(applicationDeadline)
      : null;

    return deadline ? (
      <Chip className={contentClassNames} style={style}>
        ⌛ {deadline} left
      </Chip>
    ) : null;
  }
);

Deadline.displayName = 'Deadline';
