import React, { forwardRef, memo } from 'react';
import cn from 'classnames';

import { Icon } from 'components';
import type { SelectionProps } from '../Selection';
import Selection from '../Selection';

import styles from './SelectionTickMark.module.scss';

export type SelectionTickMarkProps = Omit<SelectionProps, 'type'> & {
  tickClassName?: string;
  isTickSquare?: boolean;
  unTickClassName?: string;
  showSelectionCircle?: boolean;
};

export const SelectionTickMark = memo(
  forwardRef<HTMLInputElement, SelectionTickMarkProps>(
    (
      {
        className = '',
        style,
        isTickSquare = false,
        children,
        tickClassName = '',
        unTickClassName = '',
        showSelectionCircle = false,
        ...props
      },
      ref
    ) => {
      const contentClassNames = cn(styles.SelectionTickMark, className);

      return (
        <Selection
          {...props}
          className={contentClassNames}
          style={style}
          type="checkbox"
          dataTestid="SelectionTickMark"
          ref={ref}
        >
          {children}
          {!isTickSquare ? (
            <div className={cn(styles.untick, unTickClassName)}></div>
          ) : (
            <div className={cn(styles.untickSquare, unTickClassName)}></div>
          )}
          {showSelectionCircle ? (
            <div className={cn(styles.tickCircle)}>
              <div></div>
            </div>
          ) : !isTickSquare ? (
            <Icon
              iconName="tick-mark-circle"
              className={cn(styles.tick, tickClassName)}
            />
          ) : (
            <Icon
              iconName="tick_mark_square"
              className={cn(styles.tickSquare, tickClassName)}
            />
          )}
        </Selection>
      );
    }
  )
);

SelectionTickMark.displayName = 'SelectionTickMark';
