import React, { memo } from 'react';
import cn from 'classnames';

import { Icon } from 'components';
import type { IconProps } from 'components/Icon';

import styles from './IconButton.module.scss';

export interface IconButtonProps
  extends IconProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Custom button style */
  style?: Record<string, unknown>;
  /** custom class name for icon */
  iconClassName?: string;
  /** children node */
  children?: React.ReactNode;
  dateSelectIcon?: boolean;
  htmlFor?: string;
}

export const IconButton: React.FC<IconButtonProps> = memo(
  ({
    size,
    iconName,
    className,
    iconClassName,
    dateSelectIcon,
    children,
    htmlFor = null,
    ...props
  }) => {
    const contentClassNames = cn([styles.IconButton], className);

    return (
      <>
        {dateSelectIcon ? (
          <Icon iconName={iconName} className={iconClassName} size={size} />
        ) : (
          <button
            className={contentClassNames}
            data-testid="IconButton"
            {...props}
          >
            <Icon iconName={iconName} className={iconClassName} size={size} />
            {htmlFor && (
              <label htmlFor={htmlFor} className={styles.label}></label>
            )}
            {children}
          </button>
        )}
      </>
    );
  }
);

IconButton.displayName = 'IconButton';
