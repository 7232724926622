import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  useMatchingExperience as useMatchingExperienceInternal,
  UseMatchingExperienceReturnType,
} from 'hooks/useMatchingExperience';

const initialContext: UseMatchingExperienceReturnType = {
  matchList: [],
  match: null,
  positionOfMatch: -1,
  total: undefined,
  restMatches: [],
  mutateMatchesList: noop,
  isMatchesLoading: false,
  generateNewMatches: () => new Promise(noop),
  unvisited_count: undefined,
  unvisited_events_count: 0,
  unvisited_internships_count: 0,
  unvisited_jobs_count: 0,
  internshipMatches: [],
  jobMatches: [],
  eventMatches: [],
  update_matches: noop,
  isMatchesMutating: false,
  setMatchesMutating: noop,
  generateMatchesFromScratch: () => new Promise(noop),
  getPreviousMatches: () => new Promise(noop),
  candidateLastUpdatedStep: null,
  updateCandidateLastStep: () => new Promise(noop),
  isCandidateUpdating: false,
  setCandidateUpdating: noop,
  isNewMatchesLoading: false,
  setIsNewMatchesLoading: noop,
  candidateSelectedOpportunities: [],
  onboarding_completed: false,
  setMatchList: noop,
  event_state_empty: false,
  internship_state_empty: false,
  job_state_empty: false,
  updateCandidatePreferredMatches: () => new Promise(noop),
  mutateMatches: () => new Promise(noop),
  candidate: undefined,
  mutateCandidate: () => new Promise(noop),
  candidateDashboard: undefined,
  mutateCandidateDashboard: () => new Promise(noop),
  show_cv_builder_banner: false,
};

const MatchingExperienceContext =
  createContext<UseMatchingExperienceReturnType>(initialContext);

export const useMatchingExperience = (): UseMatchingExperienceReturnType =>
  useContext(MatchingExperienceContext);

export const MatchingExperienceProvider: React.FC = ({ children }) => {
  const value = useMatchingExperienceInternal();

  return (
    <MatchingExperienceContext.Provider value={value}>
      {children}
    </MatchingExperienceContext.Provider>
  );
};
