import React, { ReactElement, useEffect } from 'react';
import Head from 'next/head';
import cn from 'classnames';

import { useRouter } from 'next/router';
import { IconButton, Icon, Image } from 'components';
import { getLayout } from 'components/layouts/Header';

import { Row } from 'components/layout';
import { Input } from 'components/form';
import { useResponsive } from 'hooks/useResponsive';
import { useOpportunitiesSearch } from 'lib/contexts/opportunities-search-context';
import { withAuthCandidate } from 'lib/hocs/withAuthCandidate';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useApp } from 'lib/contexts/save-in-app-context';
import { TouchpointType } from 'lib/models/touchpoint';

import styles from './Search.module.scss';

export const PER_PAGE_COUNT = 50;

const Search = (): ReactElement => {
  const screens = useResponsive();
  const router = useRouter();

  const { recentSearches, setTouchpointType, resetFilter } =
    useOpportunitiesSearch();
  const { setHideMobileFooter } = useApp();

  useEffect(() => {
    setHideMobileFooter(false);
  }, []);

  const categoryList = [
    {
      id: 1,
      title: 'Jobs',
      kind: '',
      touchpointableType: 'Job',
      categoryImage: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}/images/jobs-search-banner.png`,
      backgroundGradient: `linear-gradient(0deg, rgba(31, 130, 136, 0.5), rgba(31, 130, 136, 0.5))`,
    },
    {
      id: 2,
      title: 'Internships',
      kind: '',
      touchpointableType: 'Internship',
      categoryImage: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}/images/internships-search-banner.png`,
      backgroundGradient: `linear-gradient(0deg, rgba(0, 104, 163, 0.5), rgba(0, 104, 163, 0.5))`,
    },
    {
      id: 3,
      title: 'Events',
      kind: '',
      touchpointableType: 'Event',
      categoryImage: `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}/images/events.jpeg`,
      backgroundGradient: `linear-gradient(0deg, rgba(125, 0, 83, 0.5), rgba(125, 0, 83, 0.5))`,
    },
  ];

  const handleOnClick = (title: string, url: string) => {
    resetFilter();
    if (title === 'Events') {
      setTouchpointType(TouchpointType.Event);
      router.push(PAGE_ROUTES.EVENTS_SEARCH);
    } else if (title === 'Jobs') {
      setTouchpointType(TouchpointType.Job);
      router.push(PAGE_ROUTES.JOBS_SEARCH);
    } else if (title === 'Internships') {
      setTouchpointType(TouchpointType.Internship);
      router.push(PAGE_ROUTES.INTERNSHIPS_SEARCH);
    } else {
      router.push(url);
    }
  };

  const getCategories = () => {
    return categoryList.map((category) => {
      const {
        id,
        title,
        kind,
        touchpointableType,
        categoryImage,
        backgroundGradient,
      } = category;
      const kindInURL = kind ? `&kind=${kind}` : '';
      const url = `${PAGE_ROUTES.OPPORTUNITIES_SEARCH}?touchpointableType=${touchpointableType}${kindInURL}`;
      return (
        <div key={id} onClick={() => handleOnClick(title, url)}>
          <Row
            align="center"
            justify="space-between"
            wrap={false}
            className={styles.image}
            style={{
              background: `${backgroundGradient},url(${categoryImage})`,
              backgroundSize: 'cover',
            }}
            isFullWidthRow
          >
            <p>{title}</p>
            <Icon iconName="chevron-right" size="large" />
          </Row>
        </div>
      );
    });
  };

  const onCompaniesClick = () => {
    resetFilter();
    router.push(PAGE_ROUTES.COMPANIES_SEARCH);
  };

  const onSocietiesClick = () => {
    resetFilter();
    router.push(PAGE_ROUTES.SOCIETIES_SEARCH);
  };

  return (
    <>
      <Head>
        <title>Explore | Huzzle</title>
        <meta name="description" content="Huzzle" />
      </Head>
      <section className={styles.container} data-testid="Search">
        <main className={cn(styles.search, styles.main)}>
          <Input
            className={cn(styles.input, styles.inputCommonSearch)}
            placeholder="Search"
            startIcon="search"
            startIconSize="medium"
            onClick={() => {
              if (recentSearches.length > 0)
                router.push(PAGE_ROUTES.RECENT_SEARCH);
              else router.push(PAGE_ROUTES.OPPORTUNITIES_SEARCH);
            }}
          />
          <div className={styles.section}>
            <div onClick={onCompaniesClick}>
              <Row
                align="center"
                justify="space-between"
                className={styles.sectionHeading}
              >
                <div className={styles.title}>🏢 Companies</div>
                {screens.sm && (
                  <IconButton iconName="chevron-right" size="large" />
                )}
              </Row>
              <Image src="company-search-banner.png" source="aws" />
            </div>
            <div onClick={onSocietiesClick} className={styles.sectionMobile}>
              <Row
                align="center"
                justify="space-between"
                className={styles.sectionHeading}
              >
                <div className={styles.title}>🏛 Societies</div>
                {screens.sm && (
                  <IconButton iconName="chevron-right" size="large" />
                )}
              </Row>
              <Image src="society-search-banner.png" source="aws" />
            </div>
          </div>
          <div className={styles.subsection}>{getCategories()}</div>
        </main>
      </section>
    </>
  );
};

const SearchWrapper = withAuthCandidate(Search);
// @ts-ignore
SearchWrapper.getLayout = getLayout;

export default SearchWrapper;
