import React, { memo, useEffect, useState } from 'react';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { signIn, useSession } from 'next-auth/react';
import { useRecoilState } from 'recoil';

import { IconButton, Link } from 'components';
import { Form as FormComponent } from 'components/form';
import Logo from 'components/Logo';
import { ButtonV3, InputV2 } from 'components/ComponentV2';
import { Row } from 'components/layout';
import { useCandidate } from 'hooks/useCandidate';
import { useAuth } from 'lib/providers/AuthProvider';
import { UserRole } from 'lib/models/user';
import { userSecretState } from 'lib/atoms/userSecretAtom';
import { REGEX } from 'lib/utils/regex';
import { PAGE_ROUTES } from 'lib/page-routes';
import {
  parseErrorResponse,
  parseResponse,
  parseUserResponse,
} from 'lib/utils/parser';
import { CandidateResponse } from 'lib/models/candidate';
import { VerifyOTP } from '../VerifyOTP/VerifyOTP.component';

import styles from './SignIn.module.scss';
import { PRIVACY_POLICY, TERMS_OF_USE } from 'utils/urls';

export enum USER_TYPE {
  NEW = 'NEW',
  OLD = 'OLD',
}

export interface SignInProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** callback fn to be called when sign in is successful */
  onComplete: (headers: Headers) => void;
  isLoginPopup: boolean;
}

export const SignIn: React.FC<SignInProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    onComplete,
    isLoginPopup = false,
  }: SignInProps) => {
    const [, setUserSecret] = useRecoilState(userSecretState);
    const { setUser, setIsAuthLoading } = useAuth();

    const [askOTP, setAskOtp] = useState(false);
    const [isDomainChecked, setDomainChecked] = useState(false);
    const [showError, setShowError] = useState('');
    const [isLoading, setLoading] = useState(false);

    const { data: session, status } = useSession();
    const router = useRouter();

    const {
      onGoogleLoginAuthentication,
      onAppleLoginAuthentication,
      loginCandidate,
      isNewCandidate,
      getHeaders,
    } = useCandidate();

    const {
      register,
      watch,
      setValue,
      handleSubmit,
      formState: { errors, isSubmitting },
    } = useForm({ mode: 'all' });

    const emailValue = watch('email');

    const onGoogleLogin = async () => {
      const callbackUrl = router.asPath;
      await signIn('google', {
        callbackUrl,
      });
    };

    const onAppleLogin = () => {
      const callbackUrl = router.asPath;
      signIn('apple', {
        callbackUrl,
      });
    };

    const isLoggedInViaGoogle = session && session.oauth_provider === 'google';
    const isLoggedInViaApple = session && session.oauth_provider === 'apple';

    useEffect(() => {
      if (
        status === 'authenticated' &&
        (isLoggedInViaGoogle || isLoggedInViaApple)
      ) {
        setLoading(true);
        const { user } = session;
        const { email = '' } = user || {};
        setValue('email', email);
        try {
          (async () => {
            if (isLoggedInViaGoogle) {
              const response = await onGoogleLoginAuthentication();
              if (!isLoginPopup && response?.data.type === UserRole.CANDIDATE) {
                router.push(PAGE_ROUTES.CANDIDATE_MATCHES);
              } else {
                setShowError('Try with a different email');
              }
              setLoading(false);
            }
            if (isLoggedInViaApple) {
              const response = await onAppleLoginAuthentication();
              if (!isLoginPopup && response?.data.type === UserRole.CANDIDATE) {
                router.push(PAGE_ROUTES.CANDIDATE_MATCHES);
              } else {
                setShowError('Try with a different email');
              }
              setLoading(false);
            }
          })();
        } catch (error) {
          const message = parseErrorResponse(error);
          setShowError(message);
          setLoading(false);
        }
      }
    }, [status, isLoggedInViaGoogle, isLoggedInViaApple]);

    useEffect(() => {
      if (emailValue) {
        setDomainChecked(false);
      }
    }, [emailValue]);

    const onContinue = async () => {
      try {
        setIsAuthLoading(true);
        if (isLoggedInViaGoogle) {
          const response = await onGoogleLoginAuthentication();
          if (!isLoginPopup && response?.data.type === UserRole.CANDIDATE) {
            router.push(PAGE_ROUTES.CANDIDATE_MATCHES);
          } else {
            setShowError('Try with a different email');
          }
        } else if (isLoggedInViaApple) {
          const response = await onAppleLoginAuthentication();
          if (!isLoginPopup && response?.data.type === UserRole.CANDIDATE) {
            router.push(PAGE_ROUTES.CANDIDATE_MATCHES);
          } else {
            setShowError('Try with a different email');
          }
        } else {
          const response = await loginCandidate({
            email: emailValue,
            check_email: isDomainChecked ? false : true,
          });
          if (response) {
            if (isNewCandidate(response)) {
              const candidateResponse = parseResponse(
                response as CandidateResponse
              );
              const user = parseUserResponse(response as CandidateResponse);
              if (user) {
                const { authentication_token, email } = user;
                const headers = getHeaders(candidateResponse);
                if (onComplete) {
                  setUserSecret({ token: authentication_token, email });
                  setUser(user);
                  setIsAuthLoading(false);
                  onComplete(headers);
                }
              }
            } else {
              setAskOtp(true);
            }
          }
        }
      } catch (error) {
        const message = parseErrorResponse(error);
        setShowError(message);
        setDomainChecked(true);
      } finally {
        setIsAuthLoading(false);
      }
    };

    const contentClassNames = cn(
      [styles.SignIn],
      {
        [styles.otpContainer]: askOTP,
      },
      className
    );

    return (
      <section className={contentClassNames} style={style} data-testid="SignIn">
        {askOTP ? (
          <>
            <IconButton
              iconName="chevron-left"
              size="xlarge"
              onClick={() => setAskOtp(false)}
              className={styles.chevron}
            />
            <VerifyOTP
              email={emailValue}
              onComplete={onComplete}
              isLoginPopup={isLoginPopup}
            />
          </>
        ) : (
          <>
            <Logo
              name="huzzle-new"
              size="small"
              color="primary"
              className={cn(styles.logo, styles.hideDesktop, {
                [styles.hideMobile]: isLoginPopup,
              })}
            />
            <p
              className={cn(styles.title, {
                [styles.titleModal]: isLoginPopup,
                [styles.titleMobile]: !isLoginPopup,
              })}
            >
              Job Search for the Ambitious
            </p>
            <p
              className={cn(styles.desc, {
                [styles.hideMobile]: !isLoginPopup,
              })}
            >
              Find jobs, build your CV, apply in seconds.
            </p>
            <div
              className={cn(
                { [styles.gap]: !isLoginPopup },
                styles.actionContainer
              )}
            >
              <ButtonV3
                color="secondary"
                startIcon="icon_google"
                startIconClassName={styles.startIcon}
                isFullWidth
                className={styles.buttonGoogle}
                onClick={onGoogleLogin}
                disabled={isLoading}
              >
                Continue with Google
              </ButtonV3>
              <ButtonV3
                color="secondary"
                startIcon="icon_apple"
                startIconClassName={styles.startIcon}
                isFullWidth
                onClick={onAppleLogin}
                className={styles.buttonApple}
                disabled={isLoading}
              >
                Continue with Apple
              </ButtonV3>
              <Row
                align="center"
                justify="center"
                columnGap={12}
                isFullWidthRow
                className={styles.separatorRow}
              >
                <div className={styles.separator}></div>
                <p className={styles.separatorText}>OR</p>
                <div className={styles.separator}></div>
              </Row>
              <FormComponent
                autoComplete="off"
                onSubmit={handleSubmit(onContinue)}
                className={styles.sectionForm}
              >
                <InputV2
                  type="email"
                  inputClassName={styles.signInput}
                  placeholder="Enter your email"
                  {...register('email', {
                    required: 'Required',
                    pattern: {
                      value: REGEX.EMAIL,
                      message: 'Enter a valid e-mail address',
                    },
                  })}
                />
                {showError && (
                  <div className={styles.error}>
                    <p>
                      😅{'  '}{' '}
                      <span
                        dangerouslySetInnerHTML={{ __html: showError }}
                      ></span>
                    </p>
                    <IconButton
                      iconName="close"
                      size="xsmallplus"
                      onClick={() => {
                        setShowError('');
                        setDomainChecked(true);
                      }}
                      className={styles.cancelError}
                    />
                  </div>
                )}
                <ButtonV3
                  isFullWidth
                  disabled={!!errors.email || !emailValue}
                  className={styles.buttonContinue}
                  type="submit"
                  isLoading={isSubmitting || isLoading}
                >
                  Continue with Email
                </ButtonV3>
              </FormComponent>
              <p className={styles.termsRow}>By proceeding, you agree to our</p>
              <p className={styles.termsText}>
                <span>
                  <Link
                    isUnderlined
                    href={TERMS_OF_USE}
                    className={styles.termsText}
                  >
                    Terms of Service
                  </Link>
                  {' and '}
                  <Link
                    isUnderlined
                    href={PRIVACY_POLICY}
                    className={styles.termsText}
                  >
                    Privacy Policy.
                  </Link>
                </span>
              </p>
            </div>
          </>
        )}
      </section>
    );
  }
);

SignIn.displayName = 'SignIn';
