import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';

import { ToolUserAttributes, UserResponse, UserRole } from 'lib/models/user';
import {
  CandidateAttributes,
  CandidateResponse,
  CandidateUserAttributes,
} from 'lib/models/candidate';
import { get } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import { parseResponse, parseUserResponse } from 'lib/utils/parser';
import { httpHeadersState, userSecretState } from 'lib/atoms/userSecretAtom';
import { getBasicParticipant } from 'lib/utils/discussion';

export const useAuthInternalHook = () => {
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [isInitialAuthLoading, setInitialAuthLoading] = useState(true);
  const [user, setUser] = useState<
    ToolUserAttributes | CandidateUserAttributes | null
  >(null);
  const [isNewCandidate, setIsNewCandidate] = useState<boolean>(false);
  const [candidate, setCandidate] = useState<CandidateAttributes | null>(null);
  const [candidateUser, setCandidateUser] =
    useState<CandidateUserAttributes | null>(null);
  const { replace, query } = useRouter();
  const { headers } = useRecoilValue(httpHeadersState);
  const setUserSecret = useSetRecoilState(userSecretState);

  useEffect(() => {
    function updateUser(userResponse: UserResponse | CandidateResponse) {
      const user = parseUserResponse(userResponse);
      if (user) {
        setUser(user);
        setUserSecret({ token: user.authentication_token, email: user.email });
      }
      if (isCandidateType(userResponse)) {
        const candidate = parseResponse(userResponse);
        const candidateUser = parseResponse(candidate.user);
        setCandidate(candidate);
        setCandidateUser(candidateUser);
      }
    }

    async function updateUserUsingStorage() {
      try {
        setIsAuthLoading(true);
        setInitialAuthLoading(true);
        const userResponse = await get<UserResponse | CandidateResponse>(
          API_ROUTES.USER_INFO,
          headers
        );
        updateUser(userResponse);
      } catch (error) {
        console.error(error);
        setUserSecret(null);
      } finally {
        setIsAuthLoading(false);
        setInitialAuthLoading(false);
      }
    }

    async function updateUserUsingQueryParam() {
      try {
        setIsAuthLoading(true);
        setInitialAuthLoading(true);
        const route = `${API_ROUTES.CANDIDATE}?candidate_id=${candidate_id}&magic_token=${token}`;
        const userResponse = await get<CandidateResponse>(route);
        updateUser(userResponse);
        const { candidate_id: _, token: __, ...rest } = query;
        replace({ query: rest });
      } catch (error) {
        console.error(error);
        setUserSecret(null);
      } finally {
        setIsAuthLoading(false);
        setInitialAuthLoading(false);
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const candidate_id = urlParams.get('candidate_id'),
      token = urlParams.get('token');

    if (candidate_id && token) {
      updateUserUsingQueryParam();
    } else if (headers.has('X-Auth-Email') && headers.has('X-Auth-Token')) {
      updateUserUsingStorage();
    } else {
      setIsAuthLoading(false);
      setInitialAuthLoading(false);
    }
  }, []);

  const {
    isLoggedIn,
    isManager,
    isRecruiter,
    isCandidate,
    isTemporaryCandidate,
  } = useMemo(() => {
    let isLoggedIn = true,
      isCandidate = false,
      isManager = false,
      isRecruiter = false,
      isTemporaryCandidate = false;

    switch (user?.userable_type.toLowerCase()) {
      case UserRole.MANAGER:
        isManager = true;
        break;
      case UserRole.RECRUITER:
        isRecruiter = true;
        break;
      case UserRole.CANDIDATE:
        if (user?.email === '') isTemporaryCandidate = true;
        else isCandidate = true;
        break;
      default:
        isLoggedIn = false;
    }

    return {
      isLoggedIn,
      isManager,
      isRecruiter,
      isCandidate,
      isTemporaryCandidate,
    };
  }, [user]);

  const userName = useMemo(() => {
    let name = '';
    if (user) {
      const { first_name, last_name } = user;
      name =
        (first_name ? first_name : '') + ' ' + (last_name ? last_name : '');
    }
    return name;
  }, [user]);

  const userSociety = useMemo(() => {
    return isManager && isToolUser(user) ? user.student_society || null : null;
  }, [user, isManager]);

  const userCompany = useMemo(() => {
    return isRecruiter && isToolUser(user) ? user.company || null : null;
  }, [user, isRecruiter]);

  const toolUser = useMemo(() => {
    return (isManager || isRecruiter) && isToolUser(user) ? user : null;
  }, [user, isManager, isRecruiter]);

  const basicParticipant = useMemo(() => {
    return getBasicParticipant({
      user: user as ToolUserAttributes,
      candidate,
    });
  }, [user, candidate]);

  return {
    isAuthLoading,
    isInitialAuthLoading,
    setIsAuthLoading,
    user,
    setUser,
    userName,
    isLoggedIn,
    isManager,
    isRecruiter,
    isCandidate,
    isTemporaryCandidate,
    isNewCandidate,
    setIsNewCandidate,
    userSociety,
    userCompany,
    candidate,
    candidateUser,
    toolUser,
    basicParticipant,
  };
};

export const isToolUser = (
  user: ToolUserAttributes | CandidateUserAttributes | null
): user is ToolUserAttributes => {
  const role = user?.userable_type.toLowerCase();
  return role === UserRole.MANAGER || role === UserRole.RECRUITER;
};

export const isCandidateType = (
  user: UserResponse | CandidateResponse
): user is CandidateResponse => {
  return user.data.type === UserRole.CANDIDATE;
};

export type UseAuthReturnType = ReturnType<typeof useAuthInternalHook>;
