import { TouchpointType } from 'lib/models/touchpoint';
import { PAGE_ROUTES } from 'lib/page-routes';

export const getOpportunityPublicPagePathname = (
  touchpointable_type: string,
  slug: string
): string => {
  let pathname = '';
  switch (touchpointable_type) {
    case TouchpointType.Event:
      pathname = PAGE_ROUTES.EVENT_PUBLIC.replace('[id]', slug);
      break;
    case TouchpointType.Job:
      pathname = PAGE_ROUTES.JOB_PUBLIC.replace('[id]', slug);
      break;
    case TouchpointType.Internship:
      pathname = PAGE_ROUTES.INTERNSHIP_PUBLIC.replace('[id]', slug);
      break;
    case TouchpointType.Competition:
      pathname = PAGE_ROUTES.COMPETITION_PUBLIC.replace('[id]', slug);
      break;
    default:
  }
  return pathname;
};

export const APP_LINK = 'https://huzzle.onelink.me/4BeN/58a33aa5';
export const CHROME_EXTENSION_STORE_LINK =
  'https://chrome.google.com/webstore/detail/huzzle-quick-apply-autofi/oicchbdmkmdblmmcikdpahedjjagjepe';
export const TERMS_OF_USE = 'https://www.huzzle.app/terms-of-use';
export const PRIVACY_POLICY = 'https://www.huzzle.app/gdpr';
export const REFERAL_LEARN_MORE =
  'https://www.notion.so/Huzzle-Referral-Program-7d33519da05047438b25548b18568022?pvs=4';
