import React from 'react';
import cn from 'classnames';

import type { SelectionProps } from 'components/form/Selection';
import Selection from 'components/form/Selection';

import styles from './Toggle.module.scss';

type ToggleProps = Omit<SelectionProps, 'type'>;

export type { ToggleProps };

export const Toggle: React.FC<ToggleProps> = (props: ToggleProps) => {
  const contentClassNames = cn(
    {
      [styles.Toggle]: true,
    },
    props.className
  );

  return (
    <Selection
      {...props}
      className={contentClassNames}
      type="checkbox"
      dataTestid="Toggle"
    />
  );
};
