import React, {
  memo,
  forwardRef,
  useLayoutEffect,
  useRef,
  useEffect,
} from 'react';
import cn from 'classnames';
import { IconName } from 'components/Icon';
import { IconButton } from 'components/IconButton/IconButton.component';
import styles from './TextArea.module.scss';

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  /** Custom class name */
  textAreaClassName?: string;
  /** Custom style */
  textAreaStyle?: Record<string, unknown>;
  /** What is the label for the textarea element? */
  label?: string;
  /** Is input field valid? */
  isValid?: boolean;
  /** What is the error message? */
  helperText?: string;
  /** className for Label */
  labelClassName?: string | null;
  /** iconName */
  iconName?: IconName;
  /** iconName for selectDate */
  dateSelectIcon?: boolean;
  /** onClick handler for text area */
  onClick?: () => void;
  onMount?: (ref: React.RefObject<HTMLTextAreaElement> | null) => void;
  showOptionalLabel?: boolean;
  infoToolTip?: React.ReactNode;
  isDynamic?: boolean;
  initialHeight?: string;
}

export const TextArea = memo(
  forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
      {
        className = '', // custom class name
        style, // custom style
        textAreaStyle,
        textAreaClassName,
        label,
        id,
        dateSelectIcon,
        isValid = true,
        helperText,
        labelClassName = '',
        iconName,
        onClick,
        onMount,
        showOptionalLabel,
        infoToolTip,
        isDynamic,
        initialHeight,
        ...props
      },
      ref
    ) => {
      const contentClassNames = cn(styles.TextArea, className, {
        [styles.TextAreaIsInvalid]: !isValid,
        [styles.TextAreaGap]: dateSelectIcon,
      });
      const textareaRef = useRef<HTMLTextAreaElement | null>(null);

      const labelClassNames = cn([styles.TextAreaLabel], labelClassName, {
        [styles.TextAreaLabelDate]: dateSelectIcon,
      });
      useLayoutEffect(() => {
        if (onMount) onMount(textareaRef);
      }, []);

      useEffect(() => {
        if (textareaRef && textareaRef.current && isDynamic) {
          textareaRef.current.style.height = initialHeight || '104px';
          const actualHeight = `${textareaRef.current.scrollHeight + 2}px`;
          textareaRef.current.style.height = actualHeight;
        }
      });

      return (
        <section
          className={contentClassNames}
          style={style}
          data-testid="TextAreaContainer"
        >
          <div className={labelClassNames}>
            {label ? (
              <label htmlFor={id}>
                <span></span>
                {label}
                {showOptionalLabel}
                {showOptionalLabel ? (
                  <span className={styles.optional}> (optional)</span>
                ) : null}
                {infoToolTip ? (
                  <span className={styles.infoToolTip}>{infoToolTip}</span>
                ) : null}
              </label>
            ) : null}
            {helperText ? (
              <label htmlFor={id} className={styles.HelperText}>
                {helperText}
              </label>
            ) : null}
          </div>
          <textarea
            id={id}
            ref={ref || textareaRef}
            data-testid="TextArea"
            aria-invalid={!isValid}
            style={textAreaStyle}
            onClick={onClick}
            className={cn(styles.TextAreaElement, textAreaClassName, {
              [styles.TextAreaElementWithIcon]: iconName,
            })}
            {...props}
          ></textarea>
          {iconName && (
            <IconButton
              iconName={iconName}
              className={cn(styles.TextAreaIcon, {
                [styles.TextAreaIconWithLabel]:
                  (label || helperText) && !dateSelectIcon,
              })}
              iconClassName={cn({
                [styles.TextAreaIconSelection]: dateSelectIcon,
              })}
              onClick={onClick}
              dateSelectIcon={dateSelectIcon}
            />
          )}
        </section>
      );
    }
  )
);

TextArea.displayName = 'TextArea';
