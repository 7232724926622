import React, { forwardRef, memo } from 'react';
import cn from 'classnames';

import type { SelectionProps } from '../Selection';
import Selection from '../Selection';

import styles from './Checkbox.module.scss';

export type CheckboxProps = Omit<SelectionProps, 'type'>;

export const Checkbox = memo(
  forwardRef<HTMLInputElement, CheckboxProps>(
    ({ className = '', style, ...props }, ref) => {
      const contentClassNames = cn(styles.Checkbox, className);

      return (
        <Selection
          {...props}
          className={contentClassNames}
          style={style}
          type="checkbox"
          dataTestid="Checkbox"
          ref={ref}
        />
      );
    }
  )
);

Checkbox.displayName = 'Checkbox';
