import { useState, useEffect } from 'react';
import ResponsiveObserve, { ScreenMap } from 'lib/utils/responsiveObserve';

type useResponsiveType = ScreenMap;

export const useResponsive = (): useResponsiveType => {
  const [screens, setScreens] = useState<ScreenMap>({
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
    xxl: true,
    sm_desktop_down: true,
    xs_up: true,
  });

  useEffect(() => {
    const token = ResponsiveObserve.subscribe((screen) => {
      setScreens(screen);
    });

    return () => ResponsiveObserve.unsubscribe(token);
  }, []);

  return screens;
};
