import React, { memo } from 'react';
import cn from 'classnames';
import capitalize from 'lodash/capitalize';

import { useDynamicSVGImport } from './useDynamicSVGImport';

import styles from './Icon.module.scss';

export type IconName =
  | 'edit'
  | 'edit-v2'
  | 'chevron-left'
  | 'chevron-right'
  | 'cancel'
  | 'close'
  | 'long-right-arrow'
  | 'right-arrow'
  | 'campaigns'
  | 'search'
  | 'societies'
  | 'vector'
  | 'new-campaign'
  | 'eye-open'
  | 'eye-close'
  | 'tick-mark'
  | 'tick-mark-circle'
  | 'valid-check-mark'
  | 'chevron-down'
  | 'chevron-up'
  | 'filter'
  | 'reset'
  | 'percentage'
  | 'screen'
  | 'flying-letter'
  | 'messages'
  | 'huzzle'
  | 'huzzle.recruiter'
  | 'huzzle.society'
  | 'check-contained'
  | 'group'
  | 'calendar'
  | 'upload'
  | 'dashboard'
  | 'export'
  | 'success'
  | 'info'
  | 'warning'
  | 'error'
  | 'trash'
  | 'caution'
  | 'profile'
  | 'huzzle-new'
  | 'share'
  | 'bookmark'
  | 'external-link'
  | 'plus-circle'
  | 'filter-new'
  | 'verified'
  | 'candidate-profile'
  | 'horizontal-menu'
  | 'apply'
  | 'unfollow'
  | 'folder'
  | 'logout'
  | 'envelope'
  | 'cv-upload'
  | 'doc-upload'
  | 'download'
  | 'replace'
  | 'app-store-download'
  | 'play-store-download'
  | 'saved'
  | 'information'
  | 'hamburger'
  | 'double-chevron-right'
  | 'dislike'
  | 'undo-dislike'
  | 'double-tick-mark'
  | 'add-to-calendar'
  | 'notification'
  | 'edit-new'
  | 'photo-upload'
  | 'camera'
  | 'remove'
  | 'copy-to-clipboard'
  | 'facebook'
  | 'linkedin'
  | 'twitter'
  | 'actions'
  | 'calendar-new'
  | 'location'
  | 'meeting-link'
  | 'actions-v2'
  | 'draft'
  | 'huzzle-profile'
  | 'instagram'
  | 'arrow-button-left'
  | 'arrow-button-right'
  | 'insta-heart'
  | 'insta-comment'
  | 'insta-share'
  | 'insta-bookmark'
  | 'setting'
  | 'setting-black'
  | 'category'
  | 'plus'
  | 'icon_plus'
  | 'icon_left-arrow-2'
  | 'drag-and-drop'
  | 'bullet'
  | 'icon_check'
  | 'icon_close'
  | 'icon_check-all'
  | 'arrow-up'
  | 'close-check'
  | 'icon_arrow-down'
  | 'icon_minimise'
  | 'huzzle-small'
  | 'icon_info-outline'
  | 'icon_direction-right'
  | 'icon_table'
  | 'icon_inbox'
  | 'tick_mark_square'
  | 'icon_arrows-maximize'
  | 'icon_arrows-maximize-2'
  | 'icon_arrow-forward'
  | 'icon_arrow-left'
  | 'icon_bar_chart'
  | 'icon_microphone'
  | 'icon_thumb-up'
  | 'icon_chat'
  | 'icon_dot'
  | 'icon_chat-filled'
  | 'icon_magic-wand'
  | 'icon_bicep'
  | 'icon_crown'
  | 'icon_briefcase'
  | 'icon_briefcase_filled'
  | 'icon_hat-university'
  | 'icon_mail'
  | 'icon_mail_filled'
  | 'icon_profile'
  | 'icon_check'
  | 'icon_send-message'
  | 'icon_pen-23'
  | 'icon_document-2'
  | 'icon_info'
  | 'icon_launch'
  | 'icon_huzzlerecruiter'
  | 'icon_layout-11'
  | 'icon_party'
  | 'icon_right-arrow'
  | 'icon_party_filled'
  | 'icon_multiple-11'
  | 'icon_multiple-11_filled'
  | 'icon_paint-up'
  | 'icon_paint-up_filled'
  | 'icon_medal'
  | 'icon_stopwatch'
  | 'icon_office-chair'
  | 'icon_calendar'
  | 'icon_pin'
  | 'icon_coins'
  | 'icon_coins_filled'
  | 'icon_attach'
  | 'icon_check-double'
  | 'icon_check-list'
  | 'icon_megaphone'
  | 'icon_compass'
  | 'desert'
  | 'info-emoji'
  | 'icon_add-emoji'
  | 'icon_checkbox-selected'
  | 'icon_checkbox-unselected'
  | 'icon_link'
  | 'icon_qrCode'
  | 'icon_pencil'
  | 'icon_users'
  | 'icon_users-wm-fill-regular-small'
  | 'icon_users-wm-fill-regular-small-fill'
  | 'invite-mail'
  | 'filter-2'
  | 'icon_add-emoji-outline-regular-small'
  | 'icon_arrow-up-2'
  | 'icon_volume-mute'
  | 'icon_ban'
  | 'icon_bookmark'
  | 'icon_bookmark-filled'
  | 'icon_arrow-up-2'
  | 'message'
  | 'icon_placeholder'
  | 'icon_star'
  | 'icon_star-outline'
  | 'icon_home'
  | 'icon_home-fill'
  | 'icon_search'
  | 'icon_search-fill'
  | 'icon_profile-fill'
  | 'icon_notification-bell'
  | 'icon_filter-2'
  | 'icon_gear'
  | 'icon_logout'
  | 'icon_camera-flash'
  | 'icon_restore'
  | 'icon_blog'
  | 'icon_code-outline-regular-small'
  | 'icon_hourglass-outline'
  | 'icon_trash'
  | 'icon_ellipsis'
  | 'internship'
  | 'clock'
  | 'icon_filter_2'
  | 'office'
  | 'icon_download'
  | 'icon_google'
  | 'icon_apple'
  | 'icon_mailbox-with-letter'
  | 'icon_copy'
  | 'icon_badge'
  | 'icon_badge-filled'
  | 'icon_arrow-left-2'
  | 'icon_arrow-right-2'
  | 'icon_arrow-down-2'
  | 'icon_handshake'
  | 'icon_handshake_filled'
  | 'icon_comment'
  | 'icon_arrow-right-2-bold'
  | 'discussion'
  | 'discussion-filled'
  | 'unordered_list'
  | 'icon_image'
  | 'huzzle-verified'
  | 'reply'
  | 'heart'
  | 'heart-filled'
  | 'file_type_pdf'
  | 'file_type_doc'
  | 'file_type_xls'
  | 'file_type_csv'
  | 'file_type_ppt'
  | 'icon_loader'
  | 'icon_chevron-left'
  | 'icon_handshake'
  | 'icon_handshake_filled'
  | 'icon_comment'
  | 'icon_arrow-right-2-bold';

export type IconSize =
  | 'xxsmall'
  | 'xxsmallplus'
  | 'xsmall'
  | 'xsmallplus'
  | 'small'
  | 'medium'
  | 'mediumplus'
  | 'xmediumplus'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'auto';
export interface IconProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** name of the icon */
  iconName: IconName;
  /** size of the icon */
  size?: IconSize;
}

export const Icon: React.FC<IconProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    iconName,
    size = 'medium',
  }: IconProps) => {
    const contentClassNames = cn(
      {
        [styles.Icon]: true,
        [`${styles[`IconSize${capitalize(size)}`]}`]: size,
      },
      className
    );

    const { SVGIcon } = useDynamicSVGImport(iconName);

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid={`icon_${iconName}`}
      >
        {SVGIcon !== null ? <SVGIcon /> : null}
      </div>
    );
  }
);

Icon.displayName = 'Icon';
