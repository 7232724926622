import { EventCategoriesList } from './event-categories';
import {
  QuestionAttributes,
  QuestionsResponse,
  QuestionState,
} from './answers';
import { CohostResponse, CohostResponses } from './cohost';
import { TagsResponse, TagType } from './tag';
import { TouchpointType } from './touchpoint';

export enum Kind {
  'Networking & Social' = 0,
  'Competitions' = 1,
  'Career Fairs' = 2,
  'Insight Days' = 3,
  'Workshops' = 4,
  'Conferences & Talks' = 5,
}

export const EVENT_KINDS = [
  {
    id: 0,
    label: 'Networking & Social',
    value: 'Networking & Social',
  },
  {
    id: 1,
    label: 'Competitions',
    value: 'Competitions',
  },
  {
    id: 2,
    label: 'Career Fairs',
    value: 'Career Fairs',
  },
  {
    id: 3,
    label: 'Insight Days',
    value: 'Insight Days',
  },
  {
    id: 4,
    label: 'Workshops',
    value: 'Workshops',
  },
  {
    id: 5,
    label: 'Conferences & Talks',
    value: 'Conferences & Talks',
  },
];

export interface EventTouchpointable {
  data: {
    id: string;
    type: 'event';
    attributes: {
      id: string;
      icon_url: string;
      start_date: string;
      end_date: string;
      kind: string;
      capacity: number;
      enforce_capacity: boolean;
      waitlist: boolean;
      enforce_waitlist_for_all: boolean;
      event_categories: EventCategoriesList;
      event_category_ids: Array<string>;
      collect_feedback: boolean;
    };
  };
}

export interface EventAttributes {
  id: string;
  title: string;
  description: string;
  application_deadline: string;
  topics: TagsResponse<TagType.Topic>;
  enabled: boolean;
  touchpointable: EventTouchpointable;
  touchpointable_type: TouchpointType.Event;
  place: Place;
  is_online: boolean;
  online_link: string;
  kind: string;
  questions: QuestionsResponse;
  university_required_state: QuestionState;
  study_year_required_state: QuestionState;
  degree_required_state: QuestionState;
  study_subject_required_state: QuestionState;
  cv_required_state: QuestionState;
  linkedin_profile_required_state: QuestionState;
  cohost_application: CohostResponse;
  cohost_applications: CohostResponses;
  slug: string;
  industries: TagsResponse<TagType.Industry>;
  state: string;
  notify_subscribers: boolean;
  timezone: string;
}

export interface Event {
  id: string;
  type: 'touchpoint';
  attributes: EventAttributes;
}

export interface EventResponse {
  data: Event;
}

export interface EventRequestStep1 {
  title: string;
  application_deadline: Date | null | string;
  touchpointable_attributes: {
    id: string;
    type: TouchpointType;
    start_date: Date | null | string;
    end_date: Date | string | null;
    icon?: string | File;
    icon_link?: string;
  };
  place_attributes?: PlaceAttributes | null | { id: string; _destroy: boolean };
  is_online: boolean;
  online_link?: string;
  submitType?: string;
  city_ids?: [];
  timezone?: string;
  country_id?: string;
}

export interface SocietyEventRequestStep2 {
  topic_ids: Array<string>;
  industry_ids: Array<string>;
  description: string;
  touchpointable_attributes: {
    id: string;
    kind: string;
    event_category_ids: Array<string>;
  };
  submitType?: string;
}

export interface EventRequestStep3 {
  university_required_state: QuestionState;
  study_year_required_state: QuestionState;
  degree_required_state: QuestionState;
  study_subject_required_state: QuestionState;
  cv_required_state: QuestionState;
  linkedin_profile_required_state: QuestionState;
  capacity: number;
  enforceCapacity: boolean;
  questions_attributes: QuestionAttributes[];
  enableWaitlist: boolean;
  enableForceWaitlist: boolean;
  removedCustomQuestionIds: Array<string>;
  state?: string;
}

export interface Place {
  data: {
    id: string;
    type: 'place';
    attributes: PlaceAttributes;
  };
}

export interface PlaceAttributes {
  id?: string;
  google_identifier?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  address_details?: string;
  city: string;
  country: string;
  street_address?: string;
  address_locality?: string;
  zipcode?: string;
}

export interface CompanyEventRequestStep3 {
  touchpointable_attributes: {
    id: string;
    capacity: number | null;
    enforce_capacity: boolean;
    waitlist: boolean;
    enforce_waitlist_for_all: boolean;
  };
  state: string;
  submitType?: string;
  university_required_state: QuestionState;
  study_year_required_state: QuestionState;
  degree_required_state: QuestionState;
  study_subject_required_state: QuestionState;
  cv_required_state: QuestionState;
  linkedin_profile_required_state: QuestionState;
  removedCustomQuestionIds?: Array<string>;
  questions_attributes: Array<{
    id?: string;
    title: string;
    placeholder?: string;
    state: QuestionState;
    question_type: string;
    isNew?: boolean;
  }>;
}
