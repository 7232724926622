import React, { useContext, createContext } from 'react';

import {
  UseCommonListReturnType,
  useCommonList as useCommonListInternal,
} from 'hooks/useCommonList';

const initialContext: UseCommonListReturnType = {
  areaOfResponsibilities: [],
  opportunityTypes: undefined,
  internshipKinds: undefined,
  internshipKindOptions: undefined,
  eventKinds: undefined,
  educationRequirementsOptions: undefined,
  currencyOptions: undefined,
  eventCategoriesList: undefined,
  industryOptions: [],
  topicOptions: undefined,
  eventTypeOptions: undefined,
  companySizeOptions: undefined,
  employeesCountRange: undefined,
  universities: [],
  subjects: [],
  degrees: [],
  studyYearOptions: [],
  countries: undefined,
  cityCountryList: [],
  genders: [],
  physicalDisability: [],
  congnitiveDisability: [],
  socioEconomicStatus: [],
  raceEthnicity: [],
  offeringOptions: [],
  workTypeList: [],
  workModeList: [],
  datePostedList: [],
  languageOptions: [],
  defaultLanguage: '',
  cities: [],
};

const CommonListContext =
  createContext<UseCommonListReturnType>(initialContext);

export const useCommonList = (): UseCommonListReturnType =>
  useContext(CommonListContext);

export const CommonListProvider: React.FC = ({ children }) => {
  const value = useCommonListInternal();

  return (
    <CommonListContext.Provider value={value}>
      {children}
    </CommonListContext.Provider>
  );
};
