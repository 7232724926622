import { Subject } from 'rxjs';

import { Notification, NotificationTypeEnum } from 'lib/models/notification';
import { parseErrorResponse } from 'lib/utils/parser';

let notifications$: Subject<Notification>;

interface useNotificationType {
  notifications$: Subject<Notification>;
  success: (notifiction: Omit<Notification, 'type'>) => void;
  info: (notifiction: Omit<Notification, 'type'>) => void;
  warning: (notifiction: Omit<Notification, 'type'>) => void;
  error: (notifiction: Omit<Notification, 'type'>) => void;
  custom: (notifiction: Omit<Notification, 'type'>) => void;
  acknowledge: (notifiction: Omit<Notification, 'type'>) => void;
  pendingCard: (notifiction: Omit<Notification, 'type'>) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleExceptionError: (error: any) => void;
}

export const useNotification = (): useNotificationType => {
  if (notifications$ === undefined) {
    notifications$ = new Subject<Notification>();
  }

  const success = (notification: Omit<Notification, 'type'>): void => {
    notifications$.next({
      type: NotificationTypeEnum.Success,
      ...notification,
    });
  };

  const info = (notification: Omit<Notification, 'type'>): void => {
    notifications$.next({
      type: NotificationTypeEnum.Info,
      ...notification,
    });
  };

  const warning = (notification: Omit<Notification, 'type'>): void => {
    notifications$.next({
      type: NotificationTypeEnum.Warning,
      ...notification,
    });
  };

  const error = (notification: Omit<Notification, 'type'>): void => {
    notifications$.next({
      type: NotificationTypeEnum.Error,
      ...notification,
    });
  };

  const custom = (notification: Omit<Notification, 'type'>): void => {
    notifications$.next({
      type: NotificationTypeEnum.Custom,
      ...notification,
    });
  };

  const pendingCard = (notification: Omit<Notification, 'type'>): void => {
    notifications$.next({
      type: NotificationTypeEnum.PendingCard,
      ...notification,
    });
  };

  const acknowledge = (notification: Omit<Notification, 'type'>): void => {
    notifications$.next({
      type: NotificationTypeEnum.Acknowledge,
      ...notification,
    });
  };

  const handleExceptionError = (err: Error) => {
    const message = parseErrorResponse(err);
    error({
      title: 'Error!',
      message,
    });
  };

  return {
    notifications$,
    handleExceptionError,
    success,
    info,
    warning,
    error,
    custom,
    pendingCard,
    acknowledge,
  };
};
